import { useSuspenseQuery } from '@tanstack/react-query'

import { LatLng, nearbySearch } from '@/utils/googleMap'
import { getRouteDistance } from '@/utils/googleMap/getRouteDistance'

export type EkiResult = google.maps.places.PlaceResult & {
  /** map中心からの徒歩距離 */
  walkDistance?: number
}

export const useNearbyEkis = (map: google.maps.Map, position: LatLng) =>
  useSuspenseQuery({
    queryKey: ['useNearbyEkis', position],
    async queryFn() {
      const ekis = await nearbySearch(
        map,
        [{ type: 'train_station' }, { type: 'subway_station' }],
        5000,
        position,
      )
      const mapCenter = map.getCenter()
      const centerLng = mapCenter?.lng()
      const centerLat = mapCenter?.lat()

      const promises: Promise<EkiResult>[] = ekis.map(async (eki) => {
        const lat = eki.geometry?.location?.lat()
        const lng = eki.geometry?.location?.lng()
        if (!lat || !lng || !centerLat || !centerLng) return eki

        const walkDistance = await getRouteDistance(
          { lat: centerLat, lng: centerLng },
          { lat, lng },
          google.maps.TravelMode.WALKING,
        )
        return {
          ...eki,
          walkDistance,
        }
      })
      return (await Promise.all(promises)).sort((a, b) =>
        a.walkDistance && b.walkDistance ? a.walkDistance - b.walkDistance : 0,
      )
    },
  })
