import { ngWordsForRegExp } from '../../constants/ngWords'

const regNgWords = new RegExp(`(${ngWordsForRegExp.join('|')})`, 'giu')

export type NgWordsError = {
  error: boolean
  ngWords: { value: string }[]
}

/**
 * 禁止ワードの検証
 *
 * @param text
 */
export function validateNgWords(text: string) {
  const errors: NgWordsError = { error: false, ngWords: [] }
  const result = [...text.matchAll(regNgWords)]
  if (result.length) {
    for (const regExpExecArray of result) {
      const value = regExpExecArray[0]
      const duplicated = !!errors.ngWords.filter((ng) => ng.value === value)
        .length
      if (!duplicated) {
        errors.ngWords.push({ value })
      }
    }
    errors.error = true
  }
  return errors
}

/**
 * エラーメッセージを作成
 *
 * @param ngWords
 */
export function createErrorMessage(ngWords: NgWordsError['ngWords']) {
  const uniqNgWords = ngWords
    .flat()
    .reduce<NgWordsError['ngWords']>((acc, current) => {
      if (!acc.some((item) => item.value === current.value)) acc.push(current)
      return acc
    }, [])
  return `禁止ワードが含まれています：${uniqNgWords.map((v) => `「${v.value}」`).join('')}`
}
