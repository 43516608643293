import {
  AccordionItem,
  AccordionButton,
  Box,
  Button,
  Link,
  AccordionIcon,
  AccordionPanel,
  Grid,
  Text,
  GridItem,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'

import { CenteringGridItem } from './CenteringGridItem'

import { getT3PublishLink } from '@/constants'
import { gridTemplate } from '@/utils/gridTemplate'

export const CreateAccordionItem = () => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          新規掲載
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Grid
          gridTemplate={style}
          justifyItems="center"
          alignItems="center"
          height={300}
        >
          <CenteringGridItem
            gridArea={getArea('T3Header1')}
            width="100%"
            borderColor="teraThree.500"
          >
            <Text>Terass Cloud</Text>
          </CenteringGridItem>
          <CenteringGridItem
            gridArea={getArea('T7Header')}
            width="100%"
            borderColor="teraSeven.500"
          >
            <Text>Terass Converter</Text>
          </CenteringGridItem>
          <CenteringGridItem
            gridArea={getArea('T3Header2')}
            width="100%"
            borderColor="teraThree.500"
          >
            <Text>Terass Cloud</Text>
          </CenteringGridItem>
          <Button
            colorScheme="teraThree"
            height="80%"
            gridArea={getArea('T3Baikai')}
            as={Link}
            href={getT3PublishLink({
              isSakimono: false,
            })}
            isExternal
          >
            媒体掲載・マイソク作成依頼
          </Button>
          <Button
            colorScheme="teraThree"
            height="80%"
            width="100%"
            gridArea={getArea('T3Sakimono')}
            as={Link}
            href={getT3PublishLink({
              isSakimono: true,
            })}
            isExternal
          >
            先物媒体掲載依頼
          </Button>
          <GridItem gridArea="arrow1">
            <FaArrowRight />
          </GridItem>
          <Button gridArea={getArea('shinkiButton')} height="90%" width="100%">
            媒体新規作成
          </Button>
          <GridItem gridArea="arrow2">
            <FaArrowRight />
          </GridItem>

          <Button gridArea={getArea('baikaiList')} height="80%">
            媒体掲載・マイソク
            <br />
            作成依頼一覧
          </Button>
          <Button gridArea={getArea('sakimonoList')} height="80%" width="100%">
            先物媒体掲載
            <br />
            依頼一覧
          </Button>
          <GridItem gridArea="arrow3">
            <FaArrowRight />
          </GridItem>

          <CenteringGridItem
            gridArea={getArea('shitagaki')}
            height="90%"
            width="100%"
            borderColor="teraSeven.500"
          >
            <Text>媒体掲載下書き</Text>
          </CenteringGridItem>
          <GridItem gridArea="arrow4">
            <FaArrowRight />
          </GridItem>
          <GridItem gridArea="arrow5">
            <FaArrowRight />
          </GridItem>
          <GridItem gridArea="arrow6">
            <FaArrowRight />
          </GridItem>
          <GridItem gridArea="arrow7">
            <FaArrowRight />
          </GridItem>
          <Button
            colorScheme="teraThree"
            gridArea={getArea('T3Publish')}
            height="90%"
            as={Link}
            isExternal
          >
            媒体掲載公開申請
          </Button>
        </Grid>
      </AccordionPanel>
    </AccordionItem>
  )
}

const { style, getArea } = gridTemplate`
  "${'T3Header1'}  ${'...'}    ${'T7Header'}     ${'T7Header'}  ${'T7Header'}     ${'T7Header'} ${'T7Header'}  ${'...'}    ${'T3Header2'}" auto
  "${'T3Baikai'}   ${'arrow1'} ${'shinkiButton'} ${'arrow2'}    ${'baikaiList'}   ${'arrow3'}   ${'shitagaki'} ${'arrow7'} ${'T3Publish'}" 1fr
  "${'T3Sakimono'} ${'arrow4'} ${'shinkiButton'} ${'arrow5'}    ${'sakimonoList'} ${'arrow6'}   ${'shitagaki'} ${'arrow7'} ${'T3Publish'}" 1fr
  / auto           40px        1fr               40px           auto              40px          1fr            40px        1fr
`
