import { TextInput } from '@/components/form/controls'
import { useContractByCaseId } from '@/hooks/useContractByCaseId'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const CloseDateInput = () => {
  const caseId = useV1ImportDocument(({ caseId }) => caseId)
  const baikaiKeiyaku = useContractByCaseId(caseId)
  const maxDate = baikaiKeiyaku?.endDate ?? undefined

  return <TextInput type="date" max={maxDate} />
}
