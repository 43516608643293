import { miraieFields } from '@terass/common/src'

import { FormGroupHeading } from '../FormGroupHeading'

import { BikeOkiba } from './BikeOkiba'
import { CarParking } from './CarParking'
import { CarParkingSell } from './CarParkingSell'
import { Churinjo } from './Churinjo'

import { FormGroup } from '@/components/form/FormGroup'
import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { CarParkingSellContents } from '@/components/form/PriceAndCost/CarParkingSellContents'
import {
  FieldLength,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  Textarea,
} from '@/components/form/controls'

export const Parkings: React.FC = () => {
  return (
    <>
      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum.売地
        }
      >
        <FormGroup>
          <FormGroupHeading>駐車場</FormGroupHeading>
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
            }
          >
            <CarParking />
            <CarParkingSellContents>
              <CarParkingSell />
            </CarParkingSellContents>
          </MiraieFieldContents>
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
            }
          >
            <FormControl fieldName="tato_parking">
              <FormLabel>駐車場説明文</FormLabel>
              <Textarea />
              <FieldLength maxLength={miraieFields.tato_parking.maxLength} />
              <PortalErrorMessages />
              <FrontendValidationErrorMessages />
            </FormControl>
          </MiraieFieldContents>
        </FormGroup>
      </MiraieFieldContents>
      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
        }
      >
        <FormGroup>
          <FormGroupHeading>駐輪場</FormGroupHeading>
          <Churinjo />
        </FormGroup>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
        }
      >
        <FormGroup>
          <FormGroupHeading>バイク置場</FormGroupHeading>
          <BikeOkiba />
        </FormGroup>
      </MiraieFieldContents>
    </>
  )
}
