import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { createFileRoute, useNavigate, Link } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import {
  baikaiToT7CloseDate,
  baikaiToT7Options,
  MediaPublishRequestForTeraSeven,
  baikaiShinseiToPostMiraie,
} from '@terass/common/src/tera-three'
import { availableOfficeNames } from '@terass/common/src/tera-three/office'
import { swapKeyValue } from '@terass/common/src/utils'
import { formatDate, parseDateString } from '@terass/common/src/utils/date'
import { Suspense, useState } from 'react'

import { PageHeading } from '@/components/Atoms'
import { ShopSelectOptions } from '@/components/ShopSelectOptions'
import { TbodySkeleton } from '@/components/TbodySkeleton'
import { useMediaPublishRequestForTeraSevens } from '@/hooks/useMediaPublishRequestForTeraSevens'
import { useOperator } from '@/hooks/useOperator'
import { useUserEmail } from '@/hooks/useUser'
import { createImport, updateImport } from '@/utils/import'
import { convertOfficeToShop } from '@/utils/officeToShop'
import { priceToJpString } from '@/utils/utils'

export const Route = createFileRoute('/contracts/')({
  component: MedContracts,
})

export function MedContracts() {
  return (
    <>
      <Flex justifyContent="space-between" my={2}>
        <PageHeading>媒体掲載・マイソク作成依頼選択</PageHeading>
        <Flex gap="8px">
          <Link to="/">
            <Button colorScheme="gray">キャンセル</Button>
          </Link>
        </Flex>
      </Flex>
      <Text>媒体掲載を行う媒体掲載・マイソク作成依頼を選択してください。</Text>
      <Box overflowX="scroll">
        <Table fontSize="sm" layout="fixed" mt={4}>
          <Thead bgColor="teraSeven.50">
            <Tr>
              <Th width={40}>契約種別</Th>
              <Th width={120}>ステータス</Th>
              <Th width={40}>物件種別</Th>
              <Th width={300}>物件名</Th>
              <Th width={140}>価格</Th>
              <Th width={260}>契約期間</Th>
              <Th width={150}>売主名</Th>
            </Tr>
          </Thead>
          <Tbody>
            <Suspense fallback={<TbodySkeleton rows={3} cols={7} />}>
              <ShinseiItems />
            </Suspense>
          </Tbody>
        </Table>
      </Box>
    </>
  )
}

const ShinseiItems = () => {
  const { email } = useOperator()
  const data = useMediaPublishRequestForTeraSevens({
    agentEmail: email,
  })

  return (
    <>
      {data.map((shinsei) => (
        <ShinseiCard shinsei={shinsei} key={shinsei.Id} />
      ))}
    </>
  )
}

type ShinseiCardProps = {
  shinsei: MediaPublishRequestForTeraSeven
}

const ShinseiCard = ({ shinsei }: ShinseiCardProps) => {
  const { email, office } = useOperator()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [officeName, setOfficeName] = useState<string>(
    availableOfficeNames.includes(office)
      ? office
      : (availableOfficeNames.at(0) ?? ''),
  )

  const {
    Id,
    Contract__r: {
      name,
      propertyId,
      mediationCategory,
      status,
      price,
      startDate,
      endDate,
      clientName,
    },
    PropertyType__c,
  } = shinsei
  const userEmail = useUserEmail()
  const createImportMutation = useMutation({
    mutationFn: async () => {
      const shopId = convertOfficeToShop(officeName)
      const data = await createImport({
        __readers: [email],
        __writers: [email],
        __tera_three: {
          bukkenId: propertyId ?? undefined,
        },
        caseId: Id,
        formData: {
          ...baikaiShinseiToPostMiraie(shinsei),
          sakimono_flg: miraieFields.sakimono_flg.Enum.自社,
        },
        teraSeven: {},
        shopId,
        createdBy: userEmail,
      })
      return data
    },
    onSuccess: async ({ id }) => {
      const {
        athome_c_open_kbn,
        homes_c_open_kbn,
        suumo_c_open_kbn,
        rakumachi_c_open_kbn,
      } = miraieFields.c_open_kbn.baikaiToT7OpenKbns(
        shinsei.PublicationMediumCategory__c,
      )
      const { osusume_keisai_flg, search_keisai_flg, staff_keisai_flg } =
        baikaiToT7Options(shinsei)
      // 各ポータルの掲載区分、掲載終了日、オプションは媒体掲載作成時にみらいえに反映されないよう、このタイミングで値を変換する
      // ref. https://www.notion.so/terass/7f1284cf129c47b2bd7ab5d4ad3bc6f3
      await updateImport(id, {
        'formData.athome_c_open_kbn': athome_c_open_kbn,
        'formData.homes_c_open_kbn': homes_c_open_kbn,
        'formData.suumo_c_open_kbn': suumo_c_open_kbn,
        'formData.rakumachi_c_open_kbn': rakumachi_c_open_kbn,
        'formData.osusume_keisai_flg': osusume_keisai_flg,
        'formData.search_keisai_flg': search_keisai_flg,
        'formData.staff_keisai_flg': staff_keisai_flg,
        teraSeven: {
          keisaiKikan: baikaiToT7CloseDate(shinsei),
        },
      })
      navigate({ to: '/bukkens/$importId/edit', params: { importId: id } })
    },
  })

  return (
    <>
      <Tr
        onClick={onOpen}
        _hover={{
          bgColor: 'gray.100',
          cursor: 'pointer',
        }}
      >
        <Td>
          {swapKeyValue(miraieFields.torihiki_kbn.Enum)[mediationCategory]}契約
        </Td>
        <Td>{status ?? '-'}</Td>
        <Td>{PropertyType__c}</Td>
        <Td>
          <Text>{name ?? '-'}</Text>
        </Td>
        <Td>{price ? priceToJpString(price) : '-'}</Td>
        <Td>
          {startDate && endDate
            ? `${formatDate(parseDateString(startDate))} 〜 ${formatDate(
                parseDateString(endDate),
              )}`
            : '-'}
        </Td>
        <Td>
          <Text>{clientName ?? '-'}</Text>
        </Td>
      </Tr>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        closeOnEsc={!createImportMutation.isPending}
        closeOnOverlayClick={!createImportMutation.isPending}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>媒体掲載作成</ModalHeader>
          <ModalCloseButton isDisabled={createImportMutation.isPending} />
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <Text>以下の申請から媒体掲載を作成します。</Text>

            <Box>
              <FormLabel fontWeight="semibold">物件名</FormLabel>
              <Text>{name ?? '---'}</Text>
            </Box>

            <Box>
              <FormLabel fontWeight="semibold">価格</FormLabel>
              <Text>{price ? priceToJpString(price) : '---'}</Text>
            </Box>

            <Heading size="sm">店舗を選択</Heading>
            <Select
              onChange={(e) => {
                setOfficeName(e.target.value)
              }}
              value={officeName}
            >
              <ShopSelectOptions />
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              colorScheme="gray"
              isDisabled={createImportMutation.isPending}
            >
              キャンセル
            </Button>
            <Button
              ml={3}
              onClick={() => createImportMutation.mutate()}
              isLoading={createImportMutation.isPending}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
