import { PostMiraieV1Import } from '../miraie/PostMiraieV1Import'
import { miraieFields } from '../miraie/fields'
import { getYmd } from '../utils/date'

import { SakimonoMediaPublishRequestForTeraSeven } from './SakimonoMediaPublishRequestForTeraSeven'

export function sakimonoShinseiToPostMiraie(
  shinsei: SakimonoMediaPublishRequestForTeraSeven,
): Omit<PostMiraieV1Import, 'bk_state_kbn'> {
  const bk_item_kbn =
    miraieFields.bk_item_kbn.T3MapSakimono[shinsei.PropertyType__c]
  const bk_sbt_kbn = miraieFields.bk_item_kbn.availableMap[bk_item_kbn]
  const right = shinsei.Rights__c.at(0)
  // 用途地域、建物構造はtera-three側では何個でも選べるがみらいえでは2つまでしか指定できないので、はじめの2つを使う
  const [yoto1, yoto2] = shinsei.Placement__c
  const [kozo1, kozo2] = shinsei.BuildingStructure__c
    ? shinsei.BuildingStructure__c
    : [undefined, undefined]
  const chikuYmd = shinsei.AgeOfBuilding__c
    ? getYmd(new Date(shinsei.AgeOfBuilding__c))
    : undefined
  const hikiwatashiYmd = shinsei.ExtraditionPeriod__c
    ? getYmd(new Date(shinsei.ExtraditionPeriod__c))
    : undefined

  return {
    bk_sbt_kbn,
    bk_item_kbn,
    tatemono_name: shinsei.PropertyNameText__c,
    price: miraieFields.price.convertToMiraiePrice(
      shinsei.ListingPrice__c,
      miraieFields.bk_sbt_kbn.isChintai(bk_sbt_kbn),
    ),
    genkyo_kbn:
      miraieFields.genkyo_kbn.T3MapSakimono[shinsei.PropertyCurrentStatus__c],
    tochi_pow_kbn: right && miraieFields.tochi_pow_kbn.T3MapSakimono[right],
    yoto_chiki_kbn_1: yoto1 && miraieFields.yoto_chiki_kbn.T3MapSakimono[yoto1],
    yoto_chiki_kbn_2: yoto2 && miraieFields.yoto_chiki_kbn.T3MapSakimono[yoto2],
    tate_kozo_kbn: kozo1 && miraieFields.tate_kozo_kbn.T3MapSakimono[kozo1],
    tate_ichibu_kozo_kbn:
      kozo2 && miraieFields.tate_ichibu_kozo_kbn.T3MapSakimono[kozo2],
    bal_menseki: shinsei.BalconyPatioArea__c ?? undefined,
    ...(chikuYmd
      ? {
          chiku_ymd_year: chikuYmd.year.toString(),
          chiku_ymd_month: chikuYmd.month.toString(),
        }
      : {}),
    ...(hikiwatashiYmd
      ? {
          hikiwatashi_ymd_year: hikiwatashiYmd.year.toString(),
          hikiwatashi_ymd_month: hikiwatashiYmd.month.toString(),
        }
      : {}),
    seko_company: shinsei.ConstructionCompany__c ?? undefined,
    kanrihi: shinsei.MonthlyAdministrativeExpenses__c ?? undefined,
    tsumitatekin: shinsei.MonthlyRepairReserveAmount__c ?? undefined,
    manage_hoshiki_kbn: shinsei.ManagementForm__c
      ? miraieFields.manage_hoshiki_kbn.T3MapSakimono[shinsei.ManagementForm__c]
      : undefined,
    mototsuke_gyousha_name: shinsei.ReferAccountText__c,
    mototsuke_gyousha_tel: shinsei.ReferAccountPhone__c,
    mototsuke_tanto_name: shinsei.ReferContactText__c,
  }
}
