import { Radio } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { Portal } from '@terass/common/src/portal'
import { formatDate } from '@terass/common/src/utils/date'
import { ChangeEventHandler } from 'react'

import {
  useMiraieFormContext,
  useMiraieFormController,
} from '@/hooks/useMiraieForm'

export type CloseOpenKubunRadioProps = {
  portal: Portal
}

export function CloseOpenKubunRadio({ portal }: CloseOpenKubunRadioProps) {
  const { setValue } = useMiraieFormContext()
  const {
    field: { value, ...field },
  } = useMiraieFormController({
    name: `${portal}_c_open_kbn`,
  })
  const isChecked = value === miraieFields.c_open_kbn.Enum.掲載期間外
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setValue(`draftCloseDates.${portal}`, formatDate(new Date()))
    field.onChange(e)
    // SUUMOを掲載しないに指定した場合は、有料オプションの指定を外す
    // SUUMOが掲載終了になった場合、tera-three側でもオプションを落とす必要があるため
    if (
      portal === 'suumo' &&
      e.target.value === miraieFields.c_open_kbn.Enum.掲載期間外
    ) {
      setValue('search_keisai_flg', undefined)
      setValue('staff_keisai_flg', undefined)
      setValue('osusume_keisai_flg', undefined)
    }
  }

  return (
    <Radio
      {...field}
      onChange={onChange}
      isChecked={isChecked}
      value={miraieFields.c_open_kbn.Enum.掲載期間外}
    >
      掲載しない
    </Radio>
  )
}
