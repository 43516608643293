import { z } from 'zod'

export const TeraThreeCredentials = z
  .object({
    clientId: z.string(),
    privateKey: z.string(),
    userName: z.string(),
    audience: z.string(),
  })
  .optional()
export type TeraThreeCredentials = z.infer<typeof TeraThreeCredentials>
