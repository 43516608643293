import { getShops } from '@terass/common/src/miraie/shop'

import { config } from './config'

export const Shops = getShops(config.isProduction ? 'prod' : 'test')

export const getT3PublishLink = ({
  isSakimono,
  baikaiKeiyakuId,
}: {
  isSakimono: boolean
  baikaiKeiyakuId?: string
}) =>
  isSakimono
    ? `${config.TERATHREE_URL}/s/mediacampaign/MediaCampaign__c/Default`
    : baikaiKeiyakuId
      ? // 個々の媒介契約の画面
        `${config.TERATHREE_URL}/s/contract/${baikaiKeiyakuId}`
      : // 契約一覧画面
        `${config.TERATHREE_URL}/s/contract/Contract__c/Default`

export const StaticLink = {
  /** エージェント向けマニュアル */
  agentManual:
    'https://www.notion.so/terass/Terass-Converter-d015279d13c5460abd3862fd19cb9821',
} as const
