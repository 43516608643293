import { useSuspenseQuery } from '@tanstack/react-query'

import { fetchSakimonoMediaPublishRequestForTeraSevens } from '@/utils/functions'

export function useSakimonoMediaPublishRequestForTeraSevens(
  ...[params]: Parameters<typeof fetchSakimonoMediaPublishRequestForTeraSevens>
) {
  return useSuspenseQuery({
    queryKey: ['sakimonoMediaPublishRequestForTeraSevens', params],
    queryFn() {
      return fetchSakimonoMediaPublishRequestForTeraSevens(params)
    },
  }).data
}
