import { TeraSeven } from '../firestore/TeraSeven'
import { portals, T3PortalMapBaikai } from '../portal'

import {
  MediaPublishRequestForTeraSeven,
  SakimonoMediaPublishRequestForTeraSeven,
} from '.'

export function baikaiToT7CloseDate({
  PublicationMediumCategory__c,
  MediaCloseDate__c,
}: Pick<
  MediaPublishRequestForTeraSeven,
  'PublicationMediumCategory__c' | 'MediaCloseDate__c'
>): TeraSeven['keisaiKikan'] {
  if (!MediaCloseDate__c) return {}

  const keisaiKikan: ReturnType<typeof baikaiToT7CloseDate> = {}
  portals.forEach((portal) => {
    if (
      // 楽待は非対応のため除外
      portal !== 'rakumachi' &&
      PublicationMediumCategory__c[T3PortalMapBaikai[portal]]
    )
      keisaiKikan[portal] = {
        to: MediaCloseDate__c,
      }
  })

  return keisaiKikan
}

export function sakimonoToT7CloseDate({
  PublicationMediumCategory__c,
  MediaCloseDate__c,
}: Pick<
  SakimonoMediaPublishRequestForTeraSeven,
  'PublicationMediumCategory__c' | 'MediaCloseDate__c'
>): TeraSeven['keisaiKikan'] {
  if (!MediaCloseDate__c) return {}

  const keisaiKikan: ReturnType<typeof baikaiToT7CloseDate> = {}
  portals.forEach((portal) => {
    if (
      // 楽待は非対応のため除外
      portal !== 'rakumachi' &&
      PublicationMediumCategory__c[T3PortalMapBaikai[portal]]
    )
      keisaiKikan[portal] = {
        to: MediaCloseDate__c,
      }
  })

  return keisaiKikan
}
