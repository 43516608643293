import { z } from 'zod'

import { miraieFields } from '../miraie/fields'

export function parseBaikaikeiayku(data: unknown) {
  return BaikaiKeiyaku.parse(data)
}

/** tera-three側の物件種別（tera-sevenで対応しているもののみ） */
const T3PropertyType = z.enum([
  // 戸建て
  'House',
  // マンション
  'Apartment',
  // 土地
  'Land',
])
export type T3PropertyType = z.infer<typeof T3PropertyType>

/** 土地権利内容 */
const LandRight = z.enum(['所有権', '借地権'])
type LandRight = z.infer<typeof LandRight>

/** 媒介契約ステータス */
const ContractSellMediationStatus = z.enum([
  '契約準備中',
  '契約完了',
  'REINS掲載済み',
  'クローズ',
  'キャンセル',
])
type ContractSellMediationStatus = z.infer<typeof ContractSellMediationStatus>

/**
 * tera-three から取得する先物媒体掲載依頼のスキーマ
 */
export const BaikaiKeiyaku = z
  .object({
    Id: z.string(),
    MediationCategory__c: MediationCategory(),
    Status__c: ContractSellMediationStatus.nullish(),
    Property__r: z.object({
      Id: z.string().nullish(),
      Name: z.string().nullish(),
      MansionFloor__c: z.number().nullish(),
      LandRight__c: LandRight.nullish(),
      RecordType: z.object({
        DeveloperName: T3PropertyType,
      }),
    }),
    PropertyPriceTaxIncluded__c: z.number().nullish(),
    ContractStartDateTime__c: z.string().nullish(),
    ContractEndDateTime__c: z.string().nullish(),
    ContractNameClient__c: z.string().nullish(),
  })
  .transform(
    ({
      Id,
      MediationCategory__c,
      Status__c,
      Property__r: {
        Id: propertyId,
        Name,
        MansionFloor__c,
        LandRight__c,
        RecordType: { DeveloperName },
      },
      PropertyPriceTaxIncluded__c,
      ContractStartDateTime__c,
      ContractEndDateTime__c,
      ContractNameClient__c,
    }) => ({
      id: Id,
      name: Name,
      mediationCategory: MediationCategory__c,
      status: Status__c,
      propertyId,
      mansionFloor: MansionFloor__c,
      price: PropertyPriceTaxIncluded__c,
      startDate: ContractStartDateTime__c,
      endDate: ContractEndDateTime__c,
      clientName: ContractNameClient__c,
      ...getPropertyKbn(DeveloperName, LandRight__c ?? undefined),
    }),
  )

export type BaikaiKeiyaku = z.output<typeof BaikaiKeiyaku>

export const BaikaiKeiyakuList = z.array(BaikaiKeiyaku)

function MediationCategory() {
  return z.enum(['専任媒介', '専属媒介', '一般媒介']).transform(
    (value) =>
      ({
        専任媒介: miraieFields.torihiki_kbn.Enum.専任媒介,
        専属媒介: miraieFields.torihiki_kbn.Enum.専属専任媒介,
        一般媒介: miraieFields.torihiki_kbn.Enum.一般媒介,
      })[value],
  )
}

/** tera-three側の物件種別をみらいえに投げる区分値に変換 */
const getPropertyKbn = (type: T3PropertyType, landRight?: LandRight) => {
  switch (type) {
    case 'House':
      return {
        bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum.売戸建住宅,
        bk_item_kbn: miraieFields.bk_item_kbn.Enum.売戸建,
      }
    case 'Apartment':
      return {
        bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum.売マンション,
        bk_item_kbn: miraieFields.bk_item_kbn.Enum.売マンション,
      }
    case 'Land':
      switch (landRight) {
        case '所有権':
          return {
            bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum.売地,
            bk_item_kbn: miraieFields.bk_item_kbn.Enum.所有権譲渡,
          }
        case '借地権':
          return {
            bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum.売地,
            bk_item_kbn: miraieFields.bk_item_kbn.Enum.借地権譲渡,
          }
        // landRightが不明な場合は、デフォルトで所有権として扱う
        default:
          return {
            bk_sbt_kbn: miraieFields.bk_sbt_kbn.Enum.売地,
            bk_item_kbn: miraieFields.bk_item_kbn.Enum.所有権譲渡,
          }
      }
  }
  throw new Error('Unexpected property type')
}
