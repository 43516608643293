import { useMutation } from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'
import { portals } from '@terass/common/src/portal'
import {
  getMaxDate,
  validateCloseDate,
} from '@terass/common/src/tera-seven/fields/keisaiKikan'
import { callThis } from '@terass/common/src/utils'
import {
  addDays,
  formatDate,
  parseDateString,
} from '@terass/common/src/utils/date'

import { useFrontendValidation } from '@/components/form/FrontendValidation'
import { useContractByCaseId } from '@/hooks/useContractByCaseId'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { getServerTimestamp } from '@/utils/functions'

export const useValidateMutation = (onSuccess?: () => void) => {
  const caseId = useV1ImportDocument(({ caseId }) => caseId)
  const teraThree = useContractByCaseId(caseId)
  const { trigger, getValues, clearErrors, setError } = useMiraieFormContext()
  const { validate: validateFrontedValidation } = useFrontendValidation()

  return useMutation({
    mutationFn: async () => {
      if (
        teraThree?.status &&
        teraThree.status !== '契約完了' &&
        teraThree.status !== 'REINS掲載済み'
      )
        throw new Error(
          '媒介契約のステータスが契約完了または REINS 掲載済みのどちらかでないと公開できません。',
        )

      const validateResult = await trigger()
      if (!validateResult)
        throw new Error(
          '入力内容に誤りがあります。媒体掲載を公開できませんでした。',
        )

      const { draftCloseDates, ...formData } = getValues()
      if (
        !miraieFields.images.isUniqueMd5([
          ...(formData.images || []),
          ...(formData.shisetsu || []),
        ])
      )
        throw new Error(
          '画像が重複しています。画像または周辺環境から重複している画像を別の画像に差し替えてください。',
        )

      const serverDate = new Date(await getServerTimestamp())
      const contractEndDate = teraThree?.endDate
        ? parseDateString(teraThree.endDate)
        : null
      const maxDate = getMaxDate(serverDate, 38, contractEndDate)
      const errorMessage = `掲載終了日は ${formatDate(callThis(serverDate, addDays, 1))} ~ ${formatDate(maxDate)} の期間内である必要があります。`
      const validateCloseDateResult = portals
        .filter(
          (portal) =>
            formData[`${portal}_c_open_kbn`] ===
            miraieFields.c_open_kbn.Enum.掲載,
        )
        .reduce((result, portal) => {
          const endDate = draftCloseDates[portal]
          if (
            endDate &&
            validateCloseDate(parseDateString(endDate), serverDate, maxDate)
          ) {
            clearErrors(`draftCloseDates.${portal}`)
            return result
          }

          setError(`draftCloseDates.${portal}`, {
            types: {
              [portal]: errorMessage,
            },
          })
          return false
        }, true)
      if (!validateCloseDateResult) throw new Error(errorMessage)

      const result = validateFrontedValidation(getValues())
      if (result) {
        throw new Error('禁止ワードが含まれています。')
      }
    },
    onSuccess,
  })
}
