import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'

import { CenteringGridItem } from './CenteringGridItem'

import { gridTemplate } from '@/utils/gridTemplate'

export const T3BaikaiUpdateAccordionItem = () => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          媒介契約更新による媒体掲載の更新
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Grid
          gridTemplate={style}
          justifyItems="center"
          alignItems="center"
          height={300}
          width={500}
        >
          <CenteringGridItem
            gridArea={getArea('T3Header')}
            width="100%"
            borderColor="teraThree.500"
          >
            <Text>Terass Cloud</Text>
          </CenteringGridItem>
          <CenteringGridItem
            gridArea={getArea('T7Header')}
            width="100%"
            borderColor="teraSeven.500"
          >
            <Text>Terass Converter</Text>
          </CenteringGridItem>
          <CenteringGridItem
            gridArea={getArea('T3Baikai')}
            height="90%"
            width="100%"
            borderColor="teraThree.500"
          >
            <Text>媒介契約更新</Text>
          </CenteringGridItem>
          <GridItem gridArea={getArea('arrow')}>
            <FaArrowRight />
          </GridItem>
          <CenteringGridItem
            gridArea={getArea('T7Baitai')}
            height="90%"
            width="100%"
            borderColor="teraSeven.500"
          >
            <Text>媒体掲載更新</Text>
          </CenteringGridItem>
        </Grid>
        <Text>
          Terass
          Cloud上で媒介契約が更新された場合、媒体掲載も自動で更新されます。
        </Text>
        <Text mt={2}>
          ※Terass
          Conveterで価格、期間以外の情報も修正し、下書き保存している場合は、その情報も併せて更新されますのでご注意ください。
        </Text>
      </AccordionPanel>
    </AccordionItem>
  )
}

const { style, getArea } = gridTemplate`
  "${'T3Header'}   ${'...'}      ${'T7Header'}"      auto
  "${'T3Baikai'}   ${'arrow'}    ${'T7Baitai'}"     1fr
  / 1fr           40px           1fr         
`
