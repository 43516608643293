import {
  BuildingStructureBaikai,
  BuildingStructureSakimono,
} from '../../tera-three'
import { ValueOf } from '../../types'

/**
 * 建物構造区分
 */
export const Enum = {
  木造: '1',
  ブロック: '2',
  鉄骨: '3',
  RC: '4',
  SRC: '5',
  PC: '6',
  HPC: '7',
  軽量鉄骨: '8',
  ALC: '9',
  その他: '99',
} as const

export const T3MapBaikai: Record<
  BuildingStructureBaikai,
  ValueOf<typeof Enum>
> = {
  木造: Enum.木造,
  軽量鉄骨造: Enum.軽量鉄骨,
  重量鉄骨造: Enum.鉄骨,
  鉄骨造: Enum.鉄骨,
  鉄筋コンクリート造: Enum.RC,
  鉄骨鉄筋コンクリート造: Enum.SRC,
  石造: Enum.その他,
  コンクリートブロック造: Enum.ブロック,
  土蔵造: Enum.その他,
  れんが造: Enum.その他,
  木骨石造: Enum.その他,
  木骨煉瓦造: Enum.その他,
  土地のため無し: Enum.その他,
}

export const T3MapSakimono: Record<
  BuildingStructureSakimono,
  ValueOf<typeof Enum>
> = {
  木造: Enum.木造,
  軽量鉄骨造: Enum.軽量鉄骨,
  重量鉄骨造: Enum.鉄骨,
  鉄骨造: Enum.鉄骨,
  鉄筋コンクリート造: Enum.RC,
  鉄骨鉄筋コンクリート造: Enum.SRC,
  石造: Enum.その他,
  コンクリートブロック造: Enum.ブロック,
  土蔵造: Enum.その他,
  れんが造: Enum.その他,
  木骨石造: Enum.その他,
  木骨煉瓦造: Enum.その他,
  土地のため無し: Enum.その他,
}
