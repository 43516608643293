import { Box, Card, CardBody, Flex, GridItem, Grid } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { HourOptions } from './HourOptions'
import { MinuteOptions } from './MinuteOptions'
import { OpenHouseBikoHomesContents } from './OpenHouseBikoHomesContents'
import { OpenHouseBikoLength } from './OpenHouseBikoLength'

import { Contents } from '@/components/Contents'
import { FormSectionHeading } from '@/components/form/FormSectionHeading'
import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import { KaisaibiControls } from '@/components/form/OpenHouse/KaisaibiControls'
import { OpenHouseBaibaiContents } from '@/components/form/OpenHouse/OpenHouseBaibaiContents'
import { OpenHouseSectionContents } from '@/components/form/OpenHouse/OpenHouseSectionContents'
import { sectionIds } from '@/components/form/SectionBar'
import {
  EnumOptions,
  EnumRadios,
  FieldLength,
  FormControl,
  FormHelperText,
  FormLabel,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const OpenHouseSection = () => {
  return (
    <OpenHouseSectionContents>
      <Box as="section" id={sectionIds.openHouseSection}>
        <FormSectionHeading>オープンハウス・イベント関連</FormSectionHeading>
        <Card>
          <CardBody as={FormGroup}>
            <FormGroupHeading>オープンハウス・イベントなど</FormGroupHeading>
            <OpenHouseBaibaiContents>
              <FormGroup>
                <FormControl fieldName="openhouse_kbn">
                  <FormLabel>タイトル</FormLabel>
                  <Select>
                    <PlaceholderOption />
                    <EnumOptions enum={miraieFields.openhouse_kbn.Enum} />
                  </Select>
                  <FormHelperText display="grid" justifyItems="start">
                    athomeは種別毎でタイトルが固定されているため種別により下記の反映となります。
                    <Grid templateColumns="repeat(3,auto)" columnGap={2}>
                      {(
                        [
                          ['オープンハウス', '戸建てのみ反映'],
                          ['モデルルーム', 'マンションのみ反映'],
                          ['モデルハウス', '戸建てのみ反映'],
                          ['現地見学会', '一律反映されない'],
                          ['現地案内会', '一律反映されない'],
                          ['オープンルーム', 'マンションのみ反映'],
                          ['現地販売会', '土地のみ反映'],
                          ['指定無し', '一律反映される'],
                          ['空欄（未選択）', '一律反映されない'],
                        ] as const
                      ).map(([value, description]) => (
                        <Contents key={value}>
                          <GridItem>{value}</GridItem>
                          <GridItem>⇒</GridItem>
                          <GridItem>{description}</GridItem>
                        </Contents>
                      ))}
                    </Grid>
                  </FormHelperText>
                  <PortalErrorMessages />
                </FormControl>
                <FormGroup nested>
                  <FormControl fieldName="openhouse_toi_kbn">
                    <FormLabel>案内事項</FormLabel>
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions enum={miraieFields.openhouse_toi_kbn.Enum} />
                    </Select>
                    <PortalErrorMessages />
                  </FormControl>
                </FormGroup>
                <FormControl fieldName="tenanto_status_com" fieldset>
                  <FormLabel>日程</FormLabel>
                  <RadioGroup>
                    <UnselectedRadio />
                    <EnumRadios enum={miraieFields.tenanto_status_com.Enum} />
                  </RadioGroup>
                  <PortalErrorMessages />
                </FormControl>
              </FormGroup>
            </OpenHouseBaibaiContents>
            <KaisaibiControls />
            <FormControl fieldset>
              <FormLabel>開催時間</FormLabel>
              <Flex gap={2} alignItems="center">
                <FormControl fieldName="openhouse_from_time_hour">
                  <Select>
                    <HourOptions />
                  </Select>
                </FormControl>
                :
                <FormControl fieldName="openhouse_from_time_minute">
                  <Select>
                    <MinuteOptions />
                  </Select>
                </FormControl>
                ~
                <FormControl fieldName="openhouse_to_time_hour">
                  <Select>
                    <HourOptions />
                  </Select>
                </FormControl>
                :
                <FormControl fieldName="openhouse_to_time_minute">
                  <Select>
                    <MinuteOptions />
                  </Select>
                </FormControl>
              </Flex>
              <PortalErrorMessages fieldName="openhouse_from_time_hour" />
              <PortalErrorMessages fieldName="openhouse_from_time_minute" />
              <PortalErrorMessages fieldName="openhouse_to_time_hour" />
              <PortalErrorMessages fieldName="openhouse_to_time_minute" />
            </FormControl>
            <FormControl fieldName="openhouse_biko">
              <FormLabel>備考</FormLabel>
              <Textarea />
              <OpenHouseBikoLength mt={2} />
              <PortalErrorMessages />
              <FrontendValidationErrorMessages />
            </FormControl>
            <OpenHouseBikoHomesContents>
              <FormControl fieldName="openhouse_biko_homes">
                <FormLabel>備考HOME'S専用</FormLabel>
                <Textarea />
                <FieldLength
                  maxLength={miraieFields.openhouse_biko_homes.maxLength}
                  mt={2}
                />
                <PortalErrorMessages />
                <FrontendValidationErrorMessages />
              </FormControl>
            </OpenHouseBikoHomesContents>
          </CardBody>
        </Card>
      </Box>
    </OpenHouseSectionContents>
  )
}
