import { useSuspenseQuery } from '@tanstack/react-query'

import { fetchMediaPublishRequestForTeraSevens } from '@/utils/functions'

export function useMediaPublishRequestForTeraSevens(
  ...[params]: Parameters<typeof fetchMediaPublishRequestForTeraSevens>
) {
  return useSuspenseQuery({
    queryKey: ['mediaPublishRequestForTeraSeven', params],
    queryFn() {
      return fetchMediaPublishRequestForTeraSevens(params)
    },
  }).data
}
