import {
  queryOptions,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'
import { V1Import } from '@terass/common/src/firestore/V1Import'
import {
  DocumentReference,
  DocumentSnapshot,
  UpdateData,
  doc,
  getDoc,
  orderBy,
  query,
  snapshotEqual,
  where,
} from 'firebase/firestore'

import {
  UsePaginatedQueryProps,
  usePaginatedQuery,
} from '@/hooks/usePaginatedQuery'
import { getCollection } from '@/utils/firestore'

export const types = ['contracts', 'futures'] as const
export type Type = (typeof types)[number]

export const typeLabel = {
  futures: '先物掲載',
  contracts: '売却媒介契約',
} as const

export const statuses = [
  'draft',
  'published',
  'closed',
] as const satisfies V1Import['status'][]

export const statusLabel = {
  draft: '下書き',
  published: '掲載中',
  closed: '掲載停止中',
} as const

export type FilterConditions = {
  status: V1Import['status'][]
  type: Type[]
}

export type UseV1ImportCollectionProps = {
  where: {
    status: V1Import['status'][]
    readerEmail: string
    type: Type[]
  }
  orderBy: {
    field: keyof Pick<
      V1Import,
      'draftedAt' | 'publishedAt' | 'closedAt' | 'createdAt' | 'updatedAt'
    >
    direction: 'asc' | 'desc'
  }
  cursor: string | undefined
} & Pick<
  UsePaginatedQueryProps<V1Import>,
  'direction' | 'onNext' | 'onPrevious'
>

export function useV1ImportCollection(props: UseV1ImportCollectionProps) {
  const {
    where: { status, readerEmail, type },
    cursor,
    direction,
    onNext,
    onPrevious,
  } = props
  const cursorSnapshot = useCursorSnapshot(
    cursor ? doc(getCollection('v1import'), cursor) : null,
  )
  const setCursorSnapshot = useSetCursorSnapshot<V1Import>()

  const constraints = [
    where('__readers' satisfies keyof V1Import, 'array-contains', readerEmail),
    // 空配列（フィルタ条件の選択無し）はフィルタ条件無しとして扱う
    status.length
      ? where('status' satisfies keyof V1Import, 'in', status)
      : null,
    type.length
      ? where(
          'formData.sakimono_flg' satisfies keyof UpdateData<V1Import>,
          'in',
          type.map(
            (t) =>
              miraieFields.sakimono_flg.Enum[
                t === 'contracts' ? '自社' : '先物'
              ],
          ),
        )
      : null,
  ].filter((v) => v !== null)

  return usePaginatedQuery({
    queryDeps: [
      status,
      readerEmail,
      props.orderBy.field,
      props.orderBy.direction,
      type,
    ],
    query: query(
      getCollection('v1import'),
      orderBy(
        props.orderBy.field satisfies keyof V1Import,
        props.orderBy.direction,
      ),
      ...constraints,
    ),
    pageSize: 10,
    onNext: (snapshot) => {
      setCursorSnapshot(snapshot)
      onNext(snapshot)
    },
    onPrevious: (snapshot) => {
      setCursorSnapshot(snapshot)
      onPrevious(snapshot)
    },
    cursorSnapshot,
    direction,
    snapshotOptions: { serverTimestamps: 'estimate' },
  })
}

function cursorSnapshotQueryOptions<T>(cursorRef: DocumentReference<T> | null) {
  return queryOptions({
    queryKey: ['firestore', 'DocumentSnapshot', cursorRef?.path],
    queryFn: () => (cursorRef ? getDoc(cursorRef) : null),
    structuralSharing: (oldData, newData) =>
      oldData instanceof DocumentSnapshot && newData instanceof DocumentSnapshot
        ? snapshotEqual(oldData, newData)
          ? oldData
          : newData
        : newData,
  })
}

function useCursorSnapshot<T>(cursorRef: DocumentReference<T> | null) {
  return useSuspenseQuery(cursorSnapshotQueryOptions(cursorRef)).data
}

function useSetCursorSnapshot<T>() {
  const queryClient = useQueryClient()
  return (snapshot: DocumentSnapshot<T>) =>
    queryClient.setQueryData(
      cursorSnapshotQueryOptions(snapshot.ref).queryKey,
      snapshot,
    )
}
