import { useSuspenseQuery } from '@tanstack/react-query'

import { fetchContractByCaseId } from '@/utils/functions'

export function useContractByCaseId(caseId?: string) {
  return useSuspenseQuery({
    queryKey: ['contractByCaseId', caseId],
    queryFn() {
      if (caseId === undefined) return null
      return fetchContractByCaseId(caseId)
    },
  }).data
}
