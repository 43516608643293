import { Flex } from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'

import { PageHeading } from '@/components/Atoms'
import { FormContainer } from '@/components/FormContainer'
import { DuplicateButton } from '@/components/detail/DuplicateButton'
import { EditButton } from '@/components/detail/EditButton'
import { BaitaiForm } from '@/components/form/BaitaiForm'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const Route = createFileRoute('/bukkens/$importId/')({
  component: ImportDetail,
})

export function ImportDetail() {
  const tatemono_name = useV1ImportDocument(
    ({ formData: { tatemono_name } }) => tatemono_name,
  )
  return (
    <FormContainer>
      <PageHeading mt={2}>媒体掲載詳細/{tatemono_name}</PageHeading>
      <BaitaiForm>
        <Flex position="sticky" bottom={4} gap={4}>
          <EditButton>編集する</EditButton>
          <DuplicateButton>複製して編集する</DuplicateButton>
        </Flex>
      </BaitaiForm>
    </FormContainer>
  )
}
