import { z } from 'zod'

import { ErrorPortal, portals } from '../portal'

import { miraieFields } from './fields'

export type PostMiraieV1ImportResponse = z.infer<
  typeof PostMiraieV1ImportResponse
>
export const PostMiraieV1ImportResponse = z.object({
  links: z.object({
    self: z.string(),
  }),
  data: z.object({
    status: z.string(),
    code: z.number(),
    save_mode: z.string(),
    log_database: z.array(z.string()),
    log_param: z.record(z.string()).or(z.array(z.string())),
    error_message: z
      .union([
        z
          .object({
            time: z.object({
              validate: z.number().nullish(),
            }),
            // 中身が無いときは空のarray、あるときはobjectが返ってくる
            validate: z.array(z.unknown()).or(z.record(z.string())),
          })
          .and(PortalErrors()),
        z.literal(false),
      ])
      .nullable(),
    open_status: OpenStatus(),
    bukken_info: z.object({
      bk_id: z.string(),
      bk_cd: z.string(),
    }),
  }),
})

function OpenStatus() {
  const OpenStatusFlg = z.object({
    // WTF 2や"2"が帰ってくるので、stringにキャストする
    before: z.coerce.string().pipe(z.nativeEnum(miraieFields.c_open_kbn.Enum)),
    after: z.coerce.string().pipe(z.nativeEnum(miraieFields.c_open_kbn.Enum)),
  })
  return z.object({
    suumo_flg: OpenStatusFlg,
    homes_flg: OpenStatusFlg,
    athome_flg: OpenStatusFlg,
    rakumachi_flg: OpenStatusFlg.optional(),
  })
}

function Messages() {
  return z
    .tuple([])
    .transform(() => ({}))
    .or(z.record(z.string()))
}

function KinsokuMessages() {
  return z.object({
    crit: KinsokuError(),
    warn: KinsokuError(),
  })
}

function KinsokuError() {
  return z
    .tuple([])
    .transform(() => ({}))
    .or(
      z.record(
        z.string(),
        z.array(
          z
            .object({
              message: z.string(),
            })
            .passthrough(),
        ),
      ),
    )
}

function PortalError() {
  return z
    .object({
      validate: Messages(),
      kinsoku: KinsokuMessages().optional(),
    })
    .optional()
}

function PortalErrors() {
  return z
    .object({
      公取: z
        .object({
          validate: Messages(),
        })
        .optional(),
      athome: PortalError(),
      homes: PortalError(),
      rakumachi: PortalError(),
      suumo: PortalError(),
    })
    .transform((portalErrors) => {
      const fieldErrors: MiraieFieldErrors = {}
      ;([...portals, '公取'] as const).forEach((portal) => {
        const portalError = portalErrors[portal]?.validate
        Object.entries(portalError || {}).forEach(([fieldNames, message]) =>
          // カンマ区切りでフィールド名が連結している場合があるので、
          // バラしてそれぞれのフィールドにエラーを追加する
          fieldNames.split(',').forEach((fieldName) => {
            fieldErrors[fieldName] = {
              ...(fieldErrors[fieldName] || {}),
              [portal]: message,
            }
          }),
        )
        if (portal === '公取') return
        const kinsokuErrors = portalErrors[portal]?.kinsoku?.crit
        if (!kinsokuErrors) return
        Object.entries(kinsokuErrors).forEach(([fieldName, errors]) => {
          if (!errors[0]) return
          fieldErrors[fieldName] = {
            ...(fieldErrors[fieldName] || {}),
            // kinsokuエラーは1つのフィールドに対し複数返ってくる可能性があるが、初めの1つのみを書き込む
            [portal]: errors[0].message,
          }
        })
      })

      return { fieldErrors }
    })
}

type MiraieFieldErrors = {
  [x in string]?: {
    [P in ErrorPortal]?: string
  }
}

/**
 * fieldErrorsに、その他諸々のエラーを加える
 * 具体的には、response.log_paramとresponse.error_message.validateに入っているもの
 */
export function setOtherErrors(
  response: PostMiraieV1ImportResponse['data'] | undefined,
): MiraieFieldErrors {
  const fieldErrors = structuredClone(
    (response?.error_message && response.error_message.fieldErrors) || {},
  )

  const logParam = response?.log_param
  const validateErrors =
    response?.error_message && response.error_message.validate

  if (logParam && !Array.isArray(logParam))
    for (const key in logParam) {
      // 入力された路線名、駅名でみらいえ側で駅を見つけられなかった場合のエラー
      if (/^ensen_name_\d+,eki_name_\d+$/.test(key))
        key.split(',').forEach((fieldName) => {
          fieldErrors[fieldName] = {
            ...fieldErrors[fieldName],
            logParam: logParam[key],
          }
        })
    }

  if (validateErrors && !Array.isArray(validateErrors))
    for (const key in validateErrors)
      fieldErrors[key] = {
        ...fieldErrors[key],
        validate: validateErrors[key],
      }

  // 築年月に関するエラーは `chiku_ymd` でしか返ってこないので `chiku_ymd_year` と `chiku_ymd_month` に分割する
  if (fieldErrors.chiku_ymd) {
    fieldErrors.chiku_ymd_year = fieldErrors.chiku_ymd
    fieldErrors.chiku_ymd_month = fieldErrors.chiku_ymd
    delete fieldErrors.chiku_ymd
  }

  return fieldErrors
}
