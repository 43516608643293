import { PlacementBaikai, PlacementSakimono } from '../../tera-three'
import { ValueOf } from '../../types'

/**
 * 用途地域区分
 */
export const Enum = {
  近隣商業: '4',
  商業: '5',
  準工業: '6',
  工業: '7',
  工専: '8',
  指定無: '9',
  '1種低層': '11',
  '2種低層': '12',
  '1種中高': '13',
  '2種中高': '14',
  '1種住居': '15',
  '2種住居': '16',
  準住居: '17',
  田園住居: '18',
} as const

export const T3MapBaikai: Record<PlacementBaikai, ValueOf<typeof Enum>> = {
  指定なし: Enum.指定無,
  近隣商業地域: Enum.近隣商業,
  商業地域: Enum.商業,
  準工業地域: Enum.準工業,
  工業地域: Enum.工業,
  工業専用地域: Enum.工専,
  第一種低層住居専用地域: Enum['1種低層'],
  第二種低層住居専用地域: Enum['2種低層'],
  第一種中高層住居専用地域: Enum['1種中高'],
  第二種中高層住居専用地域: Enum['2種中高'],
  第一種住居地域: Enum['1種住居'],
  第二種住居地域: Enum['2種住居'],
  準住居地域: Enum.準住居,
  田園住居地域: Enum.田園住居,
}

export const T3MapSakimono: Record<PlacementSakimono, ValueOf<typeof Enum>> = {
  指定なし: Enum.指定無,
  近隣商業地域: Enum.近隣商業,
  商業地域: Enum.商業,
  準工業地域: Enum.準工業,
  工業地域: Enum.工業,
  工業専用地域: Enum.工専,
  第一種低層住居専用地域: Enum['1種低層'],
  第二種低層住居専用地域: Enum['2種低層'],
  第一種中高層住居専用地域: Enum['1種中高'],
  第二種中高層住居専用地域: Enum['2種中高'],
  第一種住居地域: Enum['1種住居'],
  第二種住居地域: Enum['2種住居'],
  準住居地域: Enum.準住居,
  田園住居地域: Enum.田園住居,
}
