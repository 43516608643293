import { Center, Grid, Icon, VisuallyHidden } from '@chakra-ui/react'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { miraieFields } from '@terass/common/src'
import { MdDragIndicator } from 'react-icons/md'

import {
  Provider,
  ImageControlsContext,
  getImageControlsArea,
  gridTemplateStyle,
} from '@/components/form/BukkenImages/Controls/ImageControlsContext'
import { ImageInput } from '@/components/form/BukkenImages/Controls/ImageInput'
import { ImgComTextarea } from '@/components/form/BukkenImages/Controls/ImgComTextarea'
import { ImgKokaiKbnCheckbox } from '@/components/form/BukkenImages/Controls/ImgKokaiKbnCheckbox'
import { ImgSbtKbnSelect } from '@/components/form/BukkenImages/Controls/ImgSbtKbnSelect'
import { ImgTitleTextarea } from '@/components/form/BukkenImages/Controls/ImgTitleTextarea'
import { RemoveImageButton } from '@/components/form/BukkenImages/Controls/RemoveImageButton'
import { Select } from '@/components/form/BukkenImages/Controls/Select'
import { Textarea } from '@/components/form/BukkenImages/Controls/Textarea'
import { useBukkenImagesFormContext } from '@/components/form/BukkenImages/hooks/index'
import { PlaceholderOption, EnumOptions } from '@/components/form/controls'

export type ImageControlsProps = ImageControlsContext & {
  id: string
}

export const ImageControls = ({ category, index, id }: ImageControlsProps) => {
  const {
    setNodeRef,
    setActivatorNodeRef,
    attributes,
    transform,
    listeners,
    transition,
    isDragging,
  } = useSortable({ id })
  const {
    formState: { errors },
  } = useBukkenImagesFormContext()

  return (
    <Grid
      gridTemplate={gridTemplateStyle}
      gap={2}
      ref={setNodeRef}
      bg="white"
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
        ...(isDragging && {
          zIndex: 1,
        }),
      }}
    >
      <Provider value={{ category, index }}>
        <Center
          ref={setActivatorNodeRef}
          {...attributes}
          {...listeners}
          gridArea={getImageControlsArea('dragHandle')}
        >
          <Icon as={MdDragIndicator} />
        </Center>
        <ImageInput />
        {category === miraieFields.images.category.Enum.プレゼント画像 ? (
          <>
            {/**
             * 高さを他と揃えるため透明にしてレンダリング
             * react-hook-form に値を登録しておかないと submit 時に値が undefined になってしまうのを避けるためでもある
             */}
            <ImgSbtKbnSelect
              opacity={0}
              userSelect="none"
              tabIndex={-1}
              icon={<></>}
            />
            <ImgComTextarea opacity={0} userSelect="none" tabIndex={-1} />
            <Textarea
              fieldName="present_caption"
              placeholder="プレゼント画像キャプション(200文字)"
              gridArea={getImageControlsArea('img_title')}
              gridRow="1/-1"
              h="auto"
              isInvalid={errors.present_caption !== undefined}
            />
          </>
        ) : category === miraieFields.images.category.Enum.パンフレット画像 ? (
          <>
            <VisuallyHidden>
              <ImgSbtKbnSelect />
            </VisuallyHidden>
            <Select
              fieldName="pamphlet_info_caption_kbn"
              gridArea={getImageControlsArea('img_sbt_kbn')}
            >
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.pamphlet_info_caption_kbn.Enum} />
            </Select>
            <ImgComTextarea gridRow="2/-1" gridArea={undefined} />
          </>
        ) : (
          <>
            <ImgSbtKbnSelect />
            <ImgTitleTextarea />
            <ImgComTextarea />
          </>
        )}
        <Center gridArea={getImageControlsArea('img_kokai_kbn')}>
          <Grid gap={2} placeItems="center">
            <ImgKokaiKbnCheckbox />
            <RemoveImageButton />
          </Grid>
        </Center>
      </Provider>
    </Grid>
  )
}
