import { HTMLChakraProps, chakra } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'
import { serverTimestamp } from 'firebase/firestore'

import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { useContractByCaseId } from '@/hooks/useContractByCaseId'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { useUserEmail } from '@/hooks/useUser'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import { updateImport } from '@/utils/import'
import { toast } from '@/utils/standaloneToast'

export const MiraieForm = (props: HTMLChakraProps<'form'>) => {
  const caseId = useV1ImportDocument(({ caseId }) => caseId)
  const teraThree = useContractByCaseId(caseId)
  const importId = useV1ImportParam()
  const { handleSubmit } = useMiraieFormContext()
  const { savedPanelImagesLayout } = usePanelImagesLayoutContext()
  const email = useUserEmail()
  const onSubmit = handleSubmit(async ({ draftCloseDates, ...formData }) => {
    await updateImport(importId, {
      formData: {
        ...formData,
        ...miraieFields.mngr_open_kbn.getValues(formData),
        torihiki_kbn: teraThree?.mediationCategory ?? undefined,
        portal_staff: formData.portal_staff ?? undefined, // 未設定の場合は送信しない
      },
      draftCloseDates,
      draftedAt: serverTimestamp(),
      panelImagesLayout: savedPanelImagesLayout,
      updatedBy: email,
    })

    toast({
      description: '媒体掲載が下書き保存されました。',
      status: 'info',
      position: 'top',
      isClosable: true,
    })
  })

  return <chakra.form onSubmit={onSubmit} {...props} />
}
