import { PostMiraieV1Import } from '../miraie/PostMiraieV1Import'
import { miraieFields } from '../miraie/fields'

import {
  MediaPublishRequestForTeraSeven,
  SakimonoMediaPublishRequestForTeraSeven,
} from '.'

/** tera-threeの媒体掲載・マイソク作成依頼のオプションをtera-sevenのそれに変換する（ネットレポートは非対応） */
export function baikaiToT7Options({
  SuumoOptions__c,
}: Pick<MediaPublishRequestForTeraSeven, 'SuumoOptions__c'>): Pick<
  PostMiraieV1Import,
  'search_keisai_flg' | 'staff_keisai_flg' | 'osusume_keisai_flg'
> {
  if (!SuumoOptions__c) return {}
  return {
    search_keisai_flg:
      SuumoOptions__c['検索一覧キャッチ（SUUMO）'] &&
      miraieFields.search_keisai_flg.Enum.指示,
    staff_keisai_flg:
      SuumoOptions__c['スタッフPR（SUUMO）'] &&
      miraieFields.staff_keisai_flg.Enum.指示,
    osusume_keisai_flg:
      SuumoOptions__c['おすぴく(おすすめピックアップ)（SUUMO）'] &&
      miraieFields.osusume_keisai_flg.Enum.指示,
  }
}

/** tera-threeの先物媒体掲載依頼のオプションをtera-sevenのそれに変換する（ネットレポートは非対応 */
export function sakimonoToT7Options({
  SuumoOptions__c,
}: Pick<SakimonoMediaPublishRequestForTeraSeven, 'SuumoOptions__c'>): Pick<
  PostMiraieV1Import,
  'search_keisai_flg' | 'staff_keisai_flg' | 'osusume_keisai_flg'
> {
  if (!SuumoOptions__c) return {}
  return {
    search_keisai_flg:
      SuumoOptions__c['検索一覧キャッチ（SUUMO）'] &&
      miraieFields.search_keisai_flg.Enum.指示,
    staff_keisai_flg:
      SuumoOptions__c['スタッフPR（SUUMO）'] &&
      miraieFields.staff_keisai_flg.Enum.指示,
    osusume_keisai_flg:
      SuumoOptions__c['おすぴく(おすすめピックアップ)（SUUMO）'] &&
      miraieFields.osusume_keisai_flg.Enum.指示,
  }
}
