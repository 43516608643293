import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  Textarea,
} from '@/components/form/controls'

export const NaisoKahiControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>内装</FormGroupHeading>
      <FormControl fieldset>
        <CheckboxGroup>
          {naisoCheckFields.map((fieldName) => (
            <BooleanEnumCheckbox key={fieldName} fieldName={fieldName}>
              {
                {
                  reform_renovation_kanou_naiso_all_flg: '内装全面',
                  reform_renovation_kanou_madori_flg: '間取り変更・スケルトン',
                  reform_renovation_zensitsu_flg: '全室クロス張替え',
                  reform_renovation_kanou_yuka_flg: '床',
                  reform_renovation_kanou_kabe_flg: '壁・天井',
                  reform_renovation_kanou_fixture_flg: '建具',
                  reform_renovation_kanou_sash_flg: 'サッシ',
                  reform_renovation_kanou_shuno_flg: '収納',
                }[fieldName]
              }
            </BooleanEnumCheckbox>
          ))}
        </CheckboxGroup>
        {naisoCheckFields.map((fieldName) => (
          <PortalErrorMessages key={fieldName} fieldName={fieldName} />
        ))}
      </FormControl>
      <FormGroup nested>
        <FormControl fieldName="reform_renovation_kanou_naiso_naiyo">
          <FormLabel>内容</FormLabel>
          <Textarea />
          <PortalErrorMessages />
          <FrontendValidationErrorMessages />
        </FormControl>
      </FormGroup>
    </FormGroup>
  )
}

const naisoCheckFields = [
  'reform_renovation_kanou_naiso_all_flg',
  'reform_renovation_kanou_madori_flg',
  'reform_renovation_zensitsu_flg',
  'reform_renovation_kanou_yuka_flg',
  'reform_renovation_kanou_kabe_flg',
  'reform_renovation_kanou_fixture_flg',
  'reform_renovation_kanou_sash_flg',
  'reform_renovation_kanou_shuno_flg',
] as const satisfies readonly (keyof PostMiraieV1ImportForm)[]
