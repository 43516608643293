import { PropertyTypeBaikai, PropertyTypeSakimono } from '../../../tera-three'
import { ValueOf } from '../../../types'

import { Enum } from './enum'

export const T3MapBaikai: Record<PropertyTypeBaikai, ValueOf<typeof Enum>> = {
  新築戸建: Enum.売戸建,
  戸建: Enum.売戸建,
  中古戸建: Enum.売戸建,
  新築マンション: Enum.売マンション,
  中古マンション: Enum.売マンション,
  'マンション・アパート': Enum.売マンション,
  土地: Enum.所有権譲渡,
}

export const T3MapSakimono: Record<
  PropertyTypeSakimono,
  ValueOf<typeof Enum>
> = {
  新築戸建: Enum.売戸建,
  戸建: Enum.売戸建,
  中古戸建: Enum.売戸建,
  新築マンション: Enum.売マンション,
  中古マンション: Enum.売マンション,
  'マンション・アパート': Enum.売マンション,
  土地: Enum.所有権譲渡,
}
