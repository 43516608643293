import { z } from 'zod'

import { BaikaiKeiyaku } from './BaikaiKeiyaku'
import { transformMultiSelectString } from './transformMultiSelectString'

export function parseMediaPublishRequestForTeraSevens(data: unknown[]) {
  return arraySchema.parse(data)
}

export type PlacementBaikai = z.infer<typeof placementEnum>
const placementEnum = z.enum([
  '指定なし',
  '第一種低層住居専用地域',
  '第二種低層住居専用地域',
  '第一種中高層住居専用地域',
  '第二種中高層住居専用地域',
  '第一種住居地域',
  '第二種住居地域',
  '準住居地域',
  '田園住居地域',
  '近隣商業地域',
  '商業地域',
  '準工業地域',
  '工業地域',
  '工業専用地域',
])

export type BuildingStructureBaikai = z.infer<typeof buildingStructureEnum>
const buildingStructureEnum = z.enum([
  '木造',
  '軽量鉄骨造',
  '重量鉄骨造',
  '鉄骨造',
  '鉄筋コンクリート造',
  '鉄骨鉄筋コンクリート造',
  '石造',
  'コンクリートブロック造',
  '土蔵造',
  'れんが造',
  '木骨石造',
  '木骨煉瓦造',
  '土地のため無し',
])

export type RightBaikai = z.infer<typeof rightEnum>
const rightEnum = z.enum(['所有権', '旧借地権', '普通借地権', '定期借地権'])

export type ManagementFormBaikai = z.infer<typeof managementFormEnum>
const managementFormEnum = z.enum([
  '常駐管理',
  '通勤（日勤）管理',
  '巡回管理',
  '自主管理',
])

export type PropertyTypeBaikai = z.infer<typeof propertyTypeSchema>
export const baikaiPropertyTypes = [
  '新築戸建',
  '中古戸建',
  '新築マンション',
  '中古マンション',
  '土地',
  'マンション・アパート',
  '戸建',
] as const
const propertyTypeSchema = z.enum(baikaiPropertyTypes)

export type PublicationMediumCategoryBaikai = z.infer<
  typeof publicationMediumCategoryEnum
>
const publicationMediumCategoryEnum = z.enum([
  'REINS',
  'SUUMO',
  '楽待',
  'athome',
  "HOME'S",
  'SUUMO（賃貸）',
  'athome（賃貸）',
  'REINS（賃貸・貸主から依頼のみ可）',
  "HOME'S（賃貸）",
])

export type SuumoOptionsBaikai = z.infer<typeof suumoOptionsEnum>
const suumoOptionsEnum = z.enum([
  '検索一覧キャッチ（SUUMO）',
  'スタッフPR（SUUMO）',
  'ネットレポート（SUUMO）',
  'おすぴく(おすすめピックアップ)（SUUMO）',
])

/** 媒体掲載・マイソク作成依頼の型 */
export type MediaPublishRequestForTeraSeven = z.infer<
  typeof mediaPublishRequestForTeraSevenSchema
>

/** 媒体掲載・マイソク作成依頼のスキーマ */
const mediaPublishRequestForTeraSevenSchema = z.object({
  Id: z.string(),
  /** 状況 */
  Status: z.enum([
    'エージェント対応待ち',
    'サポート対応待ち',
    'アドバイザー対応待ち',
    'クローズ（完了）',
  ]),
  /** 掲載媒体種別 */
  PublicationMediumCategory__c: z
    .string()
    .transform(transformMultiSelectString)
    .pipe(z.record(publicationMediumCategoryEnum, z.literal(true).optional())),
  /** SUUMOオプション */
  SuumoOptions__c: z
    .string()
    .transform(transformMultiSelectString)
    .pipe(z.record(suumoOptionsEnum, z.literal(true).optional()))
    .nullable(),
  /** athomeオプション */
  AthomeOptions__c: z
    .string()
    .transform((value) => value.split(';'))
    .pipe(z.array(z.enum(['おすすめコメント（athome）'])))
    .nullable(),
  /**
   * ポータルサイト掲載終了希望日
   * `YYYY-MM-dd` 形式の文字列
   */
  MediaCloseDate__c: z.string().date().nullable(),
  /** 掲載価格（税込・非課税） */
  ListingPrice__c: z.number().int(),
  /** 取引態様 */
  TransactionMode__c: z.enum(['代理', '媒介', '仲介']),
  /** メインキャッチコピー */
  CatchCopy__c: z.string().nullable(),
  /** サブキャッチコピー */
  SubCatchCopy__c: z.string().nullable(),
  /** 物件種別 */
  PropertyType__c: propertyTypeSchema,
  /** 物件現況 */
  PropertyCurrentStatus__c: z.enum(['居住中', '空室', '賃貸中', '更地']),
  /** 住居表示 */
  LotNumber__c: z.string(),
  /** 権利 */
  Rights__c: z
    .string()
    .transform((value) => value.split(';'))
    .pipe(z.array(rightEnum)),
  /** 用途地域 */
  Placement__c: z
    .string()
    .transform((value) => value.split(';'))
    .pipe(z.array(placementEnum)),
  /** 引渡時期 */
  ExtraditionPeriod__c: z.string(),
  /** 地目 */
  LandCategory__c: z.string().nullable(),
  /** 土地面積 */
  LandArea__c: z.number().nullable(),
  /** 専有面積（壁芯） */
  ExclusiveAreaWallCore__c: z.number().nullable(),
  /** バルコニー・テラス等面積 */
  BalconyPatioArea__c: z.number().nullable(),
  /** 築年月 */
  AgeOfBuilding__c: z.string().nullable(),
  /** 構造 */
  BuildingStructure__c: z
    .string()
    .transform((value) => value.split(';'))
    .pipe(z.array(buildingStructureEnum))
    .nullable(),
  /** 施工会社 */
  ConstructionCompany__c: z.string().nullable(),
  /** 月額管理費 */
  MonthlyAdministrativeExpenses__c: z.number().nullable(),
  /** 月額修繕積立金 */
  MonthlyRepairReserveAmount__c: z.number().nullable(),
  /** 月額その他費用 */
  MonthlyAndOtherExpenses__c: z.number().nullable(),
  /** その他費用の名目・金額内訳 */
  OtherExpensesBreakdown__c: z.string().nullable(),
  /** 管理形態 */
  ManagementForm__c: managementFormEnum.nullable(),
  Contract__r: BaikaiKeiyaku,
})

const arraySchema = z.array(mediaPublishRequestForTeraSevenSchema)
