import { PostMiraieV1Import } from '../miraie/PostMiraieV1Import'
import { miraieFields } from '../miraie/fields'
import { getYmd } from '../utils/date'

import { MediaPublishRequestForTeraSeven } from './MediaPublishRequestForTeraSeven'

export function baikaiShinseiToPostMiraie(
  shinsei: MediaPublishRequestForTeraSeven,
): Omit<PostMiraieV1Import, 'bk_state_kbn'> {
  const {
    Contract__r: {
      name,
      bk_sbt_kbn,
      bk_item_kbn,
      mediationCategory,
      price,
      mansionFloor,
    },
    ...rest
  } = shinsei

  const right = rest.Rights__c.at(0)
  // 用途地域、建物構造はtera-three側では何個でも選べるがみらいえでは2つまでしか指定できないので、はじめの2つを使う
  const [yoto1, yoto2] = rest.Placement__c
  const [kozo1, kozo2] = rest.BuildingStructure__c
    ? rest.BuildingStructure__c
    : [undefined, undefined]
  const chikuYmd = rest.AgeOfBuilding__c
    ? getYmd(new Date(rest.AgeOfBuilding__c))
    : undefined
  const hikiwatashiYmd = rest.ExtraditionPeriod__c
    ? getYmd(new Date(rest.ExtraditionPeriod__c))
    : undefined

  return {
    bk_sbt_kbn,
    bk_item_kbn,
    tatemono_name: name ?? undefined,
    shozai_kai: mansionFloor ?? undefined,
    torihiki_kbn: mediationCategory,
    price: miraieFields.price.convertToMiraiePrice(
      price,
      miraieFields.bk_sbt_kbn.isChintai(bk_sbt_kbn),
    ),
    genkyo_kbn:
      miraieFields.genkyo_kbn.T3MapBaikai[rest.PropertyCurrentStatus__c],
    tochi_pow_kbn: right && miraieFields.tochi_pow_kbn.T3MapBaikai[right],
    yoto_chiki_kbn_1: yoto1 && miraieFields.yoto_chiki_kbn.T3MapBaikai[yoto1],
    yoto_chiki_kbn_2: yoto2 && miraieFields.yoto_chiki_kbn.T3MapBaikai[yoto2],
    tate_kozo_kbn: kozo1 && miraieFields.tate_kozo_kbn.T3MapBaikai[kozo1],
    tate_ichibu_kozo_kbn:
      kozo2 && miraieFields.tate_ichibu_kozo_kbn.T3MapBaikai[kozo2],
    bk_catch: rest.CatchCopy__c ?? undefined,
    net_com: rest.SubCatchCopy__c ?? undefined,
    bal_menseki: rest.BalconyPatioArea__c ?? undefined,
    ...(chikuYmd
      ? {
          chiku_ymd_year: chikuYmd.year.toString(),
          chiku_ymd_month: chikuYmd.month.toString(),
        }
      : {}),
    ...(hikiwatashiYmd
      ? {
          hikiwatashi_ymd_year: hikiwatashiYmd.year.toString(),
          hikiwatashi_ymd_month: hikiwatashiYmd.month.toString(),
        }
      : {}),
    seko_company: rest.ConstructionCompany__c ?? undefined,
    kanrihi: rest.MonthlyAdministrativeExpenses__c ?? undefined,
    tsumitatekin: rest.MonthlyRepairReserveAmount__c ?? undefined,
    manage_hoshiki_kbn: rest.ManagementForm__c
      ? miraieFields.manage_hoshiki_kbn.T3MapBaikai[rest.ManagementForm__c]
      : undefined,
  }
}
