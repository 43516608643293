import { useConst } from '@chakra-ui/react'
import { setOtherErrors } from '@terass/common/src/miraie/PostMiraieV1ImportResponse'
import React from 'react'
import { FormProvider } from 'react-hook-form'

import { MiraieFieldPath, useMiraieForm } from '@/hooks/useMiraieForm'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const MiraieFormProvider = ({ children }: React.PropsWithChildren) => {
  const __response = useV1ImportDocument(({ __response }) => __response)
  const formData = useV1ImportDocument(({ formData }) => formData)
  const keisaiKikan = useV1ImportDocument(
    ({ teraSeven: { keisaiKikan } }) => keisaiKikan,
  )
  const draftCloseDates = useV1ImportDocument(
    ({ draftCloseDates }) => draftCloseDates,
  )
  const defaultValues = useConst({
    ...formData,
    draftCloseDates: {
      suumo: draftCloseDates?.suumo ?? keisaiKikan?.suumo?.to,
      athome: draftCloseDates?.athome ?? keisaiKikan?.athome?.to,
      homes: draftCloseDates?.homes ?? keisaiKikan?.homes?.to,
      rakumachi: draftCloseDates?.rakumachi ?? keisaiKikan?.rakumachi?.to,
    },
  })
  const methods = useMiraieForm({
    defaultValues,
  })
  const { clearErrors, setError } = methods

  React.useEffect(() => {
    clearErrors()
    const fieldErrors = setOtherErrors(__response)
    if (!Object.keys(fieldErrors).length) return

    Object.entries(fieldErrors).forEach(([fieldName, types]) => {
      // みらいえAPIの仕様で `MiraieFieldPath` 以外のフィールド名が入ってくることがある
      // 想定外のフィールド名のエラーも表示させるために無理やり `setError()` に渡す
      // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
      setError(fieldName as MiraieFieldPath, {
        types,
      })
    })
  }, [clearErrors, setError, __response])

  return <FormProvider {...methods}>{children}</FormProvider>
}
