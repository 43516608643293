import {
  Flex,
  CheckboxGroup,
  InputGroup,
  InputRightAddon,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import {
  FormControl,
  FormLabel,
  FormHelperText,
  PortalErrorMessages,
  TextInput,
  Textarea,
  NumberInput,
  Select,
  EnumOptions,
  PlaceholderOption,
  RadioGroup,
  UnselectedRadio,
  EnumRadios,
  BooleanEnumCheckbox,
} from '../controls'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import { ChinhoshokinCollapse } from '@/components/form/PriceAndCost/ChinhoshokinCollapse'
import { ChinryoKoshoContents } from '@/components/form/PriceAndCost/ChinryoKoshoContents'
import { HokenCollapse } from '@/components/form/PriceAndCost/HokenCollapse'
import { HoshokinCollapse } from '@/components/form/PriceAndCost/HoshokinCollapse'
import { KanrihiKyoekihiCollapse } from '@/components/form/PriceAndCost/KanrihiKyoekihiCollapse'
import { KenrikinCollapse } from '@/components/form/PriceAndCost/KenrikinCollapse'
import { ReikinCollapse } from '@/components/form/PriceAndCost/ReikinCollapse'
import { ShikibikiCollapse } from '@/components/form/PriceAndCost/ShikibikiCollapse'
import { ShikikinCollapse } from '@/components/form/PriceAndCost/ShikikinCollapse'
import { ShikimashiCollapse } from '@/components/form/PriceAndCost/ShikimashiCollapse'
import { ShokyakuJokenContents } from '@/components/form/PriceAndCost/ShokyakuJokenContents'
import { useIsChintai } from '@/hooks/useIsChintai'

export const Rent: React.FC = () => {
  const isChintai = useIsChintai()
  if (!isChintai) return null

  return (
    <FormGroup>
      <FormGroupHeading>賃料・初期費用</FormGroupHeading>

      <FormControl fieldName="price">
        <FormLabel>賃料</FormLabel>
        <InputGroup>
          <NumberInput />
          <InputRightAddon>円</InputRightAddon>
        </InputGroup>
        <PortalErrorMessages />
      </FormControl>

      <ChinryoKoshoContents>
        <FormControl fieldset>
          <FormLabel>クレジットカード決済可能項目</FormLabel>
          <CheckboxGroup>
            <BooleanEnumCheckbox fieldName="chinryo_down_flg">
              賃料値下げ可
            </BooleanEnumCheckbox>
            <BooleanEnumCheckbox fieldName="initprice_down_flg">
              初期費用値下げ可
            </BooleanEnumCheckbox>
          </CheckboxGroup>
          <PortalErrorMessages fieldName="chinryo_down_flg" />
          <PortalErrorMessages fieldName="initprice_down_flg" />
        </FormControl>
      </ChinryoKoshoContents>

      <FormControl fieldName="kyoekihi_kanrihi_flg" fieldset>
        <FormLabel>管理費共益費</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.kyoekihi_kanrihi_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <KanrihiKyoekihiCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <Flex gap={4}>
              <FormControl fieldName="kanrihi">
                <FormLabel>管理費</FormLabel>
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>円</InputRightAddon>
                </InputGroup>
              </FormControl>
              <FormControl fieldName="kyoekihi">
                <FormLabel>共益費</FormLabel>
                <InputGroup>
                  <NumberInput />
                  <InputRightAddon>円</InputRightAddon>
                </InputGroup>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="kanrihi" />
            <PortalErrorMessages fieldName="kyoekihi" />
          </FormControl>
        </FormGroup>
      </KanrihiKyoekihiCollapse>

      <FormControl fieldName="shikikin_flg" fieldset>
        <FormLabel>敷金</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.shikikin_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <ShikikinCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>敷金</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="shikikin">
                <NumberInput step={miraieFields.shikikin.step} />
              </FormControl>
              <FormControl fieldName="shikikin_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.shikikin_tani_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="shikikin" />
            <PortalErrorMessages fieldName="shikikin_tani_kbn" />
          </FormControl>
        </FormGroup>
      </ShikikinCollapse>

      <FormControl fieldName="shikibiki_flg" fieldset>
        <FormLabel>敷引</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.shikibiki_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <ShikibikiCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>敷引</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="shikibiki">
                <NumberInput step={miraieFields.shikibiki.step} />
              </FormControl>
              <FormControl fieldName="shikibiki_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.shikibiki_tani_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="shikibiki" />
            <PortalErrorMessages fieldName="shikibiki_tani_kbn" />
          </FormControl>
        </FormGroup>
      </ShikibikiCollapse>

      <FormControl fieldName="shikimashi_kbn" fieldset>
        <FormLabel>敷金積増</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.shikimashi_kbn.Enum} />
        </RadioGroup>
        <FormHelperText>
          積増金分をご入力ください。
          SUUMOへは、システム側で合算（敷金+積増金）して総額金額にて入稿します。
        </FormHelperText>
        <PortalErrorMessages />
      </FormControl>

      <ShikimashiCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>積増額</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="shikimashi">
                <NumberInput step={miraieFields.shikimashi.step} />
              </FormControl>
              <FormControl fieldName="shikimashi_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.shikimashi_tani_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="shikimashi" />
            <PortalErrorMessages fieldName="shikimashi_tani_kbn" />
          </FormControl>
        </FormGroup>
      </ShikimashiCollapse>

      <FormControl fieldName="reikin_flg" fieldset>
        <FormLabel>礼金</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.reikin_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <ReikinCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>礼金</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="reikin">
                <NumberInput step={miraieFields.reikin.step} />
              </FormControl>
              <FormControl fieldName="reikin_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.reikin_tani_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="reikin" />
            <PortalErrorMessages fieldName="reikin_tani_kbn" />
          </FormControl>
        </FormGroup>
      </ReikinCollapse>

      <FormControl fieldName="hoshokin_flg" fieldset>
        <FormLabel>保証金</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.hoshokin_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <HoshokinCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>保証金</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="hoshokin">
                <NumberInput step={miraieFields.hoshokin.step} />
              </FormControl>
              <FormControl fieldName="hoshokin_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.hoshokin_tani_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="hoshokin" />
            <PortalErrorMessages fieldName="hoshokin_tani_kbn" />
          </FormControl>
        </FormGroup>
      </HoshokinCollapse>

      <FormControl fieldset>
        <FormLabel>保証金償却</FormLabel>
        <Flex gap={4}>
          <FormControl fieldName="shokyaku_kbn">
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.shokyaku_kbn.Enum} />
            </Select>
          </FormControl>
          <FormControl fieldName="shokyaku">
            <NumberInput step={miraieFields.shokyaku.step} />
          </FormControl>
          <FormControl fieldName="shokyaku_tani_kbn">
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.shokyaku_tani_kbn.Enum} />
            </Select>
          </FormControl>
        </Flex>

        <PortalErrorMessages fieldName="shokyaku_kbn" />
        <PortalErrorMessages fieldName="shokyaku" />
        <PortalErrorMessages fieldName="shokyaku_tani_kbn" />
      </FormControl>

      <ShokyakuJokenContents>
        <FormGroup nested>
          <FormControl fieldName="hoshokin_shokyaku_joken_naiyo">
            <FormLabel>その他償却条件</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </ShokyakuJokenContents>

      <FormControl fieldName="kenrikin_flg" fieldset>
        <FormLabel>権利金</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.kenrikin_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <KenrikinCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>権利金</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="kenrikin">
                <NumberInput step={miraieFields.kenrikin.step} />
              </FormControl>
              <FormControl fieldName="kenrikin_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.kenrikin_tani_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="kenrikin" />
            <PortalErrorMessages fieldName="kenrikin_tani_kbn" />
          </FormControl>
        </FormGroup>
      </KenrikinCollapse>

      <FormControl fieldName="chinhoshokin_kbn" fieldset>
        <FormLabel>賃貸保証</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.chinhoshokin_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <ChinhoshokinCollapse>
        <FormGroup nested>
          <FormControl fieldName="hoshonin_daiko_company_flg">
            <FormLabel>保証人代行会社</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions
                enum={miraieFields.hoshonin_daiko_company_flg.Enum}
              />
            </Select>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldset>
            <FormLabel>賃貸保証料</FormLabel>
            <Flex gap={4}>
              <FormControl fieldName="chinhoshokin">
                <NumberInput step={miraieFields.chinhoshokin.step} />
              </FormControl>
              <FormControl fieldName="chinhoshokin_tani_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.chinhoshokin_tani_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>

            <PortalErrorMessages fieldName="chinhoshokin" />
            <PortalErrorMessages fieldName="chinhoshokin_tani_kbn" />
          </FormControl>

          <FormControl fieldName="chinhosho_naiyo">
            <FormLabel>保証人代行詳細</FormLabel>
            <Textarea />
            <PortalErrorMessages />
            <FrontendValidationErrorMessages />
          </FormControl>
        </FormGroup>
      </ChinhoshokinCollapse>

      <FormControl fieldName="hoken_flg" fieldset>
        <FormLabel>損害保険</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.hoken_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>

      <HokenCollapse>
        <FormGroup nested>
          <FormControl fieldName="hoken_keiyaku_kikan">
            <FormLabel>契約期間</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>年</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldName="hoken_kakekin">
            <FormLabel>損害保険料</FormLabel>
            <InputGroup>
              <NumberInput />
              <InputRightAddon>円</InputRightAddon>
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </HokenCollapse>
    </FormGroup>
  )
}
