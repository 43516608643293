import { RightBaikai, RightSakimono } from '../../tera-three'
import { ValueOf } from '../../types'

/**
 * 土地権利区分
 */
export const Enum = {
  所有権: '1',
  旧法借地権: '2',
  普通借地権: '3',
  定期借地権: '4',
} as const

export const T3MapBaikai: Record<RightBaikai, ValueOf<typeof Enum>> = {
  所有権: Enum.所有権,
  旧借地権: Enum.旧法借地権,
  普通借地権: Enum.普通借地権,
  定期借地権: Enum.定期借地権,
}

export const T3MapSakimono: Record<RightSakimono, ValueOf<typeof Enum>> = {
  所有権: Enum.所有権,
  旧借地権: Enum.旧法借地権,
  普通借地権: Enum.普通借地権,
  定期借地権: Enum.定期借地権,
}
