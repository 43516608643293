import { setUser } from '@sentry/react'
import { useSuspenseQuery } from '@tanstack/react-query'
import { parseSessionClaims, SessionClaims } from '@terass/common/src/auth'
import { identity } from '@terass/common/src/utils'
import { User, getAuth } from 'firebase/auth'

import { useObserver } from '@/hooks/useObserver'

export const useUser = <T>(selector: (user: User) => T): T =>
  useAuthState((auth) => {
    if (auth) return selector(auth)
    throw new NotLoggedInError()
  })

const useAuthState = <T>(selector: (auth: User | null) => T): T =>
  useObserver(
    {
      observerKey: ['firebase', 'authState'],
      observerFn: (onChange) =>
        getAuth().onAuthStateChanged((user) => {
          setUser(
            user ? { id: user.uid, email: user.email ?? undefined } : null,
          )
          onChange(user)
        }),
    },
    selector,
  )

export const useLoginState = () => useAuthState((user) => !!user)

export const useUserClaims = <T>(selector: (claims: SessionClaims) => T): T => {
  const user = useUser(identity)
  return useSuspenseQuery({
    queryKey: ['firebase-auth', user.toJSON()],
    queryFn: async () =>
      parseSessionClaims((await user.getIdTokenResult()).claims),
    select: selector,
  }).data
}

export const useUserRole = () => useUserClaims((claims) => claims.role)

export const useUserEmail = () =>
  useUser(({ email }) => {
    if (!email) throw new Error('empty email')
    return email
  })

export class NotLoggedInError extends Error {
  name = 'NotLoggedInError'
}
