import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Button,
  ButtonProps,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Link,
  Box,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'
import { setOtherErrors } from '@terass/common/src/miraie/PostMiraieV1ImportResponse'
import { exhaustiveCheck } from '@terass/common/src/utils'

import { useValidateMutation } from './useValidateMutation'

import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { MutationStatusIcon } from '@/components/form/MiraieFormApplyButton/MutationStatusIcon'
import { getT3PublishLink } from '@/constants'
import { useContractByCaseId } from '@/hooks/useContractByCaseId'
import {
  useMiraieFormContext,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'
import { useOperator } from '@/hooks/useOperator'
import { useUserRole } from '@/hooks/useUser'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import { miraieValidate } from '@/utils/functions'
import { updateImport } from '@/utils/import'

export const MiraieFormPublishButton = (props: ButtonProps) => {
  const role = useUserRole()
  const { name } = useOperator()
  const baikaikeiyaku = useContractByCaseId(
    useV1ImportDocument(({ caseId }) => caseId),
  )
  const importId = useV1ImportParam()
  const { getValues } = useMiraieFormContext()
  const hasMiraieError =
    Object.keys(
      setOtherErrors(useV1ImportDocument(({ __response }) => __response)),
    ).length !== 0
  const [sakimono_flg] = useWatchMiraieFields({ name: ['sakimono_flg'] })

  const validateMutation = useValidateMutation(() =>
    updateImportMutation.mutate(),
  )
  const { savedPanelImagesLayout } = usePanelImagesLayoutContext()
  const updateImportMutation = useMutation({
    mutationFn: async () => {
      const { draftCloseDates, ...formData } = getValues()

      await updateImport(importId, {
        formData: {
          ...formData,
          ...miraieFields.mngr_open_kbn.getValues(formData),
          torihiki_kbn: baikaikeiyaku?.mediationCategory ?? undefined,
        },
        draftCloseDates,
        panelImagesLayout: savedPanelImagesLayout,
      })
    },
    onSuccess: () => miraieValidateMutation.mutate(),
  })

  const miraieValidateMutation = useMutation({
    mutationFn: () => miraieValidate({ importId }),
  })

  const mutations = {
    validateMutation,
    updateImportMutation,
    miraieValidateMutation,
  } as const
  const mutationNames = [
    'validateMutation',
    'updateImportMutation',
    'miraieValidateMutation',
  ] as const satisfies (keyof typeof mutations)[]
  const isPending = mutationNames.some(
    (name) => mutations[name].status === 'pending',
  )
  const isProgressOpen = mutationNames.some(
    (name) => mutations[name].status !== 'idle',
  )
  const isSuccess = mutationNames.every(
    (name) => mutations[name].status === 'success',
  )
  const resetMutations = () =>
    mutationNames.forEach((name) => mutations[name].reset())

  return (
    <>
      <Button
        isLoading={isPending}
        onClick={() => validateMutation.mutate()}
        rightIcon={<ExternalLinkIcon />}
        {...props}
      />
      <Modal
        isOpen={isProgressOpen}
        onClose={resetMutations}
        closeOnEsc={!isPending}
        closeOnOverlayClick={!isPending}
        size="lg"
      >
        <ModalOverlay />
        <ModalContent>
          {isPending || <ModalCloseButton />}
          <ModalHeader>
            {isPending
              ? 'エラーチェックを行っています...'
              : isSuccess
                ? 'エラーチェックが完了しました'
                : 'エラーがあります'}
          </ModalHeader>
          <ModalBody>
            <Text mb={4}>
              エラーチェックが完了後、Terass Cloudから申請が行えます。
            </Text>
            <Accordion
              index={mutationNames.findIndex(
                (name) => mutations[name].status === 'error',
              )}
            >
              {mutationNames.map((name, index) => {
                const step = index + 1
                const mutation = mutations[name]
                return (
                  <AccordionItem key={name}>
                    <AccordionButton
                      display="grid"
                      gridTemplateColumns="auto 1fr auto"
                      gap={2}
                      alignItems="center"
                      opacity={mutation.status === 'idle' ? 0.5 : 1}
                    >
                      <MutationStatusIcon status={mutation.status} />
                      <Text align="left">
                        {
                          {
                            validateMutation: '入力内容の検証',
                            updateImportMutation: '入力内容の保存',
                            miraieValidateMutation:
                              '入力内容の検証（ポータルサイト側）',
                          }[name]
                        }
                      </Text>
                      <Text>
                        {step}/{mutationNames.length}
                      </Text>
                    </AccordionButton>
                    <AccordionPanel>
                      {mutation.status === 'error' && (
                        <Alert status="error">
                          <AlertIcon />
                          {mutation.error.message}
                        </Alert>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
            {miraieValidateMutation.status === 'success' &&
              (hasMiraieError ? (
                <Flex direction="column" gap={2} mt={4}>
                  <Alert status="error">
                    <AlertIcon />
                    エラーがあります。
                  </Alert>
                  <Text>申請を行う前に修正する必要があります。</Text>
                </Flex>
              ) : (
                <Flex direction="column" gap={2} mt={4}>
                  <Alert status="success">
                    <AlertIcon />
                    エラーはありませんでした。
                  </Alert>
                  <Text>
                    TERASS Cloudから
                    {role === 'assistant' && (
                      <>
                        <Box as="span" fontWeight="bold">
                          {` ${name} `}
                        </Box>
                        として代理ログインし、
                      </>
                    )}
                    申請を行ってください。
                  </Text>
                </Flex>
              ))}
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              isLoading={isPending}
              onClick={resetMutations}
              mr={4}
            >
              閉じる
            </Button>
            <Link
              isExternal
              href={getT3PublishLink({
                isSakimono:
                  sakimono_flg === miraieFields.sakimono_flg.Enum.先物,
                baikaiKeiyakuId: baikaikeiyaku?.id,
              })}
            >
              <Button rightIcon={<ExternalLinkIcon />} isLoading={isPending}>
                申請画面へ
              </Button>
            </Link>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export const JumpToT3Button = (props: ButtonProps) => {
  const status = useV1ImportDocument(({ status }) => status)
  switch (status) {
    case 'draft':
      return (
        <MiraieFormPublishButton {...props}>
          Terass Cloudで媒体公開申請を行う
        </MiraieFormPublishButton>
      )
    case 'published':
      return (
        <MiraieFormPublishButton {...props}>
          Terass Cloudで媒体変更・停止申請を行う
        </MiraieFormPublishButton>
      )
    case 'closed':
      return null
    default:
      return exhaustiveCheck(status)
  }
}
