import {
  Portal,
  portals,
  T3PortalMapBaikai,
  T3PortalMapSakimono,
} from '../../../portal'
import {
  MediaPublishRequestForTeraSeven,
  SakimonoMediaPublishRequestForTeraSeven,
} from '../../../tera-three'

import { Enum } from './enum'

/** tera-threeの媒体掲載・マイソク作成依頼の掲載媒体種別をtera-seven上の公開区分に変換する */
export function baikaiToT7OpenKbns(
  t3MediumCategory: MediaPublishRequestForTeraSeven['PublicationMediumCategory__c'],
): {
  [P in Portal as `${P}_c_open_kbn`]?: Enum
} {
  const cOpenKubuns: ReturnType<typeof baikaiToT7OpenKbns> = {}

  portals.forEach((portal) => {
    if (
      // 楽待は非対応のため除外
      portal !== 'rakumachi' &&
      t3MediumCategory[T3PortalMapBaikai[portal]]
    )
      cOpenKubuns[`${portal}_c_open_kbn`] = Enum.掲載
  })

  return cOpenKubuns
}

/** tera-threeの先物媒体掲載依頼の掲載媒体種別をtera-seven上の公開区分に変換する */
export function sakimonoToT7OpenKbns(
  t3MediumCategory: SakimonoMediaPublishRequestForTeraSeven['PublicationMediumCategory__c'],
): {
  [P in Portal as `${P}_c_open_kbn`]?: Enum
} {
  const cOpenKubuns: ReturnType<typeof sakimonoToT7OpenKbns> = {}

  portals.forEach((portal) => {
    if (
      // 楽待は非対応のため除外
      portal !== 'rakumachi' &&
      t3MediumCategory[T3PortalMapSakimono[portal]]
    )
      cOpenKubuns[`${portal}_c_open_kbn`] = Enum.掲載
  })

  return cOpenKubuns
}
