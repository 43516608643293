import { LatLng } from './types'

/**
 * 2地点間の（直線距離ではなく）移動距離(m)を取得する
 */
export const getRouteDistance = async (
  from: LatLng,
  to: LatLng,
  travelMode: google.maps.TravelMode,
) => {
  const directionsService = new google.maps.DirectionsService()

  const result = await directionsService.route({
    origin: new google.maps.LatLng(from.lat, from.lng),
    destination: new google.maps.LatLng(to.lat, to.lng),
    travelMode,
  })

  return result.routes[0]?.legs[0]?.distance?.value
}
