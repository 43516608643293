import { HStack } from '@chakra-ui/react'
import { createFileRoute } from '@tanstack/react-router'

import { PageHeading } from '@/components/Atoms'
import { FormContainer } from '@/components/FormContainer'
import { BaitaiForm } from '@/components/form/BaitaiForm'
import { JumpToT3Button } from '@/components/form/MiraieFormApplyButton/MiraieFormPublishButton'
import { MiraieFormSubmitButton } from '@/components/form/MiraieFormSubmitButton'
import { MiraieFormValidateButton } from '@/components/form/MiraieFormValidateButton'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const Route = createFileRoute('/bukkens/$importId/edit/')({
  component: EditForm,
})

export function EditForm() {
  const status = useV1ImportDocument(({ status }) => status)
  const tatemono_name = useV1ImportDocument(
    ({ formData: { tatemono_name } }) => tatemono_name,
  )
  return (
    <FormContainer>
      <PageHeading mt={2}>
        {status === 'draft' ? '媒体掲載下書き' : '媒体掲載編集'}/{tatemono_name}
      </PageHeading>
      <BaitaiForm>
        <HStack mt={4} position="sticky" bottom={0}>
          <MiraieFormSubmitButton>下書き保存</MiraieFormSubmitButton>
          <MiraieFormValidateButton>
            {status === 'published'
              ? '入力内容の保存・確認(エラーチェック)'
              : '入力内容の確認（エラーチェック）'}
          </MiraieFormValidateButton>
          <JumpToT3Button />
        </HStack>
      </BaitaiForm>
    </FormContainer>
  )
}
