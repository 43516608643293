import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import {
  FieldLength,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  Textarea,
} from '@/components/form/controls'

export const Bikou = () => {
  return (
    <FormGroup>
      <FormGroupHeading>備考</FormGroupHeading>

      <FormControl fieldName="chohyo_biko">
        <FormLabel>帳票用</FormLabel>
        <Textarea />
        <FieldLength maxLength={miraieFields.chohyo_biko.maxLength} />
        <PortalErrorMessages />
        <FrontendValidationErrorMessages />
      </FormControl>
    </FormGroup>
  )
}
