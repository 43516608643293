import { QuestionIcon } from '@chakra-ui/icons'
import { Box, Flex, Progress } from '@chakra-ui/react'
import { createRootRoute } from '@tanstack/react-router'

import { ErrorFallback } from '@/components/ErrorFallback'
import { Header } from '@/components/Header'
import { LoginPage } from '@/components/LoginPage'
import { Main } from '@/components/Main'
import { PageView } from '@/components/PageView'
import { LogoutButton } from '@/components/auth/LogoutButton'
import { MasqueradeAlert } from '@/components/auth/MasqueradeAlert'
import { HelpButton } from '@/components/help/HelpModal'
import { useLoginState } from '@/hooks/useUser'
import { AuthenticatedLayout, authLoader } from '@/routes/-authenticatedLayout'

export const Route = createRootRoute({
  component: Root,
  loader: authLoader,
  errorComponent: ErrorFallback,
  pendingComponent: () => <Progress isIndeterminate size="sm" />,
})

export function Root() {
  const authState = useLoginState()

  return (
    <>
      <PageView />
      <Header>
        <Flex gap={4}>
          <HelpButton leftIcon={<QuestionIcon />}>ヘルプ</HelpButton>
          {authState && <LogoutButton />}
        </Flex>
      </Header>

      {authState && (
        <Box position="sticky" top="0" zIndex="sticky">
          <MasqueradeAlert />
        </Box>
      )}

      <Main>{authState ? <AuthenticatedLayout /> : <LoginPage />}</Main>
    </>
  )
}
