import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Button,
  Grid,
  GridItem,
  Text,
  Link,
} from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'

import { CenteringGridItem } from './CenteringGridItem'

import { getT3PublishLink } from '@/constants'
import { RoutePaths } from '@/route'
import { gridTemplate } from '@/utils/gridTemplate'

export const EditAccordionItem = () => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          掲載内容の更新
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Grid
          gridTemplate={style}
          justifyItems="center"
          alignItems="center"
          height={300}
          width={500}
        >
          <CenteringGridItem
            gridArea={getArea('T7Header')}
            width="100%"
            borderColor="teraSeven.500"
          >
            <Text>Terass Converter</Text>
          </CenteringGridItem>
          <CenteringGridItem
            gridArea={getArea('T3Header')}
            width="100%"
            borderColor="teraThree.500"
          >
            <Text>Terass Cloud</Text>
          </CenteringGridItem>
          <Button
            height="90%"
            gridArea={getArea('T7Top')}
            as={Link}
            href={'/' satisfies RoutePaths}
            isExternal
          >
            媒体掲載一覧
          </Button>
          <GridItem gridArea={getArea('arrow1')}>
            <FaArrowRight />
          </GridItem>
          <GridItem gridArea={getArea('arrow2')}>
            <FaArrowRight />
          </GridItem>
          <CenteringGridItem
            gridArea={getArea('T7Edit')}
            height="90%"
            width="100%"
            borderColor="teraSeven.500"
          >
            <Text>媒体掲載編集</Text>
          </CenteringGridItem>
          <Button
            colorScheme="teraThree"
            gridArea={getArea('T3EditShinsei')}
            height="90%"
            as={Link}
            href={getT3PublishLink({
              isSakimono: false,
            })}
            isExternal
          >
            媒体掲載更新申請
          </Button>
        </Grid>
      </AccordionPanel>
    </AccordionItem>
  )
}

const { style, getArea } = gridTemplate`
  "${'T7Header'} ${'T7Header'} ${'T7Header'}     ${'...'}      ${'T3Header'}"      auto
  "${'T7Top'}    ${'arrow1'}   ${'T7Edit'}      ${'arrow2'}    ${'T3EditShinsei'}" 1fr
  / auto           40px        1fr               40px           auto             
`
