import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { WatchMiraieFields } from '@/components/form/WatchMiraieFields'
import {
  EnumOptions,
  EnumRadios,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  TextInput,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const Room = () => {
  return (
    <FormGroup>
      <FormGroupHeading>部屋関連</FormGroupHeading>
      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent'
        }
      >
        <FormControl fieldName="tatesen_menseki">
          <FormLabel>専有面積</FormLabel>
          <InputGroup>
            <NumberInput step={miraieFields.tatesen_menseki.step} />
            <InputRightAddon>㎡</InputRightAddon>
          </InputGroup>
          <PortalErrorMessages />
        </FormControl>
      </MiraieFieldContents>

      <WatchMiraieFields name={['bk_sbt_kbn']}>
        {(bk_sbt_kbn) => (
          <FormControl
            fieldset
            isRequired={
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション ||
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
            }
          >
            <FormLabel>間取</FormLabel>
            <Flex gap={2} alignItems="center">
              <FormControl fieldName="madori_room_su">
                <NumberInput />
              </FormControl>
              <FormControl fieldName="madori_kbn">
                <Select>
                  <PlaceholderOption />
                  <EnumOptions enum={miraieFields.madori_kbn.Enum} />
                </Select>
              </FormControl>
            </Flex>
            <FormHelperText>
              戸建、マンションの場合に必須。
              「未定」はSUUMOのみ対応のため、他ポータルへも出稿するとエラーとなります。
            </FormHelperText>
            <PortalErrorMessages fieldName="madori_room_su" />
            <PortalErrorMessages fieldName="madori_kbn" />
          </FormControl>
        )}
      </WatchMiraieFields>

      <MiraieFieldContents
        name={['bk_sbt_kbn', 'suumo_c_open_kbn']}
        display={(bk_sbt_kbn, suumo_c_open_kbn) =>
          (bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション) &&
          miraieFields.c_open_kbn.isOpen(suumo_c_open_kbn)
        }
      >
        <FormControl fieldset>
          <FormLabel>SUUMO用間取</FormLabel>
          <Flex gap={2} alignItems="center">
            <FormControl fieldName="madori_suumo_room_su">
              <NumberInput />
            </FormControl>

            <FormControl fieldName="madori_suumo_kbn">
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.madori_suumo_kbn.Enum} />
              </Select>
            </FormControl>

            <FormControl fieldName="madori_types_kbn">
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.madori_types_kbn.Enum} />
              </Select>
            </FormControl>
          </Flex>
          <FormHelperText>
            {`LLDDKKや、2Sなどのより細かい間取情報を登録できます。
SUUMOホームページのみに対応している項目です。
対応ポータルでは、こちらの入力が利用されます。
未入力の場合、上で入力した間取情報が利用されます。`}
          </FormHelperText>
          <PortalErrorMessages fieldName="madori_suumo_room_su" />
          <PortalErrorMessages fieldName="madori_suumo_kbn" />
          <PortalErrorMessages fieldName="madori_types_kbn" />
        </FormControl>
      </MiraieFieldContents>

      <FormControl fieldName="shuyo_saikomen_kbn">
        <FormLabel>主要採光</FormLabel>
        <Select>
          <PlaceholderOption />
          <EnumOptions enum={miraieFields.shuyo_saikomen_kbn.Enum} />
        </Select>
        <PortalErrorMessages />
      </FormControl>

      <WatchMiraieFields name={['bk_sbt_kbn']}>
        {(bk_sbt_kbn) => (
          <FormControl
            fieldName="bal_flg"
            isRequired={
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
            }
          >
            <FormLabel>バルコニー</FormLabel>
            <Select>
              <PlaceholderOption />
              <EnumOptions enum={miraieFields.bal_flg.Enum} />
            </Select>
            <PortalErrorMessages />
          </FormControl>
        )}
      </WatchMiraieFields>

      <MiraieFieldCollapse
        name={['bal_flg']}
        display={(bal_flg) => bal_flg === miraieFields.bal_flg.Enum.有}
      >
        <FormGroup nested>
          <MiraieFieldContents
            name={['bk_sbt_kbn']}
            display={(bk_sbt_kbn) =>
              bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション ||
              miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent'
            }
          >
            <FormControl fieldName="bal_hoko_kbn">
              <FormLabel>方向</FormLabel>
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.bal_hoko_kbn.Enum} />
              </Select>
              <PortalErrorMessages />
            </FormControl>
          </MiraieFieldContents>

          <WatchMiraieFields name={['bal_flg']}>
            {(bal_flg) => (
              <FormControl
                fieldName="bal_menseki"
                isRequired={bal_flg === miraieFields.bal_flg.Enum.有}
              >
                <InputGroup>
                  <InputLeftAddon>面積</InputLeftAddon>
                  <NumberInput step={miraieFields.bal_menseki.step} />
                  <InputRightAddon>㎡</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            )}
          </WatchMiraieFields>
        </FormGroup>
      </MiraieFieldCollapse>

      <MiraieFieldContents
        name={['bk_sbt_kbn', 'athome_c_open_kbn']}
        display={(bk_sbt_kbn, athome_c_open_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
            ? miraieFields.c_open_kbn.isOpen(athome_c_open_kbn)
            : bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
        }
      >
        <FormControl fieldset>
          <FormLabel>ルーフバルコニー</FormLabel>
          <Flex gap={2} alignItems="center">
            <FormControl fieldName="roofbal_menseki">
              <InputGroup>
                <InputLeftAddon>面積</InputLeftAddon>
                <NumberInput step={miraieFields.roofbal_menseki.step} />
                <InputRightAddon>㎡</InputRightAddon>
              </InputGroup>
            </FormControl>

            <MiraieFieldCollapse
              name={['bk_sbt_kbn', 'roofbal_menseki']}
              display={(bk_sbt_kbn, roofbal_menseki) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション &&
                roofbal_menseki != null &&
                roofbal_menseki > 0
              }
            >
              <FormControl fieldName="roofbal_price">
                <InputGroup>
                  <InputLeftAddon>使用料</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>円/月</InputRightAddon>
                </InputGroup>
              </FormControl>
            </MiraieFieldCollapse>
          </Flex>

          <PortalErrorMessages fieldName="roofbal_menseki" />
          <PortalErrorMessages fieldName="roofbal_price" />
        </FormControl>

        <FormControl fieldset>
          <FormLabel>テラス</FormLabel>
          <Flex gap={2} alignItems="center">
            <FormControl fieldName="tera_menseki">
              <InputGroup>
                <InputLeftAddon>面積</InputLeftAddon>
                <NumberInput step={miraieFields.tera_menseki.step} />
                <InputRightAddon>㎡</InputRightAddon>
              </InputGroup>
            </FormControl>

            <MiraieFieldCollapse
              name={['bk_sbt_kbn', 'tera_menseki']}
              display={(bk_sbt_kbn, tera_menseki) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション &&
                tera_menseki != null &&
                tera_menseki > 0
              }
            >
              <FormControl fieldName="tera_price">
                <InputGroup>
                  <InputLeftAddon>使用料</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>円/月</InputRightAddon>
                </InputGroup>
              </FormControl>
            </MiraieFieldCollapse>
          </Flex>

          <PortalErrorMessages fieldName="tera_menseki" />
          <PortalErrorMessages fieldName="tera_price" />
        </FormControl>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション
        }
      >
        <FormControl fieldset>
          <FormLabel>専用庭</FormLabel>
          <Flex gap={2} alignItems="center">
            <FormControl fieldName="niwa_menseki">
              <InputGroup>
                <InputLeftAddon>面積</InputLeftAddon>
                <NumberInput step={miraieFields.niwa_menseki.step} />
                <InputRightAddon>㎡</InputRightAddon>
              </InputGroup>
            </FormControl>

            <MiraieFieldCollapse
              name={['bk_sbt_kbn', 'niwa_menseki']}
              display={(bk_sbt_kbn, niwa_menseki) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション &&
                niwa_menseki != null &&
                niwa_menseki > 0
              }
            >
              <FormControl fieldName="niwa_price">
                <InputGroup>
                  <InputLeftAddon>使用料</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>円/月</InputRightAddon>
                </InputGroup>
              </FormControl>
            </MiraieFieldCollapse>
          </Flex>

          <PortalErrorMessages fieldName="niwa_menseki" />
          <PortalErrorMessages fieldName="niwa_price" />
        </FormControl>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売マンション ||
          miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent'
        }
      >
        <FormControl fieldset>
          <FormLabel>メゾネット階</FormLabel>
          <Flex gap={2} alignItems="center">
            <FormControl fieldName="maisonette_kai_from_kbn">
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.maisonette_kai_from_kbn.Enum} />
              </Select>
            </FormControl>

            <FormControl fieldName="maisonette_kai_from">
              <NumberInput />
            </FormControl>

            <Text>～</Text>

            <FormControl fieldName="maisonette_kai_to_kbn">
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.maisonette_kai_to_kbn.Enum} />
              </Select>
            </FormControl>

            <FormControl fieldName="maisonette_kai_to">
              <NumberInput />
            </FormControl>
          </Flex>
          <PortalErrorMessages fieldName="maisonette_kai_from_kbn" />
          <PortalErrorMessages fieldName="maisonette_kai_from" />
          <PortalErrorMessages fieldName="maisonette_kai_to_kbn" />
          <PortalErrorMessages fieldName="maisonette_kai_to" />
        </FormControl>
      </MiraieFieldContents>

      <MiraieFieldContents
        name={['bk_sbt_kbn']}
        display={(bk_sbt_kbn) =>
          miraieFields.bk_sbt_kbn.getCategory(bk_sbt_kbn) === 'rent'
        }
      >
        <FormGroup>
          <FormControl fieldName="key_hokan_kbn" fieldset>
            <FormLabel>鍵</FormLabel>
            <RadioGroup>
              <UnselectedRadio />
              <EnumRadios enum={miraieFields.key_hokan_kbn.Enum} />
            </RadioGroup>
            <PortalErrorMessages />
          </FormControl>
          <MiraieFieldCollapse name={['key_hokan_kbn']} display={Boolean}>
            <FormGroup nested>
              <MiraieFieldCollapse
                name={['key_hokan_kbn']}
                display={(key_hokan_kbn) =>
                  key_hokan_kbn === miraieFields.key_hokan_kbn.Enum.管理会社
                }
              >
                <FormControl fieldName="key_hokan_yotakusaki">
                  <FormLabel>預託先</FormLabel>
                  <TextInput />
                  <PortalErrorMessages />
                </FormControl>
              </MiraieFieldCollapse>

              <FormControl fieldName="key_biko">
                <FormLabel>備考</FormLabel>
                <Textarea />
                <PortalErrorMessages />
                <FrontendValidationErrorMessages />
              </FormControl>
            </FormGroup>
          </MiraieFieldCollapse>
        </FormGroup>
      </MiraieFieldContents>
    </FormGroup>
  )
}
