import {
  Flex,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import { HaniEnumCheckbox } from '@/components/form/PriceAndCost/HaniEnumCheckbox'
import {
  EnumOptions,
  FieldLength,
  FormControl,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  Select,
  TextInput,
  Textarea,
} from '@/components/form/controls'
import { useWatchMiraieFields } from '@/hooks/useMiraieForm'

export const Menseki = () => {
  const [bk_sbt_kbn] = useWatchMiraieFields({ name: ['bk_sbt_kbn'] })

  return (
    <MiraieFieldContents
      name={['bk_sbt_kbn']}
      display={(bk_sbt_kbn) =>
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
        bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
      }
    >
      <FormGroup>
        <FormGroupHeading>面積</FormGroupHeading>

        <FormControl
          fieldset
          isRequired={
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
          }
        >
          <FormLabel>土地面積</FormLabel>
          <FormGroup nested>
            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
              }
            >
              <FormControl fieldName="tochi_menseki_hani_kbn">
                <HaniEnumCheckbox>面積を範囲で指定する</HaniEnumCheckbox>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldContents>

            <FormControl fieldset>
              <Flex gap={2} alignItems="center">
                <FormControl fieldName="tochi_menseki">
                  <InputGroup>
                    <NumberInput step={miraieFields.tochi_menseki.step} />
                    <InputRightAddon>㎡</InputRightAddon>
                  </InputGroup>
                </FormControl>
                <MiraieFieldContents
                  name={['tochi_menseki_hani_kbn']}
                  display={(tochi_menseki_hani_kbn) =>
                    tochi_menseki_hani_kbn ===
                    miraieFields.tochi_menseki_hani_kbn.Enum['〜']
                  }
                >
                  <Text>~</Text>

                  <FormControl fieldName="tochi_menseki_to">
                    <InputGroup>
                      <NumberInput step={miraieFields.tochi_menseki.step} />
                      <InputRightAddon>㎡</InputRightAddon>
                    </InputGroup>
                  </FormControl>
                </MiraieFieldContents>
              </Flex>
              <PortalErrorMessages fieldName="tochi_menseki" />
              <PortalErrorMessages fieldName="tochi_menseki_to" />
            </FormControl>

            <FormControl fieldName="tochi_menseki_kbn">
              <FormLabel>土地面積区分</FormLabel>
              <Select>
                <PlaceholderOption />
                <EnumOptions enum={miraieFields.tochi_menseki_kbn.Enum} />
              </Select>
              <PortalErrorMessages />
            </FormControl>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
              }
            >
              <FormControl fieldset>
                <Flex gap={2} alignItems="center">
                  <FormControl fieldName="keisha_menseki">
                    <InputGroup>
                      <InputLeftAddon>傾斜地部分面積</InputLeftAddon>
                      <NumberInput step={miraieFields.keisha_menseki.step} />
                    </InputGroup>
                  </FormControl>
                  <FormControl fieldName="keisha_menseki_tani_kbn">
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions
                        enum={miraieFields.keisha_menseki_tani_kbn.Enum}
                      />
                    </Select>
                  </FormControl>
                </Flex>
                <PortalErrorMessages fieldName="keisha_menseki" />
                <PortalErrorMessages fieldName="keisha_menseki_tani_kbn" />
              </FormControl>
            </MiraieFieldContents>

            <MiraieFieldContents
              name={['bk_sbt_kbn']}
              display={(bk_sbt_kbn) =>
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
                bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
              }
            >
              <FormControl fieldset>
                <Flex gap={2} alignItems="center">
                  <FormControl fieldName="rojijo_menseki">
                    <InputGroup>
                      <InputLeftAddon>うち路地状部分面積</InputLeftAddon>
                      <NumberInput step={miraieFields.rojijo_menseki.step} />
                    </InputGroup>
                  </FormControl>
                  <FormControl fieldName="rojijo_menseki_tani_kbn">
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions
                        enum={miraieFields.rojijo_menseki_tani_kbn.Enum}
                      />
                    </Select>
                  </FormControl>
                </Flex>
                <PortalErrorMessages fieldName="rojijo_menseki" />
                <PortalErrorMessages fieldName="rojijo_menseki_tani_kbn" />
              </FormControl>

              <FormControl fieldName="koatsusensita_menseki" mt={4}>
                <InputGroup>
                  <InputLeftAddon>うち高圧線下部分面積</InputLeftAddon>
                  <NumberInput step={miraieFields.koatsusensita_menseki.step} />
                  <InputRightAddon>㎡</InputRightAddon>
                </InputGroup>
                <PortalErrorMessages />
              </FormControl>
            </MiraieFieldContents>
          </FormGroup>
        </FormControl>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldName="kenpeiritsu_yosekiritsu">
            <FormLabel>建ぺい率容積率</FormLabel>
            <Textarea />
            <FieldLength
              maxLength={miraieFields.kenpeiritsu_yosekiritsu.maxLength}
            />
            <PortalErrorMessages />
            <FrontendValidationErrorMessages />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地 ||
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売戸建住宅
          }
        >
          <FormControl fieldset>
            <FormLabel>土地持分</FormLabel>
            <Flex gap={2} alignItems="center">
              <FormControl fieldName="tochi_bunshi">
                <NumberInput step={miraieFields.tochi_bunshi.step} />
              </FormControl>
              <Text>/</Text>
              <FormControl fieldName="tochi_bunbo">
                <NumberInput step={miraieFields.tochi_bunbo.step} />
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName="tochi_bunshi" />
            <PortalErrorMessages fieldName="tochi_bunbo" />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn !== miraieFields.bk_sbt_kbn.Enum['戸建て(2戸以上)']
          }
        >
          <FormControl fieldset>
            <FormLabel>土地 建ぺい率 容積率</FormLabel>
            <Flex gap={2} alignItems="center">
              <FormControl fieldName="kenpei_ritu">
                <InputGroup>
                  <InputLeftAddon>建ぺい率</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>％</InputRightAddon>
                </InputGroup>
              </FormControl>
              <FormControl fieldName="yoseki_ritu">
                <InputGroup>
                  <InputLeftAddon>容積率</InputLeftAddon>
                  <NumberInput />
                  <InputRightAddon>％</InputRightAddon>
                </InputGroup>
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName="kenpei_ritu" />
            <PortalErrorMessages fieldName="yoseki_ritu" />
          </FormControl>
        </MiraieFieldContents>

        <MiraieFieldContents
          name={['bk_sbt_kbn']}
          display={(bk_sbt_kbn) =>
            bk_sbt_kbn === miraieFields.bk_sbt_kbn.Enum.売地
          }
        >
          <FormControl fieldName="yoseki_ritu_biko">
            <InputGroup>
              <InputLeftAddon>容積率制限備考</InputLeftAddon>
              <TextInput />
            </InputGroup>
            <PortalErrorMessages />
          </FormControl>
        </MiraieFieldContents>
      </FormGroup>
    </MiraieFieldContents>
  )
}
