import { Flex } from '@chakra-ui/react'
import { PropsWithChildren, Suspense } from 'react'

import { CSpinner } from '@/components/Atoms'
import { BackNavButton } from '@/components/form/BackNavButton'
import { BukkenImagesSection } from '@/components/form/BukkenImages'
import { BunjouSection } from '@/components/form/Bunjou'
import { CategorySection } from '@/components/form/Category'
import { ColumnSuumoSection } from '@/components/form/ColumnSuumo'
import { CommentSection } from '@/components/form/Comment'
import { EnsenEkiSection } from '@/components/form/EnsenEki'
import { FieldErrorsMenu } from '@/components/form/FieldErrorsMenu'
import {
  FrontendValidationProvider,
  FrontendValidationContainer,
} from '@/components/form/FrontendValidation'
import { GeneralFormSection } from '@/components/form/General'
import { ImagesLayoutSection } from '@/components/form/ImagesLayout'
import { PanelImagesLayoutContextProvider } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { MiraieForm } from '@/components/form/MiraieForm'
import { MiraieFormProvider } from '@/components/form/MiraieFormProvider'
import { OpenHouseSection } from '@/components/form/OpenHouse'
import { PortalSection } from '@/components/form/Portal'
import { CostFormSection } from '@/components/form/PriceAndCost'
import { ReformSection } from '@/components/form/Reform'
import { RoomSection } from '@/components/form/Room'
import { SectionBar } from '@/components/form/SectionBar'
import { SetsubiSection } from '@/components/form/Setsubi'
import { ShisetsuSection } from '@/components/form/Shisetsu'
import { TatemonoSection } from '@/components/form/Tatemono'
import { TochiSection } from '@/components/form/Tochi'
import { TorihikiSection } from '@/components/form/Torihiki'
// import { ToshiField } from '@/components/form/Toshi'

export const BaitaiForm = ({ children }: PropsWithChildren) => {
  return (
    <Suspense fallback={<CSpinner />}>
      <PanelImagesLayoutContextProvider>
        <BackNavButton />
        <FrontendValidationProvider>
          <FrontendValidationContainer>
            <MiraieFormProvider>
              <SectionBar>
                <MiraieForm maxWidth="800" mx="auto">
                  <Flex
                    direction="column"
                    gap={4}
                    backgroundColor="gray.100"
                    px={6}
                    py={4}
                    borderRadius={8}
                  >
                    <FieldErrorsMenu />
                    <CategorySection />
                    <PortalSection />
                    <GeneralFormSection />
                    <EnsenEkiSection />
                    <CostFormSection />
                    <ReformSection />
                    <TatemonoSection />
                    <RoomSection />
                    <BunjouSection />
                    <TochiSection />
                    {/* TODO 投資用に対応するまで非表示 */}
                    {/* <ToshiField /> */}
                    <ColumnSuumoSection />
                    <TorihikiSection />
                    <SetsubiSection />
                    <CommentSection />
                    <OpenHouseSection />
                    <BukkenImagesSection />
                    <ShisetsuSection />
                    <ImagesLayoutSection />
                    {children}
                  </Flex>
                </MiraieForm>
              </SectionBar>
            </MiraieFormProvider>
          </FrontendValidationContainer>
        </FrontendValidationProvider>
      </PanelImagesLayoutContextProvider>
    </Suspense>
  )
}
