import { ManagementFormBaikai, ManagementFormSakimono } from '../../tera-three'
import { ValueOf } from '../../types'

/**
 * 管理方式区分
 */
export const Enum = {
  常駐管理: '1',
  日勤管理: '2',
  巡回管理: '3',
  なし: '4',
} as const

export const T3MapBaikai: Record<ManagementFormBaikai, ValueOf<typeof Enum>> = {
  自主管理: Enum.なし,
  '通勤（日勤）管理': Enum.日勤管理,
  巡回管理: Enum.巡回管理,
  常駐管理: Enum.常駐管理,
}

export const T3MapSakimono: Record<
  ManagementFormSakimono,
  ValueOf<typeof Enum>
> = {
  自主管理: Enum.なし,
  '通勤（日勤）管理': Enum.日勤管理,
  巡回管理: Enum.巡回管理,
  常駐管理: Enum.常駐管理,
}
