import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ButtonProps,
  useDisclosure,
  Text,
  Accordion,
  Link,
} from '@chakra-ui/react'

import { CreateAccordionItem } from './CreateAccordionItem'
import { EditAccordionItem } from './EditAccordionItem'
import { StopAccordionItem } from './StopAccordionItem'
import { T3BaikaiStopAccordionItem } from './T3BaikaiStopAccordionItem'
import { T3BaikaiUpdateAccordionItem } from './T3BaikaiUpdateAccordionItem'

import { StaticLink } from '@/constants'

export const HelpModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) => {
  return (
    <Modal {...{ isOpen, onClose }} size="6xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>媒体掲載の流れ</ModalHeader>
        <ModalBody>
          <Text mb={4}>
            詳しい操作手順は{' '}
            <Link href={StaticLink.agentManual} isExternal color="teal.500">
              マニュアル
            </Link>{' '}
            をご確認ください。
          </Text>
          <Accordion allowMultiple>
            <CreateAccordionItem />
            <EditAccordionItem />
            <T3BaikaiUpdateAccordionItem />
            <StopAccordionItem />
            <T3BaikaiStopAccordionItem />
          </Accordion>
        </ModalBody>
        <ModalFooter gap={4}>
          <Button onClick={onClose}>閉じる</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export const HelpButton = (props: ButtonProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  return (
    <>
      <Button onClick={onOpen} {...props} />
      <HelpModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
