import { HamburgerIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Skeleton,
  Td,
  Text,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useNavigate } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import { V1Import } from '@terass/common/src/firestore/V1Import'
import { PostMiraieV1Import } from '@terass/common/src/miraie/PostMiraieV1Import'
import { portals } from '@terass/common/src/portal'
import { BaikaiKeiyaku } from '@terass/common/src/tera-three'
import { formatDate } from '@terass/common/src/utils/date'
import { Suspense } from 'react'

import { DuplicateBaikaiTable } from '@/components/DuplicateBaikaiTable'
import { DuplicateSakimonoTable } from '@/components/DuplicateSakimonoTable'
import { PortalIcon, PortalIconContainer } from '@/components/icons'
import { useAddressNameOutofForm } from '@/hooks/useAddressName'
import { useContractByCaseId } from '@/hooks/useContractByCaseId'
import { observerClient } from '@/hooks/useObserver'
import { statusLabel } from '@/hooks/useV1ImportCollection'
import { v1importObserverOptions } from '@/hooks/useV1ImportDocument'
import { priceToJpString } from '@/utils/utils'

type Props = {
  id: string
  v1import: V1Import
}

export const BukkenRow: React.FC<Props> = ({ id, v1import }) => {
  const {
    __response,
    formData,
    teraSeven: { keisaiKikan },
    caseId,
    status,
    updatedAt,
  } = v1import
  const { tatemono_name, price: _price, sakimono_flg, bk_sbt_kbn } = formData
  const { isOpen, onOpen, onClose } = useDisclosure()

  const isChintai = miraieFields.bk_sbt_kbn.isChintai(bk_sbt_kbn)
  const navigate = useNavigate()
  const price = miraieFields.price.normalizePrice(_price, isChintai)

  return (
    <>
      <Tr
        onClick={() =>
          navigate({
            to: '/bukkens/$importId',
            params: { importId: id },
            state: (history) => {
              observerClient.setInitialValue(
                v1importObserverOptions(id),
                v1import,
              )
              return history
            },
          })
        }
        _hover={{
          bgColor: 'gray.100',
          cursor: 'pointer',
        }}
      >
        <Td pl={3}>
          <Menu>
            <MenuButton
              as={IconButton}
              icon={<HamburgerIcon />}
              variant="ghost"
              colorScheme="gray"
              rounded="full"
              size="sm"
              p={0}
              _hover={{ bgColor: 'gray.200' }}
              onClick={(e) => e.stopPropagation()}
            />
            <Portal>
              <MenuList>
                {status !== 'closed' && (
                  <MenuItem
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate({
                        to: '/bukkens/$importId/edit',
                        params: { importId: id },
                        state: (history) => {
                          observerClient.setInitialValue(
                            v1importObserverOptions(id),
                            v1import,
                          )
                          return history
                        },
                      })
                    }}
                  >
                    編集
                  </MenuItem>
                )}
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation()
                    onOpen()
                  }}
                >
                  複製編集
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
        </Td>
        <Td>{statusLabel[status]}</Td>
        <Td>{__response?.bukken_info.bk_cd ?? '-'}</Td>
        <Td>
          <Text fontWeight="bold" fontSize="lg" mb={3}>
            {tatemono_name ?? '-'}
          </Text>
          <Box mb={1}>
            <Suspense fallback={<Skeleton height="20px" />}>
              <Address formData={v1import.formData} />
            </Suspense>
          </Box>
          <Text>{price ? priceToJpString(price) : '-'}</Text>
        </Td>
        <Td>
          <KeisaiDate keisaiKikan={keisaiKikan} propName="from" />
        </Td>
        <Td>
          <KeisaiDate keisaiKikan={keisaiKikan} propName="to" />
        </Td>
        <Td>
          {sakimono_flg === miraieFields.sakimono_flg.Enum.先物
            ? '先物'
            : sakimono_flg === miraieFields.sakimono_flg.Enum.自社
              ? '売却媒介契約'
              : '-'}
        </Td>
        <Td>
          <Suspense fallback={<Skeleton h="20px" />}>
            {sakimono_flg === miraieFields.sakimono_flg.Enum.自社 &&
            // TODO: caseIdがrequiredになったらcaseIdの条件不要
            caseId ? (
              <Flex direction="column" gap={2}>
                <Text>
                  開始:{' '}
                  <BaikaiKeiyakuDisplay caseId={caseId} propName="startDate" />
                </Text>
                <Text>
                  終了:{' '}
                  <BaikaiKeiyakuDisplay caseId={caseId} propName="endDate" />
                </Text>
              </Flex>
            ) : (
              '-'
            )}
          </Suspense>
        </Td>
        <Td>
          <Suspense fallback={<Skeleton h="20px" />}>
            {caseId ? (
              <BaikaiKeiyakuDisplay caseId={caseId} propName="clientName" />
            ) : (
              '-'
            )}
          </Suspense>
        </Td>
        <Td>{formatDate(updatedAt.toDate())}</Td>
      </Tr>
      <Modal size="full" {...{ isOpen, onClose }}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          {sakimono_flg === miraieFields.sakimono_flg.Enum.先物 ? (
            <>
              <ModalHeader>先物媒体掲載依頼を選択</ModalHeader>
              <ModalBody>
                <Text>
                  「複製して編集」を行う場合は、事前にTerassCloudから「【先物】媒体掲載依頼」を実施いただく必要がございます。
                  <br />
                  また、複製元の物件と同じ物件種別（例：中古マンション、中古戸建）のみ複製可能となっております。
                  <br />
                  媒体掲載を行う「【先物】媒体掲載依頼」を選択してください。複製元の物件情報が反映された形で、下書きが作成されます。
                </Text>
                <DuplicateSakimonoTable baseFormData={formData} />
              </ModalBody>
            </>
          ) : (
            <>
              <ModalHeader>媒体掲載・マイソク作成依頼を選択</ModalHeader>
              <ModalBody>
                <Text>
                  「複製して編集」を行う場合は、事前にTerassCloudから「【媒介】媒体掲載・マイソク作成依頼」を実施いただく必要がございます。
                  <br />
                  また、複製元の物件と同じ物件種別（例：中古マンション、中古戸建）のみ複製可能となっております。
                  <br />
                  媒体掲載を行う「【媒介】媒体掲載・マイソク作成依頼」を選択してください。複製元の物件情報が反映された形で、下書きが作成されます。
                </Text>
                <DuplicateBaikaiTable baseFormData={formData} />
              </ModalBody>
            </>
          )}
          <ModalFooter>
            <Button onClick={onClose}>閉じる</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const Address = ({ formData }: { formData: PostMiraieV1Import }) => {
  const { azamade, banchi, chiban, partition_name } =
    useAddressNameOutofForm(formData)
  return azamade
    ? // eslint-disable-next-line no-irregular-whitespace
      `${azamade}${banchi ?? ''}${chiban ? `（地番：${chiban}）` : ''}　${partition_name ?? ''}`
    : '-'
}

const BaikaiKeiyakuDisplay = ({
  caseId,
  propName,
}: {
  caseId: string
  propName: keyof Pick<BaikaiKeiyaku, 'startDate' | 'endDate' | 'clientName'>
}) => useContractByCaseId(caseId)?.[propName] ?? '-'

const KeisaiDate = ({
  keisaiKikan,
  propName,
}: {
  keisaiKikan: V1Import['teraSeven']['keisaiKikan']
  propName: 'from' | 'to'
}) => {
  if (!keisaiKikan) return '-'
  return (
    <Flex direction="column" gap={2}>
      {portals.map((portal) =>
        keisaiKikan[portal] && keisaiKikan[portal][propName] ? (
          <Flex key={portal} gap={1} alignItems="center">
            <PortalIconContainer>
              <PortalIcon portal={portal} />
            </PortalIconContainer>
            <Text>{keisaiKikan[portal][propName] ?? '-'}</Text>
          </Flex>
        ) : null,
      )}
    </Flex>
  )
}
