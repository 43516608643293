/**
 * 文字列をセミコロンで区切りそれぞれをキーとし値が true であるオブジェクトを返す
 * @example
 * ```ts
 * transformMultiSelectString('a;b')
 * // -> { a: true, b: true }
 * ```
 */
export function transformMultiSelectString(
  value: string,
): Partial<Record<string, true>> {
  return Object.fromEntries(
    value
      .split(';')
      .filter(Boolean)
      .map((category) => [category, true]),
  )
}
