import {
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { DuplicateBaikaiTable } from '@/components/DuplicateBaikaiTable'
import { DuplicateSakimonoTable } from '@/components/DuplicateSakimonoTable'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export const DuplicateButton = (props: ButtonProps) => {
  const caseId = useV1ImportDocument(({ caseId }) => caseId)
  const formData = useV1ImportDocument(({ formData }) => formData)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { sakimono_flg } = formData

  return (
    <>
      {caseId === undefined ? null : (
        <>
          <Button onClick={onOpen} {...props} />
          <Modal size="full" {...{ isOpen, onClose }}>
            <ModalOverlay />
            <ModalContent>
              <ModalCloseButton />
              {sakimono_flg === miraieFields.sakimono_flg.Enum.先物 ? (
                <>
                  <ModalHeader>先物媒体掲載依頼を選択</ModalHeader>
                  <ModalBody>
                    <Text>
                      「複製して編集」を行う場合は、事前にTerassCloudから「【先物】媒体掲載依頼」を実施いただく必要がございます。
                      <br />
                      また、複製元の物件と同じ物件種別（例：中古マンション、中古戸建）のみ複製可能となっております。
                      <br />
                      媒体掲載を行う「【先物】媒体掲載依頼」を選択してください。複製元の物件情報が反映された形で、下書きが作成されます。
                    </Text>
                    <DuplicateSakimonoTable baseFormData={formData} />
                  </ModalBody>
                </>
              ) : (
                <>
                  <ModalHeader>媒体掲載・マイソク作成依頼を選択</ModalHeader>
                  <ModalBody>
                    <Text>
                      「複製して編集」を行う場合は、事前にTerassCloudから「【媒介】媒体掲載・マイソク作成依頼」を実施いただく必要がございます。
                      <br />
                      また、複製元の物件と同じ物件種別（例：中古マンション、中古戸建）のみ複製可能となっております。
                      <br />
                      媒体掲載を行う「【媒介】媒体掲載・マイソク作成依頼」を選択してください。複製元の物件情報が反映された形で、下書きが作成されます。
                    </Text>
                    <DuplicateBaikaiTable baseFormData={formData} />
                  </ModalBody>
                </>
              )}
              <ModalFooter>
                <Button onClick={onClose}>閉じる</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      )}
    </>
  )
}
