import {
  extendTheme,
  withDefaultColorScheme,
  theme as baseTheme,
} from '@chakra-ui/react'

const theme = extendTheme(
  withDefaultColorScheme({
    colorScheme: 'pink',
  }),
  {
    colors: {
      teraSeven: baseTheme.colors.pink,
      teraThree: baseTheme.colors.cyan,
    },
    fonts: {
      heading: 'Noto Sans JP',
      body: 'Noto Sans JP',
    },
  },
)

export default theme
