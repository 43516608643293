import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Flex,
  ButtonProps,
} from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'

export const CreateBaitaiButton = (props: ButtonProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Button onClick={onOpen} {...props} />
      <Modal
        {...{
          isOpen,
          onClose,
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>媒体掲載新規作成</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex gap={4} justifyContent="space-around" mb={4}>
              <Link to="/contracts">
                <Button height={40} width={40}>
                  媒介契約あり
                </Button>
              </Link>
              <Link to="/futures">
                <Button height={40} width={40}>
                  先物
                </Button>
              </Link>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
