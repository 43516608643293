import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { MizumawariKahiControls } from './MizumawariKahiControls'
import { NaisoKahiControls } from './NaisoKahiControls'
import { ReformKanoCollapse } from './ReformKanoCollapse'

import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import {
  EnumRadios,
  FormControl,
  FormLabel,
  PortalErrorMessages,
  RadioGroup,
  Textarea,
  UnselectedRadio,
} from '@/components/form/controls'

export const KahiControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>リフォーム可否</FormGroupHeading>
      <FormControl fieldName="reform_renovation_kanou_flg" fieldset>
        <FormLabel>リフォーム可否</FormLabel>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.reform_renovation_kanou_flg.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <ReformKanoCollapse>
        <FormGroup nested>
          <MizumawariKahiControls />
          <NaisoKahiControls />
          <FormGroup>
            <FormGroupHeading>その他</FormGroupHeading>
            <FormControl fieldName="reform_renovation_kanou_other_naiyo">
              <FormLabel>内容</FormLabel>
              <Textarea />
              <PortalErrorMessages />
              <FrontendValidationErrorMessages />
            </FormControl>
          </FormGroup>
        </FormGroup>
      </ReformKanoCollapse>
    </FormGroup>
  )
}
