import { Flex } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { RenovationKubunCollapse } from './RenovationKubunCollapse'

import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import {
  EnumRadios,
  FormControl,
  FormLabel,
  MonthOptions,
  PlaceholderOption,
  PortalErrorMessages,
  RadioGroup,
  Select,
  Textarea,
  UnselectedRadio,
  YearOptions,
} from '@/components/form/controls'

export const RenovationControls = () => {
  return (
    <FormGroup>
      <FormGroupHeading>リノベーション</FormGroupHeading>
      <FormControl fieldName="renovation_kbn" fieldset>
        <RadioGroup>
          <UnselectedRadio />
          <EnumRadios enum={miraieFields.renovation_kbn.Enum} />
        </RadioGroup>
        <PortalErrorMessages />
      </FormControl>
      <RenovationKubunCollapse>
        <FormGroup nested>
          <FormControl fieldset>
            <FormLabel>実施年月</FormLabel>
            <Flex gap={2}>
              <FormControl fieldName="renovation_ym_year">
                <Select>
                  <PlaceholderOption />
                  <YearOptions />
                </Select>
              </FormControl>
              <FormControl fieldName="renovation_ym_month">
                <Select>
                  <PlaceholderOption />
                  <MonthOptions />
                </Select>
              </FormControl>
            </Flex>
            <PortalErrorMessages fieldName="renovation_ym_year" />
            <PortalErrorMessages fieldName="renovation_ym_month" />
          </FormControl>
          <FormControl fieldName="renovation_naiyo">
            <FormLabel>内容</FormLabel>
            <Textarea />
            <PortalErrorMessages />
            <FrontendValidationErrorMessages />
          </FormControl>
        </FormGroup>
      </RenovationKubunCollapse>
    </FormGroup>
  )
}
