import { miraieFields } from '@terass/common/src'
import { Portal } from '@terass/common/src/portal'
import { PropsWithChildren } from 'react'

import { MiraieFieldCollapse } from '@/components/form/MiraieFieldCollapse'

type Props = PropsWithChildren<{
  portal: Portal
}>

export const PortalOptionsCollapse = ({ portal, children }: Props) => {
  return (
    <MiraieFieldCollapse
      name={[`${portal}_c_open_kbn`]}
      display={(c_open_kbn) => c_open_kbn === miraieFields.c_open_kbn.Enum.掲載}
    >
      {children}
    </MiraieFieldCollapse>
  )
}
