import z from 'zod'

/** tera-three から取得するagentのスキーマ */
export const Agent = z
  .object({
    Id: z.string(),
    Name: z.string(),
    EnglishName__c: z.string(),
    BusinessName__c: z.string().nullable(),
    EnglishBusinessName__c: z.string().nullable(),
    Email: z.string(),
    Office__c: z.string(),
    AthomeStaffId__c: z.string().nullable(),
  })
  .transform(
    ({
      Id,
      Name,
      EnglishName__c,
      BusinessName__c,
      EnglishBusinessName__c,
      Email,
      Office__c,
      AthomeStaffId__c,
    }) => ({
      id: Id,
      name: Name,
      englishName: EnglishName__c,
      businessName: BusinessName__c,
      englishBusinessName: EnglishBusinessName__c,
      email: Email,
      office: Office__c,
      athomeStaffId: AthomeStaffId__c,
    }),
  )
export type Agent = z.output<typeof Agent>

export const AgentList = z.array(Agent)
