import {
  MediaPublishRequestForTeraSeven,
  SakimonoMediaPublishRequestForTeraSeven,
} from '../../tera-three'
import { ValueOf } from '../../types'

/**
 * 現況区分
 */
export const Enum = {
  更地: '11',
  '古家付(賃貸)': '12',
  古家付: '13',
  未造成: '14',
  居住中: '21',
  空: '22',
  建築中: '23',
  使用中: '24',
  築後未入居: '25',
  賃貸中: '27',
  未着工: '31',
  '賃貸中(全部)': '33',
  '賃貸中(一部)': '34',
  その他: '99',
} as const

export const T3MapBaikai: Record<
  MediaPublishRequestForTeraSeven['PropertyCurrentStatus__c'],
  ValueOf<typeof Enum>
> = {
  居住中: Enum.居住中,
  更地: Enum.更地,
  空室: Enum.空,
  賃貸中: Enum.賃貸中,
}

export const T3MapSakimono: Record<
  SakimonoMediaPublishRequestForTeraSeven['PropertyCurrentStatus__c'],
  ValueOf<typeof Enum>
> = {
  居住中: Enum.居住中,
  更地: Enum.更地,
  空室: Enum.空,
  賃貸中: Enum.賃貸中,
}
