import { PublicationMediumCategoryBaikai } from './tera-three'
import { swapKeyValue } from './utils'

export const portalNames = {
  suumo: 'SUUMO',
  homes: "HOME'S",
  athome: 'athome',
  rakumachi: '楽待',
} as const

export type Portal = keyof typeof portalNames
export type ErrorPortal = Portal | '公取' | 'logParam' | 'validate'

export const ePortal = swapKeyValue(portalNames)

export const portals = [
  ePortal.SUUMO,
  ePortal["HOME'S"],
  ePortal.athome,
  ePortal.楽待,
] as const

/** tera-threeの媒体掲載・マイソク作成依頼で入力するポータルとT7上のポータルのマップ */
export const T3PortalMapBaikai: Record<
  Portal,
  PublicationMediumCategoryBaikai
> = {
  athome: 'athome',
  homes: "HOME'S",
  rakumachi: '楽待',
  suumo: 'SUUMO',
}

/** tera-threeの先物媒体掲載依頼で入力するポータルとT7上のポータルのマップ */
export const T3PortalMapSakimono: Record<
  Portal,
  PublicationMediumCategoryBaikai
> = {
  athome: 'athome',
  homes: "HOME'S",
  rakumachi: '楽待',
  suumo: 'SUUMO',
}
