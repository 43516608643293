import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Box, Button, Flex, Link as ChakraLink } from '@chakra-ui/react'
import { createFileRoute, Outlet } from '@tanstack/react-router'
import { Suspense } from 'react'

import { CSpinner, PageHeading } from '@/components/Atoms'
import { CreateBaitaiButton } from '@/components/CreateBaitaiButton'
import { config } from '@/config'
import { useUserRole } from '@/hooks/useUser'

export const Route = createFileRoute('/_layout')({
  component: Top,
})

export function Top() {
  const role = useUserRole()
  return (
    <>
      <Flex justifyContent="space-between" my={2}>
        <PageHeading>媒体掲載一覧</PageHeading>
        <CreateBaitaiButton>新規作成</CreateBaitaiButton>
      </Flex>
      {role === 'assistant' && (
        <ChakraLink href={config.MIRAIE_SEARCH_URL} isExternal>
          <Button rightIcon={<ExternalLinkIcon />} variant="outline">
            みらいえ管理画面で物件検索する
          </Button>
        </ChakraLink>
      )}
      <Box mt={2} mb={8}>
        <Suspense fallback={<CSpinner />}>
          <Outlet />
        </Suspense>
      </Box>
    </>
  )
}
