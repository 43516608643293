import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button } from '@chakra-ui/react'
import { Link } from '@tanstack/react-router'

export function BackNavButton() {
  return (
    <Link to="/">
      <Button variant="link" leftIcon={<ArrowBackIcon />} my={4}>
        媒体掲載一覧に戻る
      </Button>
    </Link>
  )
}
