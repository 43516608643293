import dayjs from 'dayjs'

/**
 * 日付の年月日を別々に取得する
 * 無効な日付の場合は`undefined` を返す
 * @example
 * ```ts
 * getYmd(new Date('2022-01-23')) // { year: 2022, month: 1, date: 23, hour: 9 }
 * // 中途半端な日付の場合、デフォルト値が設定される
 * getYmd(new Date('2022/01')) // { year: 2022, month: 1, date: 1, hour: 9 }
 * ```
 */
export function getYmd(date: Date):
  | {
      year: number
      month: number
      date: number
      hour: number
    }
  | undefined {
  const d = dayjs(date)
  if (!d.isValid()) return undefined
  return {
    year: d.year(),
    month: d.month() + 1,
    date: d.date(),
    hour: d.hour(),
  }
}
