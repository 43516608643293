import { Flex, Text } from '@chakra-ui/react'
import { miraieFields } from '@terass/common/src'

import { HaniEnumCheckbox } from '../PriceAndCost/HaniEnumCheckbox'

import { FormGroup } from '@/components/form/FormGroup'
import { FormGroupHeading } from '@/components/form/FormGroupHeading'
import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import { MiraieFieldContents } from '@/components/form/MiraieFieldContents'
import {
  EnumOptions,
  FieldLength,
  FormControl,
  FormHelperText,
  FormLabel,
  NumberInput,
  PlaceholderOption,
  PortalErrorMessages,
  Select,
  Textarea,
} from '@/components/form/controls'

export const TatoRoom = () => {
  return (
    <FormGroup>
      <FormGroupHeading>部屋関連</FormGroupHeading>

      <FormControl fieldset>
        <FormLabel>間取</FormLabel>

        <FormGroup>
          <FormControl fieldName="madori_suumo_hani_kbn">
            <HaniEnumCheckbox>間取を範囲で指定する</HaniEnumCheckbox>
            <PortalErrorMessages />
          </FormControl>

          <FormControl fieldset>
            <FormGroup>
              <Flex gap={2} alignItems="center">
                <FormControl fieldName="madori_suumo_room_su">
                  <NumberInput />
                </FormControl>

                <FormControl fieldName="madori_suumo_kbn">
                  <Select>
                    <PlaceholderOption />
                    <EnumOptions enum={miraieFields.madori_suumo_kbn.Enum} />
                  </Select>
                </FormControl>

                <FormControl fieldName="madori_types_kbn">
                  <Select>
                    <PlaceholderOption />
                    <EnumOptions enum={miraieFields.madori_types_kbn.Enum} />
                  </Select>
                </FormControl>
              </Flex>

              <MiraieFieldContents
                name={['madori_suumo_hani_kbn']}
                display={(madori_suumo_hani_kbn) =>
                  madori_suumo_hani_kbn ===
                  miraieFields.madori_suumo_hani_kbn.Enum['～']
                }
              >
                <Flex gap={2} align="center">
                  <Text>～</Text>
                  <FormControl fieldName="madori_suumo_room_su_to">
                    <NumberInput />
                  </FormControl>

                  <FormControl fieldName="madori_suumo_to_kbn">
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions enum={miraieFields.madori_suumo_kbn.Enum} />
                    </Select>
                  </FormControl>

                  <FormControl fieldName="madori_suumo_types_to_kbn">
                    <Select>
                      <PlaceholderOption />
                      <EnumOptions enum={miraieFields.madori_types_kbn.Enum} />
                    </Select>
                  </FormControl>
                </Flex>
              </MiraieFieldContents>
            </FormGroup>
          </FormControl>
        </FormGroup>

        <FormHelperText>
          {`LLDDKKや、2Sなどのより細かい間取情報を登録できます。
対応ポータルでは、こちらの入力が利用されます。`}
        </FormHelperText>
        <PortalErrorMessages fieldName="madori_suumo_room_su" />
        <PortalErrorMessages fieldName="madori_suumo_kbn" />
        <PortalErrorMessages fieldName="madori_types_kbn" />
        <PortalErrorMessages fieldName="madori_suumo_room_su_to" />
        <PortalErrorMessages fieldName="madori_suumo_to_kbn" />
        <PortalErrorMessages fieldName="madori_suumo_types_to_kbn" />
      </FormControl>

      <FormControl fieldName="tato_madori_biko">
        <FormLabel>多棟間取備考</FormLabel>
        <Textarea />
        <FieldLength maxLength={miraieFields.tato_madori_biko.maxLength} />
        <PortalErrorMessages />
        <FrontendValidationErrorMessages />
      </FormControl>
    </FormGroup>
  )
}
