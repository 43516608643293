import { Portal } from '@terass/common/src/portal'
import { callThis } from '@terass/common/src/utils'
import { isDateAfter, parseDateString } from '@terass/common/src/utils/date'
import { ReactNode } from 'react'

import { FormControl } from '@/components/form/controls'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'

export type CloseDateFormControlProps = {
  portal: Portal
  children: ReactNode
}

export const CloseDateFormControl = ({
  portal,
  ...props
}: CloseDateFormControlProps) => {
  const closeDate = useV1ImportDocument(
    ({ teraSeven: { keisaiKikan } }) => keisaiKikan?.[portal]?.to,
  )
  const today = new Date()
  const isDisabled = !!(
    closeDate && callThis(today, isDateAfter, parseDateString(closeDate))
  )

  return (
    <FormControl
      fieldName={`draftCloseDates.${portal}`}
      {...(isDisabled && { isDisabled })}
      {...props}
    />
  )
}
