import { Tr, Td, Skeleton } from '@chakra-ui/react'

type Props = {
  rows: number
  cols: number
}

export const TbodySkeleton = ({ rows, cols }: Props) =>
  [...Array(rows)].map((_, i) => (
    <Tr key={i}>
      {[...Array(cols)].map((_, i) => (
        <Td key={i}>
          <Skeleton height={5} />
        </Td>
      ))}
    </Tr>
  ))
