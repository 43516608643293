import { TextareaProps } from '@chakra-ui/react'
import { forwardRef } from 'react'

import {
  getImageControlsArea,
  useImageControlsContext,
} from '@/components/form/BukkenImages/Controls/ImageControlsContext'
import { Textarea } from '@/components/form/BukkenImages/Controls/Textarea'
import { useBukkenImagesFormContext } from '@/components/form/BukkenImages/hooks/index'

export const ImgTitleTextarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  function ImgTitleTextarea(props, ref) {
    const { category, index } = useImageControlsContext()
    const {
      formState: { errors },
    } = useBukkenImagesFormContext()
    const hasError = !!(
      errors[category] &&
      errors[category][index] &&
      errors[category][index].img_title
    )

    return (
      <Textarea
        fieldName={`${category}.${index}.img_title`}
        placeholder="タイトル(50文字)"
        gridArea={getImageControlsArea('img_title')}
        isInvalid={hasError}
        {...props}
        ref={ref}
      />
    )
  },
)
