import { miraieFields } from '@terass/common/src'

import { BukkenImagesContextProvider as Provider } from '@/components/form/BukkenImages/hooks'
import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import {
  useMiraieFormContext,
  useWatchMiraieFields,
} from '@/hooks/useMiraieForm'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import { updateImport } from '@/utils/import'

export const BukkenImagesContextProvider = (props: {
  children: React.ReactNode
}) => {
  const importId = useV1ImportParam()
  const currentPanelImagesLayout = useV1ImportDocument(
    ({ panelImagesLayout }) => panelImagesLayout,
  )
  const { setPanelImagesLayout } = usePanelImagesLayoutContext()
  const [
    images = [],
    bk_sbt_kbn,
    suumo_c_open_kbn,
    present_caption,
    pamphlet_info_caption_kbn,
  ] = useWatchMiraieFields({
    name: [
      'images',
      'bk_sbt_kbn',
      'suumo_c_open_kbn',
      'present_caption',
      'pamphlet_info_caption_kbn',
    ],
  })
  const { setValue, trigger } = useMiraieFormContext()
  const prevImages = images

  return (
    <Provider
      value={{
        images,
        bk_sbt_kbn,
        suumo_c_open_kbn,
        present_caption,
        pamphlet_info_caption_kbn,
        updateData: async ({
          images = [],
          present_caption,
          pamphlet_info_caption_kbn,
        }) => {
          const unusedImageUrls = miraieFields.images.getUnusedImageUrls(
            prevImages,
            images,
          )

          setValue('images', images)
          setValue('present_caption', present_caption)
          setValue('pamphlet_info_caption_kbn', pamphlet_info_caption_kbn)
          const validationResult = await trigger([
            'images',
            'present_caption',
            'pamphlet_info_caption_kbn',
          ])
          if (!validationResult) throw new Error('validation failed')

          await updateImport(importId, {
            'formData.images': images,
            'formData.present_caption': present_caption,
            'formData.pamphlet_info_caption_kbn': pamphlet_info_caption_kbn,
            panelImagesLayout: currentPanelImagesLayout?.filter(
              (imageUrl) => !unusedImageUrls.includes(imageUrl),
            ),
          })
          setPanelImagesLayout((panelImagesLayout) =>
            panelImagesLayout.map((panelImage) => {
              const { id, imageUrl } = panelImage
              if (!imageUrl || !unusedImageUrls.includes(imageUrl))
                return panelImage

              return { id, imageUrl: undefined }
            }),
          )
        },
      }}
      {...props}
    />
  )
}
