import {
  availableOfficeNames,
  officeNames,
} from '@terass/common/src/tera-three/office'

export const ShopSelectOptions = () => (
  <>
    {officeNames.map((name) => (
      <option
        key={name}
        value={name}
        disabled={!availableOfficeNames.includes(name)}
      >
        {name}
      </option>
    ))}
  </>
)
