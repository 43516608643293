import {
  AccordionItem,
  AccordionButton,
  Box,
  AccordionIcon,
  AccordionPanel,
  Button,
  Grid,
  Link,
  Text,
} from '@chakra-ui/react'

import { CenteringGridItem } from './CenteringGridItem'

import { getT3PublishLink } from '@/constants'
import { gridTemplate } from '@/utils/gridTemplate'

export const StopAccordionItem = () => {
  return (
    <AccordionItem>
      <AccordionButton>
        <Box as="span" flex="1" textAlign="left">
          掲載の停止
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        <Grid
          gridTemplate={style}
          justifyItems="center"
          alignItems="center"
          height={300}
          width={180}
        >
          <CenteringGridItem
            gridArea={getArea('T3Header')}
            width="100%"
            borderColor="teraThree.500"
          >
            <Text>Terass Cloud</Text>
          </CenteringGridItem>
          <Button
            colorScheme="teraThree"
            gridArea={getArea('T3StopShinsei')}
            width="100%"
            height="90%"
            as={Link}
            href={getT3PublishLink({
              isSakimono: false,
            })}
            isExternal
          >
            媒体掲載停止申請
          </Button>
        </Grid>
        <Text>
          ※媒介契約がクローズされた場合、媒体掲載も自動で停止されます。
        </Text>
      </AccordionPanel>
    </AccordionItem>
  )
}

const { style, getArea } = gridTemplate`
  "${'T3Header'}"      auto
  "${'T3StopShinsei'}" 1fr
  / auto           
`
