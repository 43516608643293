import { Flex } from '@chakra-ui/react'
import { PostMiraieV1ImportForm } from '@terass/common/src/miraie/PostMiraieV1ImportForm'

import { FormGroup } from '../FormGroup'
import { FormGroupHeading } from '../FormGroupHeading'

import { NaisoBikoCollapse } from './NaisoBikoCollapse'

import { FrontendValidationErrorMessages } from '@/components/form/FrontendValidation'
import {
  BooleanEnumCheckbox,
  CheckboxGroup,
  FormControl,
  FormHelperText,
  FormLabel,
  PortalErrorMessages,
  Textarea,
  TextInput,
  MonthOptions,
  PlaceholderOption,
  Select,
  YearOptions,
} from '@/components/form/controls'
import { useIsChintai } from '@/hooks/useIsChintai'

export const NaisoControls = () => {
  const isChintai = useIsChintai()

  return (
    <FormGroup>
      <FormGroupHeading>内装</FormGroupHeading>
      <FormControl fieldset>
        <FormLabel>実施年月</FormLabel>
        <Flex gap={2}>
          <FormControl fieldName="reform_naiso_ym_year">
            <Select>
              <PlaceholderOption />
              <YearOptions />
            </Select>
          </FormControl>
          <FormControl fieldName="reform_naiso_ym_month">
            <Select>
              <PlaceholderOption />
              <MonthOptions />
            </Select>
          </FormControl>
        </Flex>
        <FormHelperText>
          HOME'Sフォーマット使用ポータルはリフォーム実施年月が未来の年月の場合はリフォーム情報は反映されません。
        </FormHelperText>
        <PortalErrorMessages fieldName="reform_naiso_ym_year" />
        <PortalErrorMessages fieldName="reform_naiso_ym_month" />
      </FormControl>
      <FormControl fieldset>
        <FormLabel>実施箇所</FormLabel>
        <CheckboxGroup>
          {(isChintai ? chintaiNaisoCheckFields : baibaiNaisoCheckFields).map(
            (fieldName) => (
              <BooleanEnumCheckbox key={fieldName} fieldName={fieldName}>
                {
                  {
                    // 売買用
                    reform_naiso_all_flg: '全室',
                    reform_zensitsu_flg: '全室クロス',
                    // 賃貸用
                    reform_naiso_zenmen_flg: '全面',
                    reform_cloth_flg: '全室クロス',
                    // 共通
                    reform_yuka_flg: '床',
                    reform_kabe_flg: '壁',
                    reform_fixture_flg: '建具',
                    reform_sash_flg: 'サッシ',
                    reform_naiso_flg: 'その他',
                  }[fieldName]
                }
              </BooleanEnumCheckbox>
            ),
          )}
        </CheckboxGroup>
        {baibaiNaisoCheckFields.map((fieldName) => (
          <PortalErrorMessages key={fieldName} fieldName={fieldName} />
        ))}
      </FormControl>
      <NaisoBikoCollapse>
        <FormGroup nested>
          <FormControl fieldName="reform_naiso_biko">
            <FormLabel>その他</FormLabel>
            <TextInput />
            <PortalErrorMessages />
          </FormControl>
        </FormGroup>
      </NaisoBikoCollapse>
      <FormControl fieldName="reform_naiso_enforcement_content">
        <FormLabel>実施内容</FormLabel>
        <Textarea />
        <PortalErrorMessages />
        <FrontendValidationErrorMessages />
      </FormControl>
    </FormGroup>
  )
}

const baibaiNaisoCheckFields = [
  'reform_naiso_all_flg',
  'reform_zensitsu_flg',
  'reform_yuka_flg',
  'reform_kabe_flg',
  'reform_fixture_flg',
  'reform_sash_flg',
  'reform_naiso_flg',
] as const satisfies readonly (keyof PostMiraieV1ImportForm)[]

const chintaiNaisoCheckFields = [
  'reform_naiso_zenmen_flg',
  'reform_cloth_flg',
  'reform_yuka_flg',
  'reform_kabe_flg',
  'reform_fixture_flg',
  'reform_sash_flg',
  'reform_naiso_flg',
] as const satisfies readonly (keyof PostMiraieV1ImportForm)[]
