import { z } from 'zod'

import { draftCloseDatesSchema } from '../firestore/DraftCloseDate'

import { PostMiraieV1Import } from './PostMiraieV1Import'

export type PostMiraieV1ImportForm = z.infer<typeof PostMiraieV1ImportForm>
export const PostMiraieV1ImportForm = z.object({
  ...PostMiraieV1Import.shape,
  draftCloseDates: draftCloseDatesSchema,
})
