import { z } from 'zod'

export const MediaCampaignUpdateResponse = z.object({
  actionName: z.string(),
  errors: z.string().nullish(),
  isSuccess: z.boolean(),
  outputValues: z.object({
    recordId: z.string(),
    Flow__InterviewStatus: z.string(),
  }),
})
export type MediaCampaignUpdateResponse = z.infer<
  typeof MediaCampaignUpdateResponse
>
