import { PostMiraieV1Import } from '../miraie/PostMiraieV1Import'
import type { PostMiraieV1ImportForm } from '../miraie/PostMiraieV1ImportForm'

/**
 * 禁止ワード
 */
export const ngWords = [
  '至便',
  '最高',
  '最適',
  '最大',
  '一級',
  '極上',
  '屈指',
  '二度とない',
  '究極',
  '完全',
  '完璧',
  '完ぺき',
  '絶対',
  '万全',
  '完備',
  '秀逸',
  'フル',
  '日本一',
  '日本初',
  '業界一',
  '唯一',
  '理想',
  '当社だけ',
  '羨望',
  '抜群',
  '超',
  '他にない',
  'ほかにない',
  '一流',
  '一等地',
  'とっておき',
  '校区良し',
  '校区よし',
  '人気の学校区',
  '貴重',
  '特選',
  '厳選',
  '決定版',
  '限定販売',
  '今がチャンス',
  'またとない',
  '数少ない',
  '今が買い時',
  '惜譲',
  '早い者勝ち',
  '稀少',
  '希少',
  '申し分ない',
  'お早めに',
  'ラストチャンス',
  '公庫仕様',
  '事業投売',
  '駅徒歩０分',
  '適正価格',
  '新発売',
  '新築同様',
  '掘り出し',
  '買得',
  '格安',
  '激安',
  '破格',
  '価格相談',
  'お得',
  '大幅値下げ',
  '安値',
  'バーゲン',
  'お求めやすい',
  '手頃な価格',
  '資産価値大',
  '投資向き財産価値あり',
  '将来性大',
  '投資最適',
  '高収入が期待',
  '高収入向き物件',
  'ディズニー',
  'オリンピック',
  '東京ドーム',
  '歌舞伎座',
  '初掲載',
  '初登場',
  '初公開',
  '初めてのご紹介',
]

/**
 * 正規表現の構文を含む禁止ワード
 *
 * 禁止ワードを検証するときはこの配列を用いて正規表現でチェックを行う
 */
export const ngWordsForRegExp = [
  ...ngWords.filter(
    (ng) =>
      ![
        // 正規表現に置換したいワード
        '一級',
        '二度とない',
        '日本一',
        '業界一',
        '超',
        '一流',
        '一等地',
        '駅徒歩０分',
      ].includes(ng),
  ),
  '[一１1]級',
  '[二２2]度とない',
  '日本[一１1]',
  '業界[一１1]',
  '超(?!高層階)', // 超高階層は許容する
  '[一１1]流',
  '[一１1]等地',
  '駅徒歩[０0]分',
]

/**
 * 禁止ワード検証の対象フィールドのリスト
 */
export const ngWordsTargetFields: (keyof PostMiraieV1Import)[] = [
  'horei_biko',
  'shakuchi_keiyakujiko',
  'kashi_hosho_k_other_naiyo',
  'kashi_hosho_f_other_naiyo',
  'choki_yuryo_nintei_zokaichiku_naiyo',
  'key_biko',
  'reform_mizumawari_enforcement_content',
  'reform_naiso_enforcement_content',
  'reform_gaiso_enforcement_content',
  'reform_common_enforcement_content',
  'reform_other_enforcement_content',
  'reform_mitsumori_biko',
  'renovation_naiyo',
  'reform_renovation_kanou_mizumawari_naiyo',
  'reform_renovation_kanou_naiso_naiyo',
  'reform_renovation_kanou_other_naiyo',
  'openhouse_biko',
  'openhouse_biko_homes',
  'athome_biko_1',
  'athome_end_appeal',
  'athome_com',
  'athome_miryoku',
  'homes_catch_copy',
  'homes_staff_com',
  'homes_biko',
  'bk_catch',
  'net_com',
  'net_free_com',
  'suumo_biko',
  'staff_pr_com',
  'chohyo_biko',
  'payment_example_bk_info',
  'payment_example_price',
  'payment_example_rate',
  'payment_example_amount_of_payment',
  'payment_example_loan_biko',
  'flat35_loan_biko',
  'suumo_horei_biko',
  'homes_horei_biko',
  'athome_horei_biko',
  'frk_warning_biko',
  'konyu_support_gaiyo_shosai_1',
  'konyu_support_gaiyo_kikan_1',
  'konyu_support_gaiyo_chuui_1',
  'konyu_support_gaiyo_shosai_2',
  'konyu_support_gaiyo_kikan_2',
  'konyu_support_gaiyo_chuui_2',
  'konyu_support_gaiyo_shosai_3',
  'konyu_support_gaiyo_kikan_3',
  'konyu_support_gaiyo_chuui_3',
  'chinhosho_naiyo',
  'interior_custom_naiyo',
  'interior_custom_joken',
  'yokoku_kokoku',
  'hanbai_schedule_comment',
  'genba_toiawase_name',
  'genba_toiawase_biko',
  'tato_parking',
  'tato_saita_price_com',
  'hanbaiki_name',
  'tato_kenchiku_joken_freecom',
  'suumo_tato_shakuchiken_type_biko',
  'suumo_tato_shakuchiken_chinryo_biko',
  'tato_shakuchiken_other_price_biko',
  'suumo_doro_comment',
  'kenpeiritsu_yosekiritsu',
  'tato_madori_biko',
]
