import {
  Box,
  Button,
  FormLabel,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import { PostMiraieV1Import } from '@terass/common/src/miraie/PostMiraieV1Import'
import {
  SakimonoMediaPublishRequestForTeraSeven,
  sakimonoToT7CloseDate,
  sakimonoToT7Options,
  sakimonoShinseiToPostMiraie,
  sakimonoPropertyTypes,
} from '@terass/common/src/tera-three'
import { availableOfficeNames } from '@terass/common/src/tera-three/office'
import { Suspense, useState } from 'react'

import { ShopSelectOptions } from '@/components/ShopSelectOptions'
import { TbodySkeleton } from '@/components/TbodySkeleton'
import { useOperator } from '@/hooks/useOperator'
import { useSakimonoMediaPublishRequestForTeraSevens } from '@/hooks/useSakimonoMediaPublishRequestForTeraSevens'
import { useUserEmail } from '@/hooks/useUser'
import { createImport, updateImport } from '@/utils/import'
import { convertOfficeToShop } from '@/utils/officeToShop'
import { priceToJpString } from '@/utils/utils'

export type DuplicateSakimonoTableProps = {
  baseFormData: PostMiraieV1Import
}

export const DuplicateSakimonoTable = ({
  baseFormData,
}: DuplicateSakimonoTableProps) => {
  return (
    <TableContainer>
      <Table fontSize="sm" layout="fixed" mt={4}>
        <Thead bgColor="teraSeven.50" color="white">
          <Tr>
            <Th width={40}>物件種別</Th>
            <Th width={300}>物件名</Th>
            <Th width={140}>価格</Th>
          </Tr>
        </Thead>
        <Tbody>
          <Suspense fallback={<TbodySkeleton rows={3} cols={3} />}>
            <SakimonoShinseiItems {...{ baseFormData }} />
          </Suspense>
        </Tbody>
      </Table>
    </TableContainer>
  )
}

type SakimonoShinseiItemsProps = {
  baseFormData: PostMiraieV1Import
}

const SakimonoShinseiItems = ({ baseFormData }: SakimonoShinseiItemsProps) => {
  const { email } = useOperator()
  const items = useSakimonoMediaPublishRequestForTeraSevens({
    agentEmail: email,
    propertyType: sakimonoPropertyTypes.filter(
      (type) =>
        miraieFields.bk_item_kbn.T3MapSakimono[type] ===
        baseFormData.bk_item_kbn,
    ),
  })

  return (
    <>
      {items.map((shinsei) => (
        <SakimonoShinseiItem key={shinsei.Id} {...{ shinsei, baseFormData }} />
      ))}
    </>
  )
}

type SakimonoShinseiItemProps = {
  shinsei: SakimonoMediaPublishRequestForTeraSeven
  baseFormData: PostMiraieV1Import
}

const SakimonoShinseiItem = ({
  shinsei,
  baseFormData,
}: SakimonoShinseiItemProps) => {
  const { email, office } = useOperator()
  const userEmail = useUserEmail()
  const navigate = useNavigate()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [officeName, setOfficeName] = useState<string>(
    availableOfficeNames.includes(office)
      ? office
      : (availableOfficeNames.at(0) ?? ''),
  )
  const { Id, PropertyNameText__c, PropertyType__c, ListingPrice__c } = shinsei
  const createImportMutation = useMutation({
    mutationFn: async () => {
      const shopId = convertOfficeToShop(officeName)
      const data = await createImport({
        __readers: [email],
        __writers: [email],
        __tera_three: {},
        caseId: Id,
        formData: {
          ...sakimonoShinseiToPostMiraie(shinsei),
          sakimono_flg: miraieFields.sakimono_flg.Enum.先物,
        },
        teraSeven: {},
        shopId,
        createdBy: userEmail,
      })
      return data
    },
    onSuccess: async ({ id }) => {
      // 各ポータルの掲載区分、掲載終了日、オプションは媒体掲載作成時にみらいえに反映されないよう、このタイミングで値を変換する
      // ref. https://www.notion.so/terass/7f1284cf129c47b2bd7ab5d4ad3bc6f3
      await updateImport(id, {
        formData: {
          ...baseFormData,
          ...sakimonoShinseiToPostMiraie(shinsei),
          ...miraieFields.c_open_kbn.sakimonoToT7OpenKbns(
            shinsei.PublicationMediumCategory__c,
          ),
          ...sakimonoToT7Options(shinsei),
        },
        teraSeven: {
          keisaiKikan: sakimonoToT7CloseDate(shinsei),
        },
      })
      navigate({ to: '/bukkens/$importId/edit', params: { importId: id } })
    },
  })

  return (
    <>
      <Tr
        onClick={onOpen}
        _hover={{
          bgColor: 'gray.100',
          cursor: 'pointer',
        }}
        transition="common"
      >
        <Td>{PropertyType__c}</Td>
        <Td>{PropertyNameText__c}</Td>
        <Td>{priceToJpString(ListingPrice__c)}</Td>
      </Tr>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="xl"
        closeOnEsc={!createImportMutation.isPending}
        closeOnOverlayClick={!createImportMutation.isPending}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>媒体掲載作成</ModalHeader>
          <ModalCloseButton isDisabled={createImportMutation.isPending} />
          <ModalBody display="flex" flexDirection="column" gap={4}>
            <Text>以下の申請から媒体掲載を作成します。</Text>

            <Box>
              <FormLabel fontWeight="semibold">物件名</FormLabel>
              <Text>{PropertyNameText__c}</Text>
            </Box>

            <Box>
              <FormLabel fontWeight="semibold">価格</FormLabel>
              <Text>{priceToJpString(ListingPrice__c)}</Text>
            </Box>

            <Heading size="sm">店舗を選択</Heading>
            <Select
              onChange={(e) => {
                setOfficeName(e.target.value)
              }}
              value={officeName}
            >
              <ShopSelectOptions />
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={onClose}
              colorScheme="gray"
              isDisabled={createImportMutation.isPending}
            >
              キャンセル
            </Button>
            <Button
              ml={3}
              onClick={() => createImportMutation.mutate()}
              isLoading={createImportMutation.isPending}
            >
              OK
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
