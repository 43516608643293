import z from 'zod'

import { miraieFields } from './fields'

// https://docs.google.com/spreadsheets/d/1khbv_Gq1I0wrfbi6iiZ41VkrqxbAv-qR/edit#gid=1116676149

export type PostMiraieV1Import = z.infer<typeof PostMiraieV1Import>
export const PostMiraieV1Import = z.object({
  /** 担当者ID */
  tanto_id: optionalNumber(), // 連動不可(相談)
  /** 加盟店整理コード(問合せコード) */
  shop_seiri_cd: optionalString(),
  /** 物元物件コード */
  butsumoto_bk_cd: optionalString(),
  /** 部門ID */
  contact_id: optionalNumber(), // 連動不可(相談)
  /** 物件状態区分 */
  bk_state_kbn: z.nativeEnum(miraieFields.bk_state_kbn.Enum),
  /** 物件種別区分 */
  bk_sbt_kbn: z.nativeEnum(miraieFields.bk_sbt_kbn.Enum), // 必須(更新不可)
  /** 物件種目区分 */
  bk_item_kbn: z.nativeEnum(miraieFields.bk_item_kbn.Enum), // 必須(更新不可)
  /** 郵便番号 */
  zip_no: optionalString(), // TODO 反映されない
  /** 市区町村コード */
  gyosei_cd: optionalString(),
  /** 大字小字コード */
  aza_cd: optionalString(),
  /** 番地 */
  banchi: optionalString(), // 番地以下
  banchi_flg: optionalStrictString(z.nativeEnum(miraieFields.banchi_flg.Enum)),
  /** 地番 */
  chiban: optionalString(),
  // hb_to_id: z.any(),
  // shop_to_id: z.any(),
  /** 緯度 */
  lat: optionalNumber(), // TODO DECIMAL 11 7
  /** 経度 */
  lon: optionalNumber(), // TODO DECIMAL 11 7
  // ensen_flg: z.any(),
  /** 最寄駅沿線コード1 */
  ensen_cd_1: optionalString(),
  /** 最寄駅駅コード1 */
  eki_cd_1: optionalString(),
  /** 最寄まで徒歩(分)1 */
  walk_time_1: optionalNumber(),
  /** 最寄まで徒歩(m)1 */
  walk_kyori_1: optionalNumber(),
  /** 最寄バス路線名1 */
  bus_line_name_1: optionalString(),
  /** 最寄までバス(分)1 */
  bus_time_1: optionalNumber(),
  /** 最寄のバス停名称1 */
  bus_stop_name_1: optionalString(),
  /** 最寄のバス停まで徒歩(分)1 */
  bus_stop_walk_time_1: optionalNumber(),
  /** 最寄のバス停まで徒歩(m)1 */
  bus_stop_walk_kyori_1: optionalNumber(),
  /** 最寄まで車(分)1 */
  car_time_1: optionalNumber(),
  /** 最寄まで車(km)1 */
  car_kyori_1: optionalNumber(),
  /** 優先交通手段区分1 */
  yusen_kotsu_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.yusen_kotsu_kbn.Enum),
  ),
  /** 最寄駅沿線コード2 */
  ensen_cd_2: optionalString(),
  /** 最寄駅駅コード2 */
  eki_cd_2: optionalString(),
  /** 最寄まで徒歩(分)2 */
  walk_time_2: optionalNumber(),
  /** 最寄まで徒歩(m)2 */
  walk_kyori_2: optionalNumber(),
  /** 最寄バス路線名2 */
  bus_line_name_2: optionalString(),
  /** 最寄までバス(分)2 */
  bus_time_2: optionalNumber(),
  /** 最寄のバス停名称2 */
  bus_stop_name_2: optionalString(),
  /** 最寄のバス停まで徒歩(分)2 */
  bus_stop_walk_time_2: optionalNumber(),
  /** 最寄のバス停まで徒歩(m)2 */
  bus_stop_walk_kyori_2: optionalNumber(),
  /** 最寄まで車(分)2 */
  car_time_2: optionalNumber(),
  /** 最寄まで車(km)2 */
  car_kyori_2: optionalNumber(),
  /** 優先交通手段区分2 */
  yusen_kotsu_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.yusen_kotsu_kbn.Enum),
  ),
  /** 最寄駅沿線コード3 */
  ensen_cd_3: optionalString(),
  /** 最寄駅駅コード3 */
  eki_cd_3: optionalString(),
  /** 最寄まで徒歩(分)3 */
  walk_time_3: optionalNumber(),
  /** 最寄まで徒歩(m)3 */
  walk_kyori_3: optionalNumber(),
  /** 最寄バス路線名3 */
  bus_line_name_3: optionalString(),
  /** 最寄までバス(分)3 */
  bus_time_3: optionalNumber(),
  /** 最寄のバス停名称3 */
  bus_stop_name_3: optionalString(),
  /** 最寄のバス停まで徒歩(分)3 */
  bus_stop_walk_time_3: optionalNumber(),
  /** 最寄のバス停まで徒歩(m)3 */
  bus_stop_walk_kyori_3: optionalNumber(),
  /** 最寄まで車(分)3 */
  car_time_3: optionalNumber(),
  /** 最寄まで車(km)3 */
  car_kyori_3: optionalNumber(),
  /** 優先交通手段区分3 */
  yusen_kotsu_kbn_3: optionalStrictString(
    z.nativeEnum(miraieFields.yusen_kotsu_kbn.Enum),
  ),
  /** 最寄駅沿線コード4 */
  ensen_cd_4: optionalString(),
  /** 最寄駅駅コード4 */
  eki_cd_4: optionalString(),
  /** 最寄まで徒歩(分)4 */
  walk_time_4: optionalNumber(),
  /** 最寄まで徒歩(m)4 */
  walk_kyori_4: optionalNumber(),
  /** 最寄バス路線名4 */
  bus_line_name_4: optionalString(),
  /** 最寄までバス(分)4 */
  bus_time_4: optionalNumber(),
  /** 最寄のバス停名称4 */
  bus_stop_name_4: optionalString(),
  /** 最寄のバス停まで徒歩(分)4 */
  bus_stop_walk_time_4: optionalNumber(),
  /** 最寄のバス停まで徒歩(m)4 */
  bus_stop_walk_kyori_4: optionalNumber(),
  /** 最寄まで車(分)4 */
  car_time_4: optionalNumber(),
  /** 最寄まで車(km)4 */
  car_kyori_4: optionalNumber(),
  /** 優先交通手段区分4 */
  yusen_kotsu_kbn_4: optionalStrictString(
    z.nativeEnum(miraieFields.yusen_kotsu_kbn.Enum),
  ),
  // other_kotsu: z.any(),
  /** 土地権利区分 */
  tochi_pow_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tochi_pow_kbn.Enum),
  ),
  // tochi_shikichiken_kbn: z.any(),
  /** 土地借地権区分 */
  tochi_shakuchiken_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tochi_shakuchiken_kbn.Enum),
  ),
  // tochi_pow_biko: z.any(),
  /** 地役権区分 */
  chiekiken_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.chiekiken_kbn.Enum),
  ),
  /** 地役権面積 */
  chiekiken_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 土地面積区分 */
  tochi_menseki_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tochi_menseki_kbn.Enum),
  ),
  /** 土地面積 */
  tochi_menseki: optionalNumber(),
  /** 土地共有持分分子 */
  tochi_bunshi: optionalNumber(), // TODO DECIMAL 9 2
  /** 土地共有持分分母 */
  tochi_bunbo: optionalNumber(), // TODO DECIMAL 9 2
  // tochi_tani_kbn: z.any(),
  // shikichi_menseki_h: z.any(),
  /** 私道面積有フラグ */
  shido_menseki_flg: optionalStrictString(
    z.nativeEnum(miraieFields.shido_menseki_flg.Enum),
  ),
  /** 私道面積 */
  shido_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 私道面積基準区分 */
  shido_menseki_kijun_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shido_menseki_kijun_kbn.Enum),
  ),
  /** 私道面積持分分子 */
  shido_bunshi: optionalNumber(), // TODO DECIMAL 9 2
  /** 私道面積持分分母 */
  shido_bunbo: optionalNumber(), // TODO DECIMAL 9 2
  // shido_menseki_tani_kbn: z.any(),
  /** セットバック区分 */
  setbac_kbn: optionalStrictString(z.nativeEnum(miraieFields.setbac_kbn.Enum)),
  /** セットバック面積 */
  setbac_menseki_h: optionalNumber(), // TODO DECIMAL 9 2
  /** セットバック幅 */
  setbac_haba: optionalNumber(), // TODO DECIMAL 4 1
  /** 傾斜地部分面積 */
  keisha_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 傾斜地部分面積単位区分 */
  keisha_menseki_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.keisha_menseki_tani_kbn.Enum),
  ),
  /** 路地状部分面積 */
  rojijo_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 路地状部分面積単位区分 */
  rojijo_menseki_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.rojijo_menseki_tani_kbn.Enum),
  ),
  /** 高圧線下部分面積 */
  koatsusensita_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 再建築条件区分 */
  saikenchiku_joken_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.saikenchiku_joken_kbn.Enum),
  ),
  /** 再建築許可理由 */
  saikenchiku_kyoka_reason: optionalString(),
  /** 都市計画区分 */
  toshikei_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.toshikei_kbn.Enum),
  ),
  /** 用途地域区分1 */
  yoto_chiki_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.yoto_chiki_kbn.Enum),
  ),
  /** 用途地域区分2 */
  yoto_chiki_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.yoto_chiki_kbn.Enum),
  ),
  /** 土地最適用途区分 */
  saiteki_yoto_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.saiteki_yoto_kbn.Enum),
  ),
  /** 建ぺい率 */
  kenpei_ritu: optionalNumber(),
  /** 容積率 */
  yoseki_ritu: optionalNumber(),
  /** 容積率制限備考 */
  yoseki_ritu_biko: optionalString(),
  /** 法令制限等区分連結 */
  horei_kbn_renketsu: z
    .array(z.nativeEnum(miraieFields.horei_kbn_renketsu.Enum))
    .optional(),
  /** 法令に基づくその他制限事項 */
  horei_biko: optionalString(),
  /** 土地国土法区分 */
  kokudo_kbn: optionalStrictString(z.nativeEnum(miraieFields.kokudo_kbn.Enum)),
  /** 地目区分 */
  chimoku_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.chimoku_kbn.Enum),
  ),
  /** 地勢区分 */
  chisei_kbn: optionalStrictString(z.nativeEnum(miraieFields.chisei_kbn.Enum)),
  /** 開発許可番号 */
  kaihatukyoka_no: optionalString(),
  /** 建築許可理由区分 */
  kenchiku_kyoka_reason_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.kenchiku_kyoka_reason_kbn.Enum),
  ),
  /** 農地転用許可番号 */
  nochi_tenyo_kyoka_no: optionalString(),
  /** 接道区分 */
  setsudo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_kbn.Enum),
  ),
  /** 接道方角区分1 */
  setsudo_hogaku_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_hogaku_kbn.Enum),
  ),
  /** 接道幅員1 */
  setsudo_haba_1: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道公私区分1 */
  setsudo_koshi_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_koshi_kbn.Enum),
  ),
  /** 接道接面1 */
  setsudo_setsumen_1: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道位置指定有フラグ1 */
  setsudo_ichi_flg_1: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_ichi_flg.Enum),
  ),
  /** 接道方角区分2 */
  setsudo_hogaku_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_hogaku_kbn.Enum),
  ),
  /** 接道幅員2 */
  setsudo_haba_2: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道公私区分2 */
  setsudo_koshi_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_koshi_kbn.Enum),
  ),
  /** 接道接面2 */
  setsudo_setsumen_2: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道位置指定有フラグ2 */
  setsudo_ichi_flg_2: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_ichi_flg.Enum),
  ),
  /** 接道方角区分3 */
  setsudo_hogaku_kbn_3: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_hogaku_kbn.Enum),
  ),
  /** 接道幅員3 */
  setsudo_haba_3: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道公私区分3 */
  setsudo_koshi_kbn_3: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_koshi_kbn.Enum),
  ),
  /** 接道接面3 */
  setsudo_setsumen_3: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道位置指定有フラグ3 */
  setsudo_ichi_flg_3: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_ichi_flg.Enum),
  ),
  /** 接道方角区分4 */
  setsudo_hogaku_kbn_4: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_hogaku_kbn.Enum),
  ),
  /** 接道幅員4 */
  setsudo_haba_4: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道公私区分4 */
  setsudo_koshi_kbn_4: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_koshi_kbn.Enum),
  ),
  /** 接道接面4 */
  setsudo_setsumen_4: optionalNumber(), // TODO DECIMAL 4 1
  /** 接道位置指定有フラグ4 */
  setsudo_ichi_flg_4: optionalStrictString(
    z.nativeEnum(miraieFields.setsudo_ichi_flg.Enum),
  ),
  // setsudo_flg: z.any(),
  // zoseikoji_kanryoyotei_ym: z.any(),
  /** 借地期間区分 */
  shakuchi_kikan_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shakuchi_kikan_kbn.Enum),
  ),
  /** 定期借地契約満了日 */
  shakuchi_keiyaku_end_date: optionalString(),
  /** 借地期間年 */
  shakuchi_year: optionalNumber(),
  /** 借地期間ヶ月 */
  shakuchi_month: optionalNumber(),
  shakuchi_keiyakujiko: optionalString(),
  /** 借地権割合 */
  shakuchi_wariai_ritu: optionalNumber(),
  /** 土地権利_所有権借地権混在区分 */
  tochi_pow_mixed_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tochi_pow_mixed_kbn.Enum),
  ),
  /** 定借建物譲渡特約付フラグ */
  teishaku_jyoto_tokuyaku_flg: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_jyoto_tokuyaku_flg.Enum),
  ),
  /** 定借譲渡可否フラグ */
  teishaku_joto_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_joto_kbn.Enum),
  ),
  /** 定借譲渡承諾者区分 */
  teishaku_shodakusha_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_shodakusha_kbn.Enum),
  ),
  /** 定借譲渡承諾要区分 */
  teishaku_shodakuyo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_shodakuyo_kbn.Enum),
  ),
  /** 定借譲渡承諾料区分 */
  teishaku_shodakuprice_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_shodakuprice_kbn.Enum),
  ),
  /** 定借設定登記区分 */
  teishaku_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_kbn.Enum),
  ),
  /** 定借賃料改定年数 */
  teishaku_kaitei: optionalNumber(),
  /** 定借賃料改定額区分 */
  teishaku_kaitei_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_kaitei_kbn.Enum),
  ),
  /** 定借賃料改定時期区分 */
  teishaku_jiki_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_jiki_kbn.Enum),
  ),
  /** 地代月額 */
  chidai_month: optionalNumber(),
  /** 地代有無区分 */
  chidai_kbn: optionalStrictString(z.nativeEnum(miraieFields.chidai_kbn.Enum)),
  /** 借地用敷金 */
  shakuchi_shikikin: optionalNumber(), // TODO DECIMAL 13 2
  /** 借地用敷金単位区分 */
  shakuchi_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shakuchi_tani_kbn.Enum),
  ),
  /** 借地用保証金 */
  shakuchi_hoshokin: optionalNumber(), // TODO DECIMAL 13 2
  /** 借地用保証金単位区分 */
  shakuchi_hoshokin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shakuchi_hoshokin_tani_kbn.Enum),
  ),
  /** 借地用権利金 */
  shakuchi_kenrikin: optionalNumber(), // TODO DECIMAL 13 2
  /** 借地用権利金単位区分 */
  shakuchi_kenrikin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shakuchi_kenrikin_tani_kbn.Enum),
  ),
  /** 建物/物件名 */
  tatemono_name: optionalString(),
  /** 建物/物件名フリガナ */
  tatemono_name_kana: optionalString(),
  // tatemono_hyoji_flg: z.any(),
  /** 建物・物件名SUUMO */
  tatemono_name_suumo: optionalString(),
  /** 建物・物件名フリガナSUUMO */
  tatemono_name_kana_suumo: optionalString(),
  /** 建物・物件名HOME'S */
  tatemono_name_homes: optionalString(),
  /** 建物・物件名フリガナHOME'S */
  tatemono_name_kana_homes: optionalString(),
  /** 建物・物件名athome */
  tatemono_name_athome: optionalString(),
  /** 建物・物件名フリガナathome */
  tatemono_name_kana_athome: optionalString(),
  // tatemono_name_yahoo: z.any(),
  // tatemono_name_kana_yahoo: z.any(),
  /** 街区号棟/区画名 */
  partition_name: optionalString(),
  /** 地上階 */
  chijo_kai: optionalNumber(),
  /** 地下階 */
  chika_kai: optionalNumber(),
  /** 新築フラグ */
  shinchiku_flg: optionalStrictString(
    z.nativeEnum(miraieFields.shinchiku_flg.Enum),
  ),
  /** 総区画数(総戸数) */
  sokosu: optionalNumber(),
  /** 事務所戸数 */
  jimusho_kosu: optionalNumber(),
  /** 棟総戸数 */
  to_sokosu: optionalString(),
  /** 稼働戸数 */
  operation_kosu: optionalNumber(),
  /** 築年月日(年) */
  chiku_ymd_year: optionalString(),
  /** 築年月日(月) */
  chiku_ymd_month: optionalString(),
  // chiku_ymd_jun_kbn: z.any(),
  // chiku_ymd_fusho_kbn: z.any(),
  // kanse_jiki_biko: z.any(),
  /** 契約後入居待機予定月数 */
  keiyakugo_nyukyo_taiki_month: optionalNumber(),
  /** 契約後完成予定月数 */
  keiyakugo_kansei_month: optionalNumber(),
  /** 専有面積 */
  tatesen_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 専有面積基準区分 */
  tatesen_menseki_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tatesen_menseki_kbn.Enum),
  ),
  // tatesen_menseki_biko: z.any(),
  /** 建物延床面積 */
  taten_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 建物延床面積範囲区分 */
  taten_menseki_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.taten_menseki_hani_kbn.Enum),
  ),
  /** 最大建物延床面積 */
  taten_menseki_to: optionalNumber(),
  // taten_menseki_1f: z.any(),
  // taten_menseki_2f: z.any(),
  /** 建物延床面積基準区分 */
  nobeyuka_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.nobeyuka_kbn.Enum),
  ),
  // taten_menseki_biko: z.any(),
  /** 居住用途部分以外の用途区分 */
  kyoju_yoto_igai_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.kyoju_yoto_igai_kbn.Enum),
  ),
  /** うち居住用途以外その他説明 */
  kyoju_yoto_igai_other_setsumei: optionalString(),
  /** 建築面積 */
  kenchiku_menseki_h: optionalNumber(), // TODO DECIMAL 9 2
  /** 専用庭面積 */
  niwa_menseki: optionalNumber(),
  /** うち地下室面積 */
  chika_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** うち1F車庫面積 */
  shako_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** うち地下車庫面積 */
  chika_shako_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** うち居住用途以外面積 */
  kyoju_yoto_igai_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 建物プラン建物面積 */
  tatemono_plan_menseki: optionalNumber(), // TODO DECIMAL 9 2
  /** 建物プラン建物価格 */
  tatemono_plan_price: optionalNumber(),
  /** 建物構造区分 */
  tate_kozo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tate_kozo_kbn.Enum),
  ),
  /** 建物構造その他説明 */
  tate_kozo_other_setsumei: optionalString(),
  tate_ichibu_kozo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tate_ichibu_kozo_kbn.Enum),
  ),
  /** 建物一部構造その他説明 */
  tate_ichibu_kozo_other_setsumei: optionalString(),
  /** 建築工法区分 */
  tate_koho_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tate_koho_kbn.Enum),
  ),
  /** 施工会社 */
  seko_company: optionalString(),
  /** 施主1 */
  seshu_1: optionalString(),
  /** 施主2 */
  seshu_2: optionalString(),
  /** 設計会社 */
  design_company: optionalString(),
  /** 工法その他備考 */
  tate_koho_other_biko: optionalString(),
  /** 管理員 */
  manage_hoshiki_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.manage_hoshiki_kbn.Enum),
  ),
  /** 管理形態区分 */
  manage_keitai_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.manage_keitai_kbn.Enum),
  ),
  /** 管理会社名 */
  manage_company_name: optionalString(),
  /** 管理会社電話番号 */
  manage_company_tel: optionalString(),
  /** 管理組合有無 */
  manage_kumiai_umu_flg: optionalStrictString(
    z.nativeEnum(miraieFields.manage_kumiai_umu_flg.Enum),
  ),
  // jutakurirekijoho_flg: z.any(),
  /** 建築確認有フラグ */
  kenchikukakunin_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kenchikukakunin_flg.Enum),
  ),
  /** 建築確認番号 */
  kenchiku_no: optionalString(),
  /** 建物検査 */
  tatemonokensa_jisshi_flg: optionalStrictString(
    z.nativeEnum(miraieFields.tatemonokensa_jisshi_flg.Enum),
  ),
  tatemono_kensa_jisshi_date: optionalString(),
  // tate_kozo_other_biko: z.any(),
  /** 建物検査実施報告書有無フラグ */
  tatemono_kensa_jisshi_houkokusho_flg: optionalStrictString(
    z.nativeEnum(miraieFields.tatemono_kensa_jisshi_houkokusho_flg.Enum),
  ),
  // taishin_jisshi_flg: z.any(),
  // taishin_jisshi_ym: z.any(),
  /** 瑕疵保証_国交省指定_による保証__利用可否 */
  kashi_hosho_k_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_k_kbn.Enum),
  ),
  /** 瑕疵保険_国交省指定_による保証_保険期間_年_ */
  kashi_hosho_k_years: optionalNumber(),
  /** 瑕疵保険_国交省指定_による保証_保険期間_ヶ月_ */
  kashi_hosho_k_months: optionalNumber(),
  /** 瑕疵保険_国交省指定_による保証_保証範囲有無フラグ_構造躯体_ */
  kashi_hosho_k_kouzou_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_k_kouzou_flg.Enum),
  ),
  /** 瑕疵保険_国交省指定_による保証_保証範囲有無フラグ_雨漏り_ */
  kashi_hosho_k_amamori_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_k_amamori_flg.Enum),
  ),
  /** 瑕疵保険_国交省指定_による保証_保証範囲有無フラグ_給排水管路_ */
  kashi_hosho_k_kyuhaikan_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_k_kyuhaikan_flg.Enum),
  ),
  /** 瑕疵保険_国交省指定_による保証_保証範囲有無フラグ_シロアリ_ */
  kashi_hosho_k_shiroari_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_k_shiroari_flg.Enum),
  ),
  /** 瑕疵保険_国交省指定_による保証_保証範囲有無フラグ_その他_ */
  kashi_hosho_k_other_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_k_other_flg.Enum),
  ),
  /** 瑕疵保険_国交省指定_による保証_保証範囲詳細 */
  kashi_hosho_k_other_naiyo: optionalString(),
  /** 瑕疵保証_国交省指定_による保証_建物検査実施日年月日 */
  kashi_hosho_k_kensa_jisshi_date: optionalString(),
  /** 瑕疵保険_国交省指定_による保証_買主保険料負担有無フラグ */
  kashi_hosho_k_hokenryo_buyer_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_k_hokenryo_buyer_flg.Enum),
  ),
  /** 瑕疵保証_不動産会社独自__利用可否 */
  kashi_hosho_f_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_f_kbn.Enum),
  ),
  /** 瑕疵保証_不動産会社独自__保証期間_年_ */
  kashi_hosho_f_years: optionalNumber(),
  /** 瑕疵保証_不動産会社独自__保証期間_ヶ月_ */
  kashi_hosho_f_months: optionalNumber(),
  /** 瑕疵保証_不動産会社独自__保証範囲有無フラグ_建物_ */
  kashi_hosho_f_tatemono_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_f_tatemono_flg.Enum),
  ),
  /** 瑕疵保証_不動産会社独自__保証範囲有無フラグ_土地_ */
  kashi_hosho_f_tochi_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_f_tochi_flg.Enum),
  ),
  /** 瑕疵保証_不動産会社独自__保証範囲有無フラグ_その他_ */
  kashi_hosho_f_other_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_f_other_flg.Enum),
  ),
  /** 瑕疵保証_不動産会社独自__保証範囲詳細 */
  kashi_hosho_f_other_naiyo: optionalString(),
  /** 瑕疵保証_不動産会社独自__建物検査実施日年月日 */
  kashi_hosho_f_kensa_jisshi_date: optionalString(),
  /** 瑕疵保証_不動産会社独自__買主保証料負担有無フラグ */
  kashi_hosho_f_hokenryo_buyer_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kashi_hosho_f_hokenryo_buyer_flg.Enum),
  ),
  /** 建物状況調査_買主検査料負担有無フラグ */
  tatemono_kensa_chosaryo_buyer_flg: optionalStrictString(
    z.nativeEnum(miraieFields.tatemono_kensa_chosaryo_buyer_flg.Enum),
  ),
  /** 長期優良住宅認定通知書有無フラグ */
  choki_yuryo_nintei_flg: optionalStrictString(
    z.nativeEnum(miraieFields.choki_yuryo_nintei_flg.Enum),
  ),
  /** 長期優良住宅認定通知書_建物検査実施日 */
  choki_yuryo_nintei_kensa_jisshi_date: optionalString(),
  /** 長期優良住宅認定通知書_増改築有無フラグ */
  choki_yuryo_nintei_zokaichiku_flg: optionalStrictString(
    z.nativeEnum(miraieFields.choki_yuryo_nintei_zokaichiku_flg.Enum),
  ),
  /** 長期優良住宅認定通知書_増改築実施日 */
  choki_yuryo_nintei_zokaichiku_jisshi_date: optionalString(),
  /** 長期優良住宅認定通知書_増改築箇所等 */
  choki_yuryo_nintei_zokaichiku_naiyo: optionalString(),
  /** フラット35適合証明書有無フラグ */
  flat35_shomeisho_flg: optionalStrictString(
    z.nativeEnum(miraieFields.flat35_shomeisho_flg.Enum),
  ),
  /** フラット35適合証明書_建物検査実施日 */
  flat35_shomeisho_tatemono_kensa_jisshi_date: optionalString(),
  /** フラット35Ｓ適合証明書有無フラグ */
  flat35_s_shomeisho_flg: optionalStrictString(
    z.nativeEnum(miraieFields.flat35_s_shomeisho_flg.Enum),
  ),
  /** フラット35Ｓ適合証明書_建物検査実施日 */
  flat35_s_shomeisho_tatemono_kensa_jisshi_date: optionalString(),
  /** 耐震基準適合証明書有無フラグ */
  taishin_shomeisho_flg: optionalStrictString(
    z.nativeEnum(miraieFields.taishin_shomeisho_flg.Enum),
  ),
  /** 耐震基準適合証明書_建物検査実施日 */
  taishin_shomeisho_tatemono_kensa_jisshi_date: optionalString(),
  /** 法適合状況調査報告書有無フラグ */
  hou_tekigo_houkokusho_flg: optionalStrictString(
    z.nativeEnum(miraieFields.hou_tekigo_houkokusho_flg.Enum),
  ),
  /** 法適合状況調査報告書_建物検査実施日 */
  hou_tekigo_houkokusho_kensa_jisshi_date: optionalString(),
  /** 建築士等の建物検査報告書有無フラグ */
  kenchikushi_shomeisho_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kenchikushi_shomeisho_flg.Enum),
  ),
  /** 建築士等の建物検査報告書_建物検査実施日 */
  kenchikushi_shomeisho_tatemono_kensa_jisshi_date: optionalString(),
  /** 1Fの施設_環境 */
  shisetsu_kankyo: optionalString(),
  /** 所在階 */
  shozai_kai: optionalNumber(),
  /** 所在階区分 */
  shozai_kai_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shozai_kai_kbn.Enum),
  ),
  /** メゾネット階数From */
  maisonette_kai_from: optionalNumber(),
  /** メゾネット階数From階区分 */
  maisonette_kai_from_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.maisonette_kai_from_kbn.Enum),
  ),
  /** メゾネット階数To */
  maisonette_kai_to: optionalNumber(),
  /** メゾネット階数To階区分 */
  maisonette_kai_to_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.maisonette_kai_to_kbn.Enum),
  ),
  /** 部屋番号 */
  room_no: optionalString(),
  room_no_show_flg: optionalStrictString(
    z.nativeEnum(miraieFields.room_no_show_flg.Enum),
  ),
  /** 間取部屋数 */
  madori_room_su: optionalNumber(),
  /** 間取区分 */
  madori_kbn: optionalStrictString(z.nativeEnum(miraieFields.madori_kbn.Enum)),
  /** 間取部屋数SUUMO */
  madori_suumo_room_su: optionalNumber(),
  /** 間取区分SUUMO */
  madori_suumo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.madori_suumo_kbn.Enum),
  ),
  /** 間取タイプS区分 */
  madori_types_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.madori_types_kbn.Enum),
  ),
  /** 間取範囲上限区分SUUMO */
  madori_suumo_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.madori_suumo_hani_kbn.Enum),
  ),
  /** 間取部屋数上限SUUMO */
  madori_suumo_room_su_to: optionalNumber(),
  /** 間取区分上限SUUMO */
  madori_suumo_to_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.madori_suumo_kbn.Enum),
  ),
  /** 間取タイプS上限SUUMO */
  madori_suumo_types_to_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.madori_types_kbn.Enum),
  ),
  /** 鍵保管場所区分 */
  key_hokan_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.key_hokan_kbn.Enum),
  ),
  /** 鍵保管預託先会社名 */
  key_hokan_yotakusaki: optionalString(),
  /** 鍵備考 */
  key_biko: optionalString(),
  /** 間取備考 */
  madori_biko: optionalString(),
  /** 部屋タイプ区分1 */
  room_type_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数1 */
  room_josu_1: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階1 */
  room_shozaikai_1: optionalNumber(),
  /** 部屋所在階区分1 */
  room_shozaikai_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分2 */
  room_type_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数2 */
  room_josu_2: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階2 */
  room_shozaikai_2: optionalNumber(),
  /** 部屋所在階区分2 */
  room_shozaikai_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分3 */
  room_type_kbn_3: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数3 */
  room_josu_3: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階3 */
  room_shozaikai_3: optionalNumber(),
  /** 部屋所在階区分3 */
  room_shozaikai_kbn_3: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分4 */
  room_type_kbn_4: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数4 */
  room_josu_4: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階4 */
  room_shozaikai_4: optionalNumber(),
  /** 部屋所在階区分4 */
  room_shozaikai_kbn_4: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分5 */
  room_type_kbn_5: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数5 */
  room_josu_5: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階5 */
  room_shozaikai_5: optionalNumber(),
  /** 部屋所在階区分5 */
  room_shozaikai_kbn_5: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分6 */
  room_type_kbn_6: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数6 */
  room_josu_6: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階6 */
  room_shozaikai_6: optionalNumber(),
  /** 部屋所在階区分6 */
  room_shozaikai_kbn_6: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分7 */
  room_type_kbn_7: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数7 */
  room_josu_7: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階7 */
  room_shozaikai_7: optionalNumber(),
  /** 部屋所在階区分7 */
  room_shozaikai_kbn_7: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分8 */
  room_type_kbn_8: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数8 */
  room_josu_8: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階8 */
  room_shozaikai_8: optionalNumber(),
  /** 部屋所在階区分8 */
  room_shozaikai_kbn_8: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分9 */
  room_type_kbn_9: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数9 */
  room_josu_9: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階9 */
  room_shozaikai_9: optionalNumber(),
  /** 部屋所在階区分9 */
  room_shozaikai_kbn_9: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 部屋タイプ区分10 */
  room_type_kbn_10: optionalStrictString(
    z.nativeEnum(miraieFields.room_type_kbn.Enum),
  ),
  /** 部屋帖数10 */
  room_josu_10: optionalNumber(), // TODO DECIMAL 4 1
  /** 部屋所在階10 */
  room_shozaikai_10: optionalNumber(),
  /** 部屋所在階区分10 */
  room_shozaikai_kbn_10: optionalStrictString(
    z.nativeEnum(miraieFields.room_shozaikai_kbn.Enum),
  ),
  /** 主要採光面区分 */
  shuyo_saikomen_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shuyo_saikomen_kbn.Enum),
  ),
  /** バルコニー有フラグ */
  bal_flg: optionalStrictString(z.nativeEnum(miraieFields.bal_flg.Enum)),
  /** バルコニー方向区分 */
  bal_hoko_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.bal_hoko_kbn.Enum),
  ),
  /** バルコニー面積 */
  bal_menseki: optionalNumber(),
  /** テラス面積 */
  tera_menseki: optionalNumber(),
  /** ルーフバルコニー面積 */
  roofbal_menseki: optionalNumber(),
  /** ルーフバルコニー使用料 */
  roofbal_price: optionalNumber(),
  // jigyo_yoto_kbn: z.any(),
  // jigyo_yoto_other_biko: z.any(),
  // jigyo_hikiwatashi_kbn: z.any(),
  ikkatsu_flg: optionalStrictString(
    z.nativeEnum(miraieFields.ikkatsu_flg.Enum),
  ),
  /** 分譲区画数/戸数 */
  ikkatsu_bunjo_kukakusu: optionalNumber(),
  /** 分譲管理費最高 */
  ikkatsu_mansion_kanrihi_to: optionalNumber(),
  /** 分譲積立金最高 */
  ikkatsu_mansion_tsumitatekin_to: optionalNumber(),
  /** 分譲積立金最低 */
  ikkatsu_mansion_tsumitatekin_from: optionalNumber(),
  /** 分譲管理費最低 */
  ikkatsu_mansion_kanrihi_from: optionalNumber(),
  // ikkatsu_saita_price: z.any(),
  // ikkatsu_saita_price_kukakusu: z.any(),
  // ikkatsu_kaihatsu_menseki: z.any(),
  // ikkatsu_t_yushibunjo_flg: z.any(),
  // ikkatsu_hanbai_menseki: z.any(),
  // ikkatsu_kakaku_kukakusu_from: z.any(),
  // ikkatsu_kakaku_kukakusu_to: z.any(),
  /** 分譲販売価格帯最高 */
  ikkatsu_hanbai_price_to: optionalNumber(),
  /** 分譲販売価格帯最低 */
  ikkatsu_hanbai_price_from: optionalNumber(),
  // ikkatsu_hanbai_kukakusu: z.any(),
  /** 分譲販売専有/建物面積帯最高 */
  ikkatsu_hanbai_menseki_to: optionalNumber(), // TODO DECIMAL 9 2
  /** 分譲販売専有/建物面積帯最低 */
  ikkatsu_hanbai_menseki_from: optionalNumber(), // TODO DECIMAL 9 2
  /** 分譲販売土地面積最低 */
  ikkatsu_hanbai_tochi_from: optionalNumber(), // TODO DECIMAL 9 2
  /** 分譲販売土地面積最高 */
  ikkatsu_hanbai_tochi_to: optionalNumber(), // TODO DECIMAL 9 2
  /** リフォーム */
  reform_flg: optionalStrictString(z.nativeEnum(miraieFields.reform_flg.Enum)),
  /** キッチンリフォーム済みフラグ */
  reform_kitchen_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_kitchen_flg.Enum),
  ),
  /** 浴室リフォーム済みフラグ */
  reform_yokusitsu_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_yokusitsu_flg.Enum),
  ),
  /** トイレリフォーム済みフラグ */
  reform_toire_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_toire_flg.Enum),
  ),
  /** 洗面所リフォーム済みフラグ */
  reform_washroom_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_washroom_flg.Enum),
  ),
  /** 給排水道管リフォーム済みフラグ */
  reform_water_pipe_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_water_pipe_flg.Enum),
  ),
  /** 給湯器リフォーム済みフラグ */
  reform_water_heater_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_water_heater_flg.Enum),
  ),
  /** その他水回りリフォーム済みフラグ */
  reform_mizumawari_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_mizumawari_flg.Enum),
  ),
  /** その他水回りリフォーム箇所備考 */
  reform_mizumawari_biko: optionalString(),
  /** 水回りリフォーム年月(年) */
  reform_mizumawari_ym_year: optionalString(),
  /** 水回りリフォーム年月(月) */
  reform_mizumawari_ym_month: optionalString(),
  /** 水回りリフォーム実施内容 */
  reform_mizumawari_enforcement_content: optionalString(),
  /** 内装全面リフォーム済みフラグ */
  reform_naiso_all_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_naiso_all_flg.Enum),
  ),
  /** 壁・天井リフォーム済フラグ */
  reform_kabe_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_kabe_flg.Enum),
  ),
  /** 床リフォーム済フラグ */
  reform_yuka_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_yuka_flg.Enum),
  ),
  /** 建具リフォーム済フラグ */
  reform_fixture_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_fixture_flg.Enum),
  ),
  /** サッシリフォーム済フラグ */
  reform_sash_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_sash_flg.Enum),
  ),
  /** 全室クロス張替えリフォーム済フラグ */
  reform_zensitsu_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_zensitsu_flg.Enum),
  ),
  /** 全室クロス張替えリフォーム済フラグ 賃貸用 */
  reform_cloth_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_cloth_flg.Enum),
  ),
  /** 内装全面リフォーム済フラグ 賃貸用 */
  reform_naiso_zenmen_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_naiso_zenmen_flg.Enum),
  ),
  /** その他内装リフォーム済フラグ */
  reform_naiso_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_naiso_flg.Enum),
  ),
  /** その他内装リフォーム箇所備考 */
  reform_naiso_biko: optionalString(),
  /** 内装リフォーム年月(年) */
  reform_naiso_ym_year: optionalString(),
  /** 内装リフォーム年月(月) */
  reform_naiso_ym_month: optionalString(),
  /** 内装リフォーム実施内容 */
  reform_naiso_enforcement_content: optionalString(),
  /** 外壁リフォーム済フラグ */
  reform_gaiheki_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_gaiheki_flg.Enum),
  ),
  /** 屋根リフォーム済フラグ */
  reform_yane_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_yane_flg.Enum),
  ),
  /** その他外装リフォーム済フラグ */
  reform_gaiso_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_gaiso_flg.Enum),
  ),
  /** 外装リフォーム大規模修繕済フラグ */
  reform_daikibo_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_daikibo_flg.Enum),
  ),
  /** その他外装リフォーム箇所備考 */
  reform_gaiso_biko: optionalString(),
  /** 外装リフォーム年月(年) */
  reform_gaiso_ym_year: optionalString(),
  /** 外装リフォーム年月(月) */
  reform_gaiso_ym_month: optionalString(),
  /** 外装リフォーム実施内容 */
  reform_gaiso_enforcement_content: optionalString(),
  /** 共用部リフォーム年月(年) */
  reform_common_ym_year: optionalString(),
  /** 共用部リフォーム年月(月) */
  reform_common_ym_month: optionalString(),
  /** 共用部リフォーム実施内容 */
  reform_common_enforcement_content: optionalString(),
  /** その他リフォーム年月(年) */
  reform_other_ym_year: optionalString(),
  /** その他リフォーム年月(月) */
  reform_other_ym_month: optionalString(),
  /** その他リフォーム実施内容 */
  reform_other_enforcement_content: optionalString(),
  /** リフォーム見積もり */
  reform_mitsumori_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_mitsumori_flg.Enum),
  ), // FIXME: 区分値が未記載のため内容が同じもので代用
  /** リフォーム見積もり内容 */
  reform_mitsumori_biko: optionalString(),
  // reform_seko_company: z.any(),
  // reform_biko: z.any(),
  /** リノベーション区分 */
  renovation_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.renovation_kbn.Enum),
  ),
  /** リノベーション年月(年) */
  renovation_ym_year: optionalString(),
  /** リノベーション年月(月) */
  renovation_ym_month: optionalString(),
  /** リノベーション内容 */
  renovation_naiyo: optionalString(),
  /** リフォーム/リノベーション可能箇所有無フラグ */
  reform_renovation_kanou_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可フラグ(水回りの位置変更) */
  reform_renovation_kanou_mizumawari_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_mizumawari_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可フラグ(キッチン) */
  reform_renovation_kanou_kitchen_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_kitchen_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可フラグ(浴室) */
  reform_renovation_kanou_yokusitsu_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_yokusitsu_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可フラグ(トイレ) */
  reform_renovation_kanou_toire_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_toire_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可フラグ(洗面所) */
  reform_renovation_kanou_washroom_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_washroom_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可フラグ(給排水管) */
  reform_renovation_kanou_water_pipe_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_water_pipe_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可フラグ(給湯器) */
  reform_renovation_kanou_water_heater_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_water_heater_flg.Enum),
  ),
  /** リフォーム/リノベーション水回り対応可テキスト */
  reform_renovation_kanou_mizumawari_naiyo: optionalString(),
  /** リフォーム/リノベーション内装対応可フラグ(内装全面(床・壁・天井・建具)) */
  reform_renovation_kanou_naiso_all_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_naiso_all_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可フラグ(間取り変更・スケルトン) */
  reform_renovation_kanou_madori_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_madori_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可フラグ(全室クロス張替え) */
  reform_renovation_zensitsu_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_zensitsu_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可フラグ(床(フローリング等)) */
  reform_renovation_kanou_yuka_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_yuka_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可フラグ(壁・天井(クロス・塗装等)) */
  reform_renovation_kanou_kabe_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_kabe_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可フラグ(建具(室内ドア等)) */
  reform_renovation_kanou_fixture_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_fixture_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可フラグ(サッシ) */
  reform_renovation_kanou_sash_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_sash_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可フラグ(収納) */
  reform_renovation_kanou_shuno_flg: optionalStrictString(
    z.nativeEnum(miraieFields.reform_renovation_kanou_shuno_flg.Enum),
  ),
  /** リフォーム/リノベーション内装対応可テキスト */
  reform_renovation_kanou_naiso_naiyo: optionalString(),
  /** リフォーム/リノベーションその他対応可テキスト */
  reform_renovation_kanou_other_naiyo: optionalString(),
  /** 価格 */
  price: optionalNumber(),
  /** 価格公開フラグ */
  price_kokai_flg: optionalStrictString(
    z.nativeEnum(miraieFields.price_kokai_flg.Enum),
  ),
  /** 価格状態区分 */
  price_state_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.price_state_kbn.Enum),
  ),
  // price_biko: z.any(),
  // price_date: z.any(),
  // old_price: z.any(),
  // old_price_date: z.any(),
  // tsubo_tanka: z.any(),
  /** 管理費 */
  kanrihi: optionalNumber(),
  /** 修繕積立基金 */
  tsumitatekikin: optionalNumber(),
  /** 修繕積立金 */
  tsumitatekin: optionalNumber(),
  /** 専用庭使用料 */
  niwa_price: optionalNumber(),
  /** テラス使用料 */
  tera_price: optionalNumber(),
  /** 町会費 */
  chokaihi_price: optionalNumber(),
  /** 町会費区分 */
  chokaihi_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.chokaihi_kbn.Enum),
  ),
  /** 町会費有無区分 */
  chokaihi_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.chokaihi_umu_kbn.Enum),
  ),
  /** インターネット定額料金 */
  internet_price: optionalNumber(),
  /** インターネット定額料金有無区分 */
  internet_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.internet_umu_kbn.Enum),
  ),
  /** インターネット初期費用 */
  internet_initprice: optionalNumber(),
  /** インターネット初期費用有無区分 */
  internet_initprice_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.internet_initprice_umu_kbn.Enum),
  ),
  /** 有線放送初期費用 */
  yusen_initprice: optionalNumber(),
  /** 有線放送初期費用有無区分 */
  yusen_initprice_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.yusen_initprice_umu_kbn.Enum),
  ),
  /** 有線放送定額料金 */
  yusen_price: optionalNumber(),
  /** 有線放送定額料金有無区分 */
  yusen_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.yusen_umu_kbn.Enum),
  ),
  /** CATV初期費用 */
  catv_initprice: optionalNumber(),
  /** CATV初期費用有無区分 */
  catv_initprice_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.catv_initprice_umu_kbn.Enum),
  ),
  /** CATV定額料金 */
  catv_amount_price: optionalNumber(),
  /** CATV定額料金有無区分 */
  catv_amount_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.catv_amount_umu_kbn.Enum),
  ),
  /** 温泉有無区分 */
  onsen_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_umu_kbn.Enum),
  ),
  /** 温泉料金 */
  onsen_price: optionalNumber(),
  /** 温泉料金区分 */
  onsen_price_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_price_kbn.Enum),
  ),
  /** 温泉料金単位区分 */
  onsen_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_tani_kbn.Enum),
  ),
  /** 温泉かけ流し有フラグ */
  onsen_kakenagashi_flg: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_kakenagashi_flg.Enum),
  ),
  /** 温泉加温有フラグ */
  onsen_kaon_flg: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_kaon_flg.Enum),
  ),
  /** 温泉加水有フラグ */
  onsen_kasui_flg: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_kasui_flg.Enum),
  ),
  /** 温泉運湯有フラグ */
  onsen_hakobiyu_flg: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_hakobiyu_flg.Enum),
  ),
  /** 温泉循環装置使用フラグ */
  onsen_junkan_flg: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_junkan_flg.Enum),
  ),
  /** 温泉循環ろ過装置使用フラグ */
  onsen_roka_flg: optionalStrictString(
    z.nativeEnum(miraieFields.onsen_roka_flg.Enum),
  ),
  /** 一時金名目1 */
  ichijikin_name_1: optionalString(),
  /** 一時金金額1 */
  ichijikin_price_1: optionalNumber(),
  /** 一時金名目2 */
  ichijikin_name_2: optionalString(),
  /** 一時金金額2 */
  ichijikin_price_2: optionalNumber(),
  /** 一時金名目3 */
  ichijikin_name_3: optionalString(),
  /** 一時金金額3 */
  ichijikin_price_3: optionalNumber(),
  /** 一時金名目4 */
  ichijikin_name_4: optionalString(),
  /** 一時金金額4 */
  ichijikin_price_4: optionalNumber(),
  /** 一時金名目5 */
  ichijikin_name_5: optionalString(),
  /** 一時金金額5 */
  ichijikin_price_5: optionalNumber(),
  /** ランニングコスト区分1 */
  running_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.running_kbn.Enum),
  ),
  /** ランニングコスト名目1 */
  running_name_1: optionalString(),
  /** ランニングコスト金額1 */
  running_price_1: optionalNumber(),
  /** ランニングコスト区分2 */
  running_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.running_kbn.Enum),
  ),
  /** ランニングコスト名目2 */
  running_name_2: optionalString(),
  /** ランニングコスト金額2 */
  running_price_2: optionalNumber(),
  /** ランニングコスト区分3 */
  running_kbn_3: optionalStrictString(
    z.nativeEnum(miraieFields.running_kbn.Enum),
  ),
  /** ランニングコスト名目3 */
  running_name_3: optionalString(),
  /** ランニングコスト金額3 */
  running_price_3: optionalNumber(),
  /** ランニングコスト区分4 */
  running_kbn_4: optionalStrictString(
    z.nativeEnum(miraieFields.running_kbn.Enum),
  ),
  /** ランニングコスト名目4 */
  running_name_4: optionalString(),
  /** ランニングコスト金額4 */
  running_price_4: optionalNumber(),
  /** ランニングコスト区分5 */
  running_kbn_5: optionalStrictString(
    z.nativeEnum(miraieFields.running_kbn.Enum),
  ),
  /** ランニングコスト名目5 */
  running_name_5: optionalString(),
  /** ランニングコスト金額5 */
  running_price_5: optionalNumber(),
  toshi_flg: optionalStrictString(z.nativeEnum(miraieFields.toshi_flg.Enum)),
  /** オーナーチェンジ有フラグ */
  owner_change_flg: optionalStrictString(
    z.nativeEnum(miraieFields.owner_change_flg.Enum),
  ),
  /** 現行利回 */
  genko_rimawari: optionalNumber(),
  /** 想定利回 */
  sote_rimawari: optionalNumber(),
  /** 満室時表面利回 */
  full_rimawari: optionalNumber(),
  /** athome想定利回 */
  athome_sote_rimawari: optionalNumber(),
  /** 現行収入 */
  genko_income: optionalNumber(),
  /** 想定収入 */
  sote_income: optionalNumber(),
  /** athome想定収入 */
  athome_sote_income: optionalNumber(),
  /** 調査日 */
  research_date: optionalString(),
  // toshi_shunyu_biko: z.any(),
  /** 取引態様区分 */
  torihiki_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.torihiki_kbn.Enum),
  ),
  /** 取引態様補足備考 */
  torihiki_hosoku_biko: optionalString(),
  /** 先物物件フラグ */
  sakimono_flg: optionalStrictString(
    z.nativeEnum(miraieFields.sakimono_flg.Enum),
  ),
  /** 一般消費者向仲介手数料_円_ */
  user_tesuryo_price: optionalNumber(),
  /** 一般消費者向仲介手数料_率_ */
  user_tesuryo_ritsu: optionalNumber(), // TODO DECIMAL 5 2
  /** 一般消費者向仲介手数料_ヶ月_ */
  user_tesuryo_month: optionalNumber(),
  /** 貸主率 */
  lender_ritsu: optionalNumber(), // TODO DECIMAL 3
  /** 借主率 */
  kari_ritsu: optionalNumber(), // TODO DECIMAL 3
  /** 元付率 */
  moto_ritsu: optionalNumber(), // TODO DECIMAL 3
  /** 客付率 */
  kyaku_ritsu: optionalNumber(), // TODO DECIMAL 3
  /** 物件確認日 */
  bk_kakunin_date: optionalString(),
  /** 物件確認書送信日 */
  bk_kakuninsho_send_date: optionalString(),
  /** 物件確認広告掲載確認日 */
  bk_kokoku_kakunin_date: optionalString(),
  // mototsuke_torihiki_kbn: z.any(),
  // mototsuke_gyousha_id: z.any(),
  /** 元付担当者名 */
  mototsuke_tanto_name: optionalString(),
  /** 元付業者名 */
  mototsuke_gyousha_name: optionalString(),
  /** 元付カナ */
  mototsuke_gyousha_name_kana: optionalString(),
  /** 元付電話番号 */
  mototsuke_gyousha_tel: optionalString(),
  /** 広告費有フラグ */
  kokokuhi_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kokokuhi_flg.Enum),
  ),
  // kokokuhi_kind_kbn: z.any(),
  /** 広告費上限金 */
  kokokuhi_to: optionalNumber(), // TODO DECIMAL 13 2
  /** 広告費上限金単位区分 */
  kokokuhi_to_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.kokokuhi_to_tani_kbn.Enum),
  ),
  // bk_kokoku_keisai_kbn: z.any(),
  /** 客付可フラグ */
  kyakutsuke_pass_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kyakutsuke_pass_flg.Enum),
  ),
  kyakutsuke_tesuryo_price: optionalNumber(),
  kyakutsuke_tesuryo_ritsu: optionalNumber(),
  customer_tesuryo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.customer_tesuryo_kbn.Enum),
  ),
  // kyakutsuke_company_kokoku_kbn: z.any(),
  // seller_id: z.any(),
  /** 現況区分 */
  genkyo_kbn: optionalStrictString(z.nativeEnum(miraieFields.genkyo_kbn.Enum)),
  /** 建築条件有フラグ */
  kenchiku_joken_flg: optionalStrictString(
    z.nativeEnum(miraieFields.kenchiku_joken_flg.Enum),
  ),
  /** 引渡条件区分 */
  hikiwatashi_joken_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.hikiwatashi_joken_kbn.Enum),
  ),
  /** 引渡区分 */
  hikiwatashi_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.hikiwatashi_kbn.Enum),
  ),
  /** 引渡年月日(年) */
  hikiwatashi_ymd_year: optionalString(),
  /** 引渡年月日(月) */
  hikiwatashi_ymd_month: optionalString(),
  /** 引渡年月日旬区分 */
  hikiwatashi_ymd_jun_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.hikiwatashi_ymd_jun_kbn.Enum),
  ),
  // hikiwatashi_ymd_fusho_kbn: z.any(),
  /** 引渡/入居時期相談内容 */
  hikiwatashi_biko: optionalString(),
  setsubi_kbn_renketsu: z.array(z.string()).optional(),
  // setsubi_biko: z.any(),
  /** おすすめピックアップピクト指定1 */
  osusume_pickup_1: optionalString(),
  /** おすすめピックアップピクト指定2 */
  osusume_pickup_2: optionalString(),
  /** おすすめピックアップピクト指定3 */
  osusume_pickup_3: optionalString(),
  /** 駐車場状況区分 */
  parking_state_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_state_kbn.Enum),
  ),
  /** 専用使用権付フラグ */
  parking_senyo_flg: optionalStrictString(
    z.nativeEnum(miraieFields.parking_senyo_flg.Enum),
  ),
  /** 駐車場分譲区分 */
  parking_sell_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_sell_kbn.Enum),
  ),
  /** 駐車場空台数 */
  parking_daisu: optionalNumber(),
  /** 近隣駐車場距離 */
  parking_kyori: optionalNumber(),
  /** 駐車場タイプ区分 */
  parking_type_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_type_kbn.Enum),
  ),
  /** 駐車場構造区分 */
  parking_kozo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_kozo_kbn.Enum),
  ),
  /** 駐車場有料フラグ */
  parking_yuryo_flg: optionalStrictString(
    z.nativeEnum(miraieFields.parking_yuryo_flg.Enum),
  ),
  /** 駐車場契約必須区分 */
  parking_keiyaku_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_keiyaku_kbn.Enum),
  ),
  /** 分譲駐車場価格From */
  parking_from_price: optionalNumber(),
  /** 分譲駐車場価格To */
  parking_to_price: optionalNumber(),
  // parking_price_zei_kbn: z.any(),
  /** 駐車場料金FROM */
  parking_ryokin: optionalNumber(),
  /** 駐車場料金TO */
  parking_to_ryokin: optionalNumber(),
  /** 駐車場料金範囲区分 */
  parking_ryokin_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_ryokin_hani_kbn.Enum),
  ),
  /** 駐車場敷金 */
  parking_shikikin: optionalNumber(),
  /** 駐車場敷金単位区分 */
  parking_shikikin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_shikikin_tani_kbn.Enum),
  ),
  /** 駐車場礼金 */
  parking_reikin: optionalNumber(),
  /** 駐車場礼金単位区分 */
  parking_reikin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_reikin_tani_kbn.Enum),
  ),
  /** バイク置場有無区分 */
  bikeokiba_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.bikeokiba_umu_kbn.Enum),
  ),
  /** バイク置場料金 */
  bikeokiba_price: optionalNumber(),
  /** バイク置場料金区分 */
  bikeokiba_price_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.bikeokiba_price_kbn.Enum),
  ),
  /** バイク置場有料フラグ */
  bikeokiba_yuryo_flg: optionalStrictString(
    z.nativeEnum(miraieFields.bikeokiba_yuryo_flg.Enum),
  ),
  /** バイク置場サイズ区分 */
  bikeokiba_size_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.bikeokiba_size_kbn.Enum),
  ),
  /** 駐輪場有無区分 */
  churinjo_umu_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.churinjo_umu_kbn.Enum),
  ),
  /** 駐輪場有料フラグ */
  churinjo_yuryo_flg: optionalStrictString(
    z.nativeEnum(miraieFields.churinjo_yuryo_flg.Enum),
  ),
  /** 駐輪場料金 */
  churinjo_price: optionalNumber(),
  /** 駐輪場料金区分 */
  churinjo_price_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.churinjo_price_kbn.Enum),
  ),
  /** 分譲駐車場価格範囲区分 */
  parking_bunjo_price_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_bunjo_price_hani_kbn.Enum),
  ),
  /** 分譲駐車場管理費 */
  parking_kanrihi: optionalNumber(),
  /** 分譲駐車場管理費単位区分 */
  parking_kanrihi_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_kanrihi_tani_kbn.Enum),
  ),
  /** 分譲駐車場修繕積立金 */
  parking_tsumitatekin: optionalNumber(),
  /** 分譲駐車場修繕積立基金 */
  parking_tsumitatekikin: optionalNumber(),
  /** 分譲駐車場修繕積立金単位区分 */
  parking_tsumitatekin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_tsumitatekin_tani_kbn.Enum),
  ),
  // tokki_kbn_renketsu: z.any(),
  /** ペット備考 */
  pet_biko: optionalString(),
  /** 小学校区 */
  shogakku: optionalString(),
  /** 中学校区 */
  chugakku: optionalString(),
  // kokai_kbn: z.any(),
  /** [未使用]ポータルHP公開区分 */
  // hb_hp_flg: z.never(),
  // shop_hp_flg: z.any(),
  // shop_hp_open_member_only_flg: z.any(),
  // athome_flg: z.never(), // 更新不可
  // homes_flg: z.never(), // 更新不可
  // homes_toshi_flg: z.any(),
  // homes_hp_flg: z.any(),
  // homes_tato_flg: z.any(),
  // suumo_flg: z.never(), // 更新不可
  // suumo_hp_flg: z.any(),
  // yahoo_new_flg: z.any(),
  // yahoo_old_flg: z.any(),
  // mmail_flg: z.any(),
  // panorama_flg: z.any(),
  // panorama_url: z.any(),
  // movie_flg: z.any(),
  // movie_url: z.any(),
  // mvie_kbn: z.any(),
  // osusume_flg: z.any(),
  // map_serchable_flg: z.any(),
  // map_athome_flg: z.any(),
  // map_suumo_flg: z.any(),
  /** HOMESURL区分 */
  url_kbn: optionalStrictString(z.nativeEnum(miraieFields.url_kbn.Enum)),
  /** HOMESURL */
  homes_url: optionalString(),
  // homes_keisai_kbn: z.any(),
  /** 売買SUUMOネット掲載指示区分 */
  suumonet_keisai_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.suumonet_keisai_kbn.Enum),
  ),
  // kokoku_keisai_flg: z.any(),
  // osusume_keisai_flg: z.any(),
  // cm_keisai_flg: z.any(),
  /** スタッフPR掲載指示フラグ */
  staff_keisai_flg: optionalStrictString(
    z.nativeEnum(miraieFields.staff_keisai_flg.Enum),
  ),
  // theme_keisai_flg: z.any(),
  // resort_flg: z.any(),
  /** 検索一覧キャッチ掲載指示フラグ */
  search_keisai_flg: optionalStrictString(
    z.nativeEnum(miraieFields.search_keisai_flg.Enum),
  ),
  /** おすすめピックアップ掲載指示フラグ */
  osusume_keisai_flg: optionalStrictString(
    z.nativeEnum(miraieFields.osusume_keisai_flg.Enum),
  ),
  // pick_keisai_flg: z.any(),
  // o_company_hp_keisai_flg: z.any(),
  // o_company_copy_flg: z.any(),
  /** 賃貸SUUMO内優先画像区分 */
  yusen_img_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.yusen_img_kbn.Enum),
  ),
  // kokoku_banchi_disp_flg: z.any(),
  // kokoku_sodan_flg: z.any(),
  // netmg_addr_disp_flg: z.any(),
  // netreport_raijoyoyaku_kbn: z.any(),
  /** オープンハウス区分 */
  openhouse_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.openhouse_kbn.Enum),
  ),
  /** オープンハウス日程区分 */
  tenanto_status_com: optionalStrictString(
    z.nativeEnum(miraieFields.tenanto_status_com.Enum),
  ),
  /** オープンハウス問合区分 */
  openhouse_toi_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.openhouse_toi_kbn.Enum),
  ),
  /** オープンハウス開催日From または オープンハウス開催年月日1 */
  openhouse_from_date: optionalString(),
  /** オープンハウス開催日To */
  openhouse_to_date: optionalString(),
  /** オープンハウス開催時刻From 時間 */
  openhouse_from_time_hour: optionalString(),
  /** オープンハウス開催時刻From 分 */
  openhouse_from_time_minute: optionalString(),
  /** オープンハウス開催時刻To 時間 */
  openhouse_to_time_hour: optionalString(),
  /** オープンハウス開催時刻To 分 */
  openhouse_to_time_minute: optionalString(),
  // openhouse_biko_30: z.any(),
  /** オープンハウス備考 */
  openhouse_biko: optionalString(),
  /** オープンハウス備考HOME's専用 */
  openhouse_biko_homes: optionalString(),
  /** オープンハウス開催年月日2 */
  openhouse_date_2: optionalString(),
  /** オープンハウス開催年月日3 */
  openhouse_date_3: optionalString(),
  /** オープンハウス開催年月日4 */
  openhouse_date_4: optionalString(),
  /** オープンハウス開催年月日5 */
  openhouse_date_5: optionalString(),
  /** オープンハウス開催年月日6 */
  openhouse_date_6: optionalString(),
  /** オープンハウス開催年月日7 */
  openhouse_date_7: optionalString(),
  /** オープンハウス開催年月日8 */
  openhouse_date_8: optionalString(),
  /** オープンハウス開催年月日9 */
  openhouse_date_9: optionalString(),
  /** オープンハウス開催年月日10 */
  openhouse_date_10: optionalString(),
  /** オープンハウス開催年月日11 */
  openhouse_date_11: optionalString(),
  /** オープンハウス開催年月日12 */
  openhouse_date_12: optionalString(),
  // mroom_kokai_shun_kbn: z.any(),
  // mhouse_name: z.any(),
  // mhouse_kokai_kbn: z.any(),
  // mhouse_shozai_cd: z.any(),
  // mhouse_banchi: z.any(),
  // mhouse_lat: z.any(),
  // mhouse_lon: z.any(),
  // event_name_1: z.any(),
  // event_from_date_1: z.any(),
  // event_to_date_1: z.any(),
  // event_biko_1: z.any(),
  // event_name_2: z.any(),
  // event_from_date_2: z.any(),
  // event_to_date_2: z.any(),
  // event_biko_2: z.any(),
  // event_name_3: z.any(),
  // event_from_date_3: z.any(),
  // event_to_date_3: z.any(),
  // event_biko_3: z.any(),
  /** カーナビ案内住所 */
  carnavi_addr: optionalString(),
  // athome_adver_yahoo_flg: z.any(),
  // tag_cd_renketsu: optionalString(),
  // general_column: z.any(),
  // general_column_setsumei: z.any(),
  // bk_error_flg: z.any(),
  // spare_flg_1: z.any(),
  // spare_flg_2: z.any(),
  // spare_flg_3: z.any(),
  // spare_kbn_1: z.any(),
  // spare_kbn_2: z.any(),
  // spare_kbn_3: z.any(),
  // reins_export_no: z.any(),
  // reins_export_last_upd_datetime: z.any(),
  // staff_cd: z.any(),
  // custom_field_1: z.any(),
  // custom_field_2: z.any(),
  // custom_field_3: z.any(),
  // custom_field_4: z.any(),
  // custom_field_5: z.any(),
  portal_staff: z
    .object({
      name: z.string(),
      kana: z.string(),
      suumo_b_staff_cd: z.string().nullish(),
      homes_staff_name: z.string().nullish(),
      athome_pro_staff_cd: z.string().nullish(),
    })
    .nullish(),
  portal_staff_id: z.null().optional(),
  /** athome備考 */
  athome_biko_1: optionalString(),
  // athome_biko_2: z.any(), // 未使用
  /** athomeエンド向けアピール */
  athome_end_appeal: optionalString(),
  /** athomeプロのコメント */
  athome_com: optionalString(),
  /** athome物件の魅力 */
  athome_miryoku: optionalString(),
  // athome_com_staff_id: z.any(),
  /** パノラマコンテンツID */
  athome_panorama_id: optionalString(),
  /** HOMESキャッチコピー */
  homes_catch_copy: optionalString(),
  // homes_staff_com_template_no: z.any(),
  /** HOMESスタッフコメント種別 */
  homes_staff_com_sbt: optionalStrictString(
    z.nativeEnum(miraieFields.homes_staff_com_sbt.Enum),
  ),
  /** HOMESスタッフコメント */
  homes_staff_com: optionalString(),
  /** HOMES備考 */
  homes_biko: optionalString(),
  // homes_special_kokoku_point: z.any(),
  /** HOMES所属グループ */
  homes_shozoku_group: optionalString(),
  /** HOMES周辺施設画像最低出稿枚数 */
  homes_shisetsu_minimum: optionalStrictString(
    z.nativeEnum(miraieFields.homes_shisetsu_minimum.Enum),
  ),
  // kyakutsuke_msg: z.any(),
  // bk_memo_1: z.any(),
  // bk_memo_2: z.any(),
  /** リンク先表示名称1 */
  link_name_1: optionalString(),
  /** リンク先URL1 */
  link_url_1: optionalString(),
  /** リンク先表示名称2 */
  link_name_2: optionalString(),
  /** リンク先URL2 */
  link_url_2: optionalString(),
  /** リンク先表示名称3 */
  link_name_3: optionalString(),
  /** リンク先URL3 */
  link_url_3: optionalString(),
  /** リンク先表示名称4 */
  link_name_4: optionalString(),
  /** リンク先URL4 */
  link_url_4: optionalString(),
  /** リンク先表示名称5 */
  link_name_5: optionalString(),
  /** リンク先URL5 */
  link_url_5: optionalString(),
  /** SUUMO物件キャッチ（メインキャッチ） */
  bk_catch: optionalString(),
  /** SUUMO物件ネット用コメント（サブキャッチ） */
  net_com: optionalString(),
  /** SUUMOネット用コメント */
  net_free_com: optionalString(),
  /** SUUMOネット用フリーコメント */
  suumo_biko: optionalString(),
  /** SUUMO備考 */
  // i_company_msg: z.any(), // 削除
  /** 会社間広告メインキャッチ */
  i_company_main_catch: optionalString(),
  /** 会社間用メインキャッチ1 */
  i_company_main_catch_1: optionalString(),
  /** 会社間用メインキャッチ2 */
  i_company_main_catch_2: optionalString(),
  /** 会社間広告サブキャッチ1 */
  i_company_sub_catch_1: optionalString(),
  /** 会社間広告サブキャッチ2 */
  i_company_sub_catch_2: optionalString(),
  /** 会社間広告サブキャッチ3 */
  i_company_sub_catch_3: optionalString(),
  /** 会社間広告サブキャッチ4 */
  i_company_sub_catch_4: optionalString(),
  /** 会社間広告サブキャッチ5 */
  i_company_sub_catch_5: optionalString(),
  /** 会社間広告サブキャッチ6 */
  i_company_sub_catch_6: optionalString(),
  /** 会社間広告サブキャッチ7 */
  i_company_sub_catch_7: optionalString(),
  /** 会社間広告サブキャッチ8 */
  i_company_sub_catch_8: optionalString(),
  /** 会社間広告サブキャッチ9 */
  i_company_sub_catch_9: optionalString(),
  /** 会社間広告サブキャッチ10 */
  i_company_sub_catch_10: optionalString(),
  /** 会社間広告フリーコメント */
  i_company_free_com: optionalString(),
  /** SUUMOネット用キャッチ */
  net_catch: optionalString(),
  /** 見学できる物件ピックアップ用コメント */
  pickup_com: optionalString(),
  /** SUUMOスタッフPRコメント */
  staff_pr_com: optionalString(),
  // staff_cd: z.any(), dup
  // homes_catch_copy_a: z.any(), // 削除
  // homes_catch_copy_b: z.any(), // 削除
  // homes_biko_a: z.any(), // 削除
  // homes_biko_b: z.any(), // 削除
  /** 汎用キャッチコピー */
  // catch_copy: optionalString(), // みらいえ用の項目のため利用しない
  // biko_50b: z.any(),
  /** 備考100文字 */
  // biko_100b: optionalString(), // みらいえ用の項目のため利用しない
  // biko_250b: z.any(),
  /** 標準スタッフコメント */
  // staff_com: optionalString(), // みらいえ用の項目のため利用しない
  // salespoint_50b: z.any(),
  /** 標準セールスポイント_自社HP用_ */
  // salespoint_100b: optionalString(), // みらいえ用の項目のため利用しない
  // salespoint_250b: z.any(),
  /** 帳票用備考 */
  chohyo_biko: optionalString(),
  // owabitoteisei: z.any(),
  // staff_osusume_point: z.any(),
  // other_bk_gaiyo_2000: z.any(),
  // shop_hp_c_open_kbn: z.any(),
  // mmail_c_open_kbn: z.any(),
  /** [suumo]公開区分 */
  suumo_c_open_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.c_open_kbn.Enum),
  ),
  /** [suumo]番地表示フラグ */
  suumo_c_banchi_flg: optionalStrictString(
    z.nativeEnum(miraieFields.banchi_flg.Enum),
  ),
  /** [suumo]建物表示フラグ */
  suumo_c_tatemono_hyoji_flg: optionalStrictString(
    z.nativeEnum(miraieFields.tatemono_hyoji_flg.Enum),
  ),
  /** [suumo]部屋番号表示フラグ */
  suumo_c_room_no_show_flg: optionalStrictString(
    z.nativeEnum(miraieFields.room_no_show_flg.Enum),
  ),
  /** [suumo]地図検索可フラグ */
  suumo_map_serchable_flg: optionalStrictString(
    z.nativeEnum(miraieFields.suumo_map_serchable_flg.Enum),
  ),
  // suumo_b_b_open_kbn: z.any(),
  // suumo_b_banchi_flg: z.any(),
  // suumo_kyakutsuke_msg: z.any(),
  // suumo_mngr_open_kbn: z.any(),
  // suumo_hp_kbn: z.any(),
  /** [homes]公開区分 */
  homes_c_open_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.c_open_kbn.Enum),
  ),
  /** [homes]番地表示フラグ */
  homes_c_banchi_flg: optionalStrictString(
    z.nativeEnum(miraieFields.banchi_flg.Enum),
  ),
  /** [homes]建物表示フラグ */
  homes_c_tatemono_hyoji_flg: optionalStrictString(
    z.nativeEnum(miraieFields.tatemono_hyoji_flg.Enum),
  ),
  /** [homes]部屋番号表示フラグ */
  homes_c_room_no_show_flg: optionalStrictString(
    z.nativeEnum(miraieFields.room_no_show_flg.Enum),
  ),
  // homes_c_staff_com_flg: z.any(),
  // homes_c_panorama_kbn: z.any(),
  // homes_b_b_open_kbn: z.any(),
  // homes_b_banchi_flg: z.any(),
  // homes_b_tatemono_hyoji_flg: z.any(),
  // homes_b_room_no_show_flg: z.any(),
  // homes_b_limit_open_kbn: z.any(),
  // homes_b_img_download_kbn: z.any(),
  // homes_kyakutsuke_msg: z.any(),
  // homes_mngr_open_kbn: z.any(),
  // homes_hp_kbn: z.any(),
  /** [athome]公開区分 */
  athome_c_open_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.c_open_kbn.Enum),
  ),
  /** [athome]建物表示フラグ */
  athome_c_tatemono_hyoji_flg: optionalStrictString(
    z.nativeEnum(miraieFields.tatemono_hyoji_flg.Enum),
  ),
  /** [athome]部屋番号表示フラグ */
  athome_c_room_no_show_flg: optionalStrictString(
    z.nativeEnum(miraieFields.room_no_show_flg.Enum),
  ),
  /** [athome]地図検索可フラグ */
  athome_map_serchable_flg: optionalStrictString(
    z.nativeEnum(miraieFields.athome_map_serchable_flg.Enum),
  ),
  // athome_b_b_open_kbn: z.any(),
  // athome_b_banchi_flg: z.any(),
  // athome_b_tatemono_hyoji_flg: z.any(),
  // athome_b_room_no_show_flg: z.any(),
  // athome_kyakutsuke_msg: z.any(),
  // TODO: rakumachiの他のflg？
  /** [rakumachi]公開区分 */
  rakumachi_c_open_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.c_open_kbn.Enum),
  ),
  // yahoo_c_open_kbn: z.any(),
  // yahoo_c_opt_template_kbn: z.any(),
  /** 予備備考1 */
  yobi_biko_1: optionalString(),
  /** 予備備考2 */
  yobi_biko_2: optionalString(),
  /** 予備備考3 */
  yobi_biko_3: optionalString(),
  /** 予備備考4 */
  yobi_biko_4: optionalString(),
  /** 予備備考5 */
  yobi_biko_5: optionalString(),
  /** 予備備考6 */
  yobi_biko_6: optionalString(),
  /** 予備備考7 */
  yobi_biko_7: optionalString(),
  /** 予備備考8 */
  yobi_biko_8: optionalString(),
  /** 予備備考9 */
  yobi_biko_9: optionalString(),
  /** プレゼント情報キャプション */
  present_caption: optionalString(),
  /** パンフレット情報キャプション区分 */
  pamphlet_info_caption_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.pamphlet_info_caption_kbn.Enum),
  ),
  /** 設計性能評価区分 */
  s_seinohyouka_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.s_seinohyouka_kbn.Enum),
  ),
  /** 設計性能評価その他特記事項 */
  s_seinohyouka_biko: optionalString(),
  /** 建設性能評価区分 */
  k_seinohyouka_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.k_seinohyouka_kbn.Enum),
  ),
  /** 建設性能評価その他特記事項 */
  k_seinohyouka_biko: optionalString(),
  /** 長期優良住宅区分 */
  long_highquality_housing_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.long_highquality_housing_kbn.Enum),
  ),
  /** 長期優良住宅_申請中棟区画名 */
  long_highquality_housing_partiton_name: optionalString(),
  /** 支払い例有フラグ */
  payment_example_flg: optionalStrictString(
    z.nativeEnum(miraieFields.payment_example_flg.Enum),
  ),
  /** 支払い例_例示部_物件_住戸_情報 */
  payment_example_bk_info: optionalString(),
  /** 支払い例_例示部_金額 */
  payment_example_price: optionalString(),
  /** 支払い例_例示部_金利 */
  payment_example_rate: optionalString(),
  /** 支払い例_支払額部 */
  payment_example_amount_of_payment: optionalString(),
  /** 支払い例_住宅ローンのご案内 */
  payment_example_loan_biko: optionalString(),
  /** フラット35区分 */
  flat35_kbn: optionalStrictString(z.nativeEnum(miraieFields.flat35_kbn.Enum)),
  /** フラット35S_バリアフリー性フラグ */
  flat35_barrierfree_flg: optionalStrictString(
    z.nativeEnum(miraieFields.flat35_barrierfree_flg.Enum),
  ),
  /** フラット35S_省エネルギー性フラグ */
  flat35_energysaving_flg: optionalStrictString(
    z.nativeEnum(miraieFields.flat35_energysaving_flg.Enum),
  ),
  /** フラット35S_耐震性フラグ */
  flat35_earthquakeproof_flg: optionalStrictString(
    z.nativeEnum(miraieFields.flat35_earthquakeproof_flg.Enum),
  ),
  /** フラット35S_耐久性・可変性フラグ */
  flat35_durability_and_variability_flg: optionalStrictString(
    z.nativeEnum(miraieFields.flat35_durability_and_variability_flg.Enum),
  ),
  /** フラット35S_住宅ローンのご案内 */
  flat35_loan_biko: optionalString(),
  /** 物件画像更新可能項目 */
  images: z
    .array(
      z.object({
        /** 画像番号 */
        img_no: z.enum(miraieFields.images.img_no.values),
        /** 画像種別区分 */
        img_sbt_kbn: optionalStrictString(
          z.nativeEnum(miraieFields.images.img_sbt_kbn.Enum),
        ),
        /** 画像タイトル */
        img_title: optionalString(),
        /** 画像コメント */
        img_com: optionalString(),
        /** 画像URL */
        img_data_url: optionalString(),
        /** 画像URLの md5 */
        img_in_md5: optionalString(),
        /** 画像公開区分 */
        img_kokai_kbn: optionalStrictString(
          z.nativeEnum(miraieFields.images.img_kokai_kbn.Enum),
        ),
      }),
    )
    .optional(),
  shisetsu: z
    .array(
      z.object({
        /** 周辺施設番号 */
        shisetsu_no: z.enum(miraieFields.shisetsu.shisetsu_no.values),
        /** 周辺施設種別区分 */
        shisetsu_sbt_kbn: optionalStrictString(
          z.nativeEnum(miraieFields.shisetsu.shisetsu_sbt_kbn.Enum),
        ),
        /** 周辺施設名称 */
        shisetsu_name: optionalString(),
        shisetsu_kyori: optionalNumber(),
        /** 周辺施設コメント */
        shisetsu_com: optionalString(),
        // watermark_flg: z.any(),
        // img_data_base64: z.never(),
        img_data_url: optionalString(),
        img_in_md5: optionalString(),
      }),
    )
    .optional(),
  // --
  ensen_name_1: optionalString(),
  eki_name_1: optionalString(),
  ensen_name_2: optionalString(),
  eki_name_2: optionalString(),
  ensen_name_3: optionalString(),
  eki_name_3: optionalString(),
  ensen_name_4: optionalString(),
  eki_name_4: optionalString(),

  /** 法令に基づくその他制限事項_SUUMO_ */
  suumo_horei_biko: optionalString(),
  /** 法令に基づくその他制限事項_HOMES_ */
  homes_horei_biko: optionalString(),
  /** 法令に基づくその他制限事項_athome_ */
  athome_horei_biko: optionalString(),
  /** FRK備考 */
  frk_warning_biko: optionalString(),
  /** SEO_description */
  seo_description: optionalString(),
  /** SEO_keywords */
  seo_keywords: optionalString(),
  /** 購入サポート概要区分1 */
  konyu_support_gaiyo_kbn_1: optionalStrictString(
    z.nativeEnum(miraieFields.konyu_support_gaiyo_kbn.Enum),
  ),
  /** 購入サポート詳細1 */
  konyu_support_gaiyo_shosai_1: optionalString(),
  /** 購入サポート料金発生有無フラグ1 */
  konyu_support_gaiyo_ryokin_umu_flg_1: optionalStrictString(
    z.nativeEnum(miraieFields.konyu_support_gaiyo_ryokin_umu_flg.Enum),
  ),
  /** 購入サポート料金1 */
  konyu_support_gaiyo_ryokin_1: optionalString(),
  /** 購入サポート期間1 */
  konyu_support_gaiyo_kikan_1: optionalString(),
  /** 購入サポート注意点・制約事項1 */
  konyu_support_gaiyo_chuui_1: optionalString(),
  /** 購入サポート概要区分2 */
  konyu_support_gaiyo_kbn_2: optionalStrictString(
    z.nativeEnum(miraieFields.konyu_support_gaiyo_kbn.Enum),
  ),
  /** 購入サポート詳細2 */
  konyu_support_gaiyo_shosai_2: optionalString(),
  /** 購入サポート料金発生有無フラグ2 */
  konyu_support_gaiyo_ryokin_umu_flg_2: optionalStrictString(
    z.nativeEnum(miraieFields.konyu_support_gaiyo_ryokin_umu_flg.Enum),
  ),
  /** 購入サポート料金2 */
  konyu_support_gaiyo_ryokin_2: optionalString(),
  /** 購入サポート期間2 */
  konyu_support_gaiyo_kikan_2: optionalString(),
  /** 購入サポート注意点・制約事項2 */
  konyu_support_gaiyo_chuui_2: optionalString(),
  /** 購入サポート概要区分3 */
  konyu_support_gaiyo_kbn_3: optionalStrictString(
    z.nativeEnum(miraieFields.konyu_support_gaiyo_kbn.Enum),
  ),
  /** 購入サポート詳細3 */
  konyu_support_gaiyo_shosai_3: optionalString(),
  /** 購入サポート料金発生有無フラグ3 */
  konyu_support_gaiyo_ryokin_umu_flg_3: optionalStrictString(
    z.nativeEnum(miraieFields.konyu_support_gaiyo_ryokin_umu_flg.Enum),
  ),
  /** 購入サポート料金3 */
  konyu_support_gaiyo_ryokin_3: optionalString(),
  /** 購入サポート期間3 */
  konyu_support_gaiyo_kikan_3: optionalString(),
  /** 購入サポート注意点・制約事項3 */
  konyu_support_gaiyo_chuui_3: optionalString(),
  /** 賃料値下可フラグ */
  chinryo_down_flg: optionalStrictString(
    z.nativeEnum(miraieFields.chinryo_down_flg.Enum),
  ),
  /** 初期費用値下可フラグ */
  initprice_down_flg: optionalStrictString(
    z.nativeEnum(miraieFields.initprice_down_flg.Enum),
  ),
  /** 管理費共益費有フラグ */
  kyoekihi_kanrihi_flg: optionalString(),
  /** 共益費 */
  kyoekihi: optionalNumber(),
  /** 敷金有フラグ */
  shikikin_flg: optionalString(),
  /** 敷金 */
  shikikin: optionalNumber(),
  /** 敷金単位区分 */
  shikikin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shikikin_tani_kbn.Enum),
  ),
  /** 敷引有フラグ */
  shikibiki_flg: optionalString(),
  /** 敷引 */
  shikibiki: optionalNumber(),
  /** 敷引単位区分 */
  shikibiki_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shikibiki_tani_kbn.Enum),
  ),
  /** 敷金積増条件区分 */
  shikimashi_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shikimashi_kbn.Enum),
  ),
  /** 敷金積増額 */
  shikimashi: optionalNumber(),
  /** 敷金積増額単位区分 */
  shikimashi_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shikimashi_tani_kbn.Enum),
  ),
  /** 礼金有フラグ */
  reikin_flg: optionalString(),
  /** 礼金 */
  reikin: optionalNumber(),
  /** 礼金単位区分 */
  reikin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.reikin_tani_kbn.Enum),
  ),
  /** 保証金有フラグ */
  hoshokin_flg: optionalString(),
  /** 保証金 */
  hoshokin: optionalNumber(),
  /** 保証金単位区分 */
  hoshokin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.hoshokin_tani_kbn.Enum),
  ),
  /** 保証金償却区分 */
  shokyaku_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shokyaku_kbn.Enum),
  ),
  /** 保証金償却 */
  shokyaku: optionalNumber(),
  /** 保証金償却単位区分 */
  shokyaku_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shokyaku_tani_kbn.Enum),
  ),
  /** 保証金その他償却条件内容 */
  hoshokin_shokyaku_joken_naiyo: optionalString(),
  /** 権利金有フラグ */
  kenrikin_flg: optionalString(),
  /** 権利金 */
  kenrikin: optionalNumber(),
  /** 権利金単位区分 */
  kenrikin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.reikin_tani_kbn.Enum),
  ),
  /** 賃貸保証区分 */
  chinhoshokin_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.chinhoshokin_kbn.Enum),
  ),
  /** 賃貸保証料 */
  chinhoshokin: optionalNumber(),
  /** 賃貸保証料単位区分 */
  chinhoshokin_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.chinhoshokin_tani_kbn.Enum),
  ),
  /** 賃貸保証内容 */
  chinhosho_naiyo: optionalString(),
  /** 保証人代行会社区分 */
  hoshonin_daiko_company_flg: optionalStrictString(
    z.nativeEnum(miraieFields.hoshonin_daiko_company_flg.Enum),
  ),
  /** 損害保険加入要フラグ */
  hoken_flg: optionalStrictString(z.nativeEnum(miraieFields.hoken_flg.Enum)),
  /** 損害保険契約期間 */
  hoken_keiyaku_kikan: optionalNumber(),
  /** 損害保険料 */
  hoken_kakekin: optionalNumber(),
  /** フリーレント */
  freerent: optionalNumber(),
  /** フリーレント条件詳細 */
  freerent_biko: optionalString(),
  /** フリーレント単位区分 */
  freerent_tani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.freerent_tani_kbn.Enum),
  ),
  /** 更新料有フラグ */
  koshinryo_flg: optionalString(),
  /** 更新料 */
  koshinryo: optionalNumber(),
  /** 更新料区分 */
  koshinryo_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.koshinryo_kbn.Enum),
  ),
  /** 特優賃有フラグ */
  tokuyuchin_flg: optionalStrictString(
    z.nativeEnum(miraieFields.tokuyuchin_flg.Enum),
  ),
  /** 特優賃入居負担額下限 */
  tokuyuchin_from: optionalNumber(),
  /** 特優賃入居負担額上限 */
  tokuyuchin_to: optionalNumber(),
  /** 特優賃変動区分 */
  tokuyuchin_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tokuyuchin_kbn.Enum),
  ),
  /** 特優賃上昇率 */
  tokuyuchin_ritsu: optionalNumber(),
  /** 特優賃家賃補助年数 */
  tokuyuchin_year: optionalNumber(),
  /** 特優賃補足 */
  tokuyuchin_hosoku: optionalString(),
  /** クレジット決済初期費用 */
  credit_shoki_flg: optionalStrictString(
    z.nativeEnum(miraieFields.credit_shoki_flg.Enum),
  ),
  /** クレジット決済賃料 */
  credit_chinryo_flg: optionalStrictString(
    z.nativeEnum(miraieFields.credit_chinryo_flg.Enum),
  ),
  /** クレジット決済その他 */
  credit_other_flg: optionalStrictString(
    z.nativeEnum(miraieFields.credit_other_flg.Enum),
  ),
  /** その他クレジット可能条件等 */
  credit_other_joken: optionalString(),
  /** 室内清掃費用 */
  room_cleaning_price: optionalNumber(),
  /** 雑費 */
  zappi: optionalNumber(),
  /** 鍵交換代 */
  key_kokan: optionalNumber(),
  /** 駐車場備考区分 */
  parking_biko_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.parking_biko_kbn.Enum),
  ),
  /** 定期借家契約フラグ */
  teishaku_keiyaku_flg: optionalStrictString(
    z.nativeEnum(miraieFields.teishaku_keiyaku_flg.Enum),
  ),
  /** 定期借家契約満了日 */
  shakuchi_keiyaku_date: optionalString(),
  /** 契約期間年 */
  keiyaku_years: optionalNumber(),
  /** 契約期間ヶ月 */
  keiyaku_months: optionalNumber(),
  /** 解約日 */
  kaiyaku_date: optionalString(),
  /** 募集概要賃貸総戸数 */
  chintai_zen_kosu: optionalNumber(),
  /** 募集概要賃貸空戸数 */
  chintai_aki_kosu: optionalNumber(),
  /** 内装カスタマイズ区分 */
  interior_custom_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.interior_custom_kbn.Enum),
  ),
  /** 内装カスタマイズ内容 */
  interior_custom_naiyo: optionalString(),
  /** 内装カスタマイズ条件 */
  interior_custom_joken: optionalString(),
  /** 多棟広告・販売状況 */
  tato_kokoku_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tato_kokoku_kbn.Enum),
  ),
  /** 予告広告補足コメント */
  yokoku_kokoku: optionalString(),
  /** 販売戸数 */
  hanbai_kosu: optionalNumber(),
  /** 販売方式 */
  hanbai_hoshiki_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.hanbai_hoshiki_kbn.Enum),
  ),
  /** 登録受付開始年月(年) */
  hanbai_start_ym_year: optionalString(),
  /** 登録受付開始年月(月) */
  hanbai_start_ym_month: optionalString(),
  /** 登録受付開始旬区分 */
  hanbai_start_jun_kbn: optionalString(),
  /** 登録受付終了年月(年) */
  touroku_end_ym_year: optionalString(),
  /** 登録受付終了年月(月) */
  touroku_end_ym_month: optionalString(),
  /** 登録受付終了日 */
  touroku_end_d: optionalString(),
  /** 抽選年月(年) */
  chusen_ym_year: optionalString(),
  /** 抽選年月(月) */
  chusen_ym_month: optionalString(),
  /** 抽選旬区分 */
  chusen_jun_kbn: optionalString(),
  /** 販売スケジュールコメント */
  hanbai_schedule_comment: optionalString(),
  /** 現場問い合わせ先名 */
  genba_toiawase_name: optionalString(),
  /** 現場問い合わせ先電話番号 */
  genba_toiawase_tel: optionalString(),
  /** 現場問い合わせ先備考 */
  genba_toiawase_biko: optionalString(),
  /** 多棟駐車場 */
  tato_parking: optionalString(),
  /** 最大価格 */
  price_to: optionalNumber(),
  /** 価格範囲区分 */
  price_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.price_hani_kbn.Enum),
  ),
  /** 保証金又は敷金区分 */
  shikikin_hoshokin_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shikikin_hoshokin_kbn.Enum),
  ),
  /** 保証金又は敷金金額 */
  shikikin_hoshokin_from: optionalNumber(),
  /** 保証金又は敷金範囲区分 */
  shikikin_hoshokin_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.shikikin_hoshokin_hani_kbn.Enum),
  ),
  /** 保証金又は敷金上限金額 */
  shikikin_hoshokin_to: optionalNumber(),
  /** 最多価格帯1 */
  tato_saita_price_1: optionalNumber(),
  /** 最多価格帯2 */
  tato_saita_price_2: optionalNumber(),
  /** 最多価格帯3 */
  tato_saita_price_3: optionalNumber(),
  /** 最多価格帯4 */
  tato_saita_price_4: optionalNumber(),
  /** 最多価格帯5 */
  tato_saita_price_5: optionalNumber(),
  /** 最多価格帯フリーコメント */
  tato_saita_price_com: optionalString(),
  /** 多棟最多価格帯戸数 */
  tato_ikkatsu_saita_price_kukakusu: optionalNumber(),
  /** 販売期名 */
  hanbaiki_name: optionalString(),
  /** 価格備考 */
  price_biko: optionalString(),
  /** SUUMO価格備考 */
  suumo_price_biko: optionalString(),
  /** 価格状態区分 */
  price_status_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.price_status_kbn.Enum),
  ),
  /** 多棟建築条件有区分 */
  tato_kenchiku_joken_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tato_kenchiku_joken_kbn.Enum),
  ),
  /** 建築条件区画数 */
  tato_kenchiku_joken_kukakusu: optionalNumber(),
  /** 建築条件付フリーコメント */
  tato_kenchiku_joken_freecom: optionalString(),
  /** 土地面積範囲区分 */
  tochi_menseki_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.tochi_menseki_hani_kbn.Enum),
  ),
  /** 最大土地面積 */
  tochi_menseki_to: optionalNumber(),
  /** SUUMO多棟借地権種類・期間等備考 */
  suumo_tato_shakuchiken_type_biko: optionalString(),
  /** SUUMO多棟借地権月賃料備考 */
  suumo_tato_shakuchiken_chinryo_biko: optionalString(),
  /** 多棟借地権その他費用備考 */
  tato_shakuchiken_other_price_biko: optionalString(),
  /**  道路幅from */
  doro_haba_from: optionalNumber(),
  /** 道路幅範囲区分 */
  doro_haba_hani_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.doro_haba_hani_kbn.Enum),
  ),
  /** 道路幅to */
  doro_haba_to: optionalNumber(),
  /** 道路舗装区分 */
  doro_hoso_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.doro_hoso_kbn.Enum),
  ),
  /** SUUMO道路コメント */
  suumo_doro_comment: optionalString(),
  /** 建ぺい率容積率 */
  kenpeiritsu_yosekiritsu: optionalString(),
  /** 多棟間取備考 */
  tato_madori_biko: optionalString(),
  // フォームの項目としては無いが、エラーメッセージが返ってくる場合がある項目
  // ref. https://github.com/terass-inc/tera-seven/issues/848
  /** 掲載前チェックフラグ */
  precheck_flg: optionalNever(),
  /** 承認ステータス状況 */
  approve_status_kbn: optionalNever(),
  /** SUUMO_マネージャ公開区分 */
  suumo_mngr_open_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.mngr_open_kbn.Enum),
  ),
  /** HOMES_マネージャ公開区分 */
  homes_mngr_open_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.mngr_open_kbn.Enum),
  ),
  /** 楽待_マネージャ公開区分 */
  rakumachi_mngr_open_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.mngr_open_kbn.Enum),
  ),
  /** 再エネありフラグ */
  is_renewable_energy_flg: optionalStrictString(
    z.nativeEnum(miraieFields.is_renewable_energy_flg.Enum),
  ),
  /** エネルギー消費性能 */
  energy_performance_from_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.energy_performance_from_kbn.Enum),
  ),
  /** 断熱性能（最小値） */
  insulation_performance_from_kbn: optionalStrictString(
    z.nativeEnum(miraieFields.insulation_performance_from_kbn.Enum),
  ),
  /** 目安光熱費 */
  estimated_utility_costs_from: optionalNumber(),
})

function optionalString() {
  return z.string().optional()
}

function optionalNumber() {
  return z.number().nullish()
}

function optionalNever() {
  return z.never().optional()
}

function optionalStrictString<T extends string>(type: z.ZodType<T>) {
  return type
    .or(z.literal(''))
    .optional()
    .transform((value) => value || undefined)
}
